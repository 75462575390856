import { ApiConnection } from "../../../api/api-client/common/ApiConnection"

export const createApiConnection = ({
  apiKey,
  refreshToken,
  isCurrentRouteDocumentLibraryPage,
}: {
  apiKey?: string
  refreshToken?: string | null
  isCurrentRouteDocumentLibraryPage: boolean
}) => {
  const apiConnection = new ApiConnection(apiKey, undefined) // second argument is undefined, this is to setup a user but looks like we should not set it at this point, Alex?
  apiConnection.refreshToken = refreshToken ?? undefined
  apiConnection.isPrimaryUser = isCurrentRouteDocumentLibraryPage

  return apiConnection
}
