import React, { FormEvent, KeyboardEvent } from "react"
import { BUTTON_SIZE, BUTTON_VARIANT, BUTTON_ICON_POSITION } from "./constants"
import { ComponentWithClass } from "../../../api/api-client/common/ComponentWithClass"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import classNames from "classnames"
import "./Button.css"

export type ButtonSizeType =
  | typeof BUTTON_SIZE.TINY
  | typeof BUTTON_SIZE.SMALL
  | typeof BUTTON_SIZE.REGULAR
  | typeof BUTTON_SIZE.LARGE
  | typeof BUTTON_SIZE.XLARGE
  | typeof BUTTON_SIZE.MAXWIDTH

export type ButtonVariantType =
  | typeof BUTTON_VARIANT.PRIMARY
  | typeof BUTTON_VARIANT.SECONDARY
  | typeof BUTTON_VARIANT.TERTIARY
  | typeof BUTTON_VARIANT.DANGER_PRIMARY
  | typeof BUTTON_VARIANT.DANGER_REMOVE
  | typeof BUTTON_VARIANT.MENU
  | typeof BUTTON_VARIANT.LINK

export type ButtonIconPositionType =
  | typeof BUTTON_ICON_POSITION.LEFT
  | typeof BUTTON_ICON_POSITION.RIGHT

export interface ButtonProps extends ComponentWithClass {
  className?: string

  /**
   * Toggles whether the component is disabled or not (preventing user interaction).
   */
  isDisabled?: boolean
  /**
   * Optional icon to display beside the component text.
   */
  icon?: string
  /**
   * Position of the optional icon.
   */
  iconPosition?: ButtonIconPositionType
  /**
   * Optional handler called when the component is clicked.
   */
  onClick?: (event: FormEvent<HTMLButtonElement>) => void
  /**
   * Size of the component.
   */
  size?: ButtonSizeType
  /**
   * HTML type of the component (forms should use the `submit` type).
   */
  type?: "button" | "submit" | "reset"
  /**
   * Type of component to display (style varies accordingly).
   */
  variant?: ButtonVariantType
  /**
   * aria label for accessibility
   */
  ariaLabel?: string
  /**
   * description of button context/function
   */
  title?: string
  /**
   * optional test id
   */
  testId?: string
  /**
   * optional flag to determine if the button has an icon
   */
  shouldShowIcon?: boolean
  /**
   * optional flag to determine if the button has autofocus
   */
  hasAutoFocus?: boolean
  /**
   * optional id
   */
  id?: string
  /**
   * optional onKeyDown event
   */
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void
}

export const Button: React.FC<ButtonProps> = ({
  className,
  isDisabled,
  icon,
  iconPosition = BUTTON_ICON_POSITION.RIGHT,
  onClick,
  size = BUTTON_SIZE.REGULAR,
  type = "button",
  variant,
  children,
  ariaLabel,
  title = "",
  testId = "",
  shouldShowIcon = true,
  hasAutoFocus = false,
  id = undefined,
  onKeyDown,
}) => {
  const buttonIcon = useGetIcon(icon)
  const handleOnClick = (event: FormEvent<HTMLButtonElement>) => {
    if (onClick && !isDisabled) {
      if (type === "submit") {
        isDisabled = true
      }
      onClick(event)
    }
  }

  const buttonClass = classNames("button", {
    disabled: isDisabled, //The key here is equal to a string
    "icon-position-left": iconPosition === BUTTON_ICON_POSITION.LEFT,
    [`${className}`]: className,
    [`${size}`]: size,
    [`${variant}`]: variant,
  })

  return (
    <button
      data-testid={testId}
      aria-label={ariaLabel}
      id={id}
      type={type}
      className={buttonClass}
      onClick={handleOnClick}
      onKeyDown={onKeyDown}
      disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      title={title}
      autoFocus={hasAutoFocus}
    >
      <span className={`button-children`}>{children}</span>
      {buttonIcon && shouldShowIcon && (
        <span className="icon-in-button" aria-hidden data-testid="button-icon">
          {buttonIcon}
        </span>
      )}
    </button>
  )
}

Button.displayName = "Button"
