export enum loginTypes {
  // Used to authenticate users with a Password login
  PASSWORD = "Password",
  // Used to authenticate users with a Passwordless login
  PASSWORDLESS = "Passwordless",
  // Used to authenticate users with both Password and Passwordless login
  MIXED = "Mixed",
  NONE = "None",
  SSO = "SSO",
  TOKEN = "Token",
}
