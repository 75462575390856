export const themesAvailableOn3TierSystem = [
  "ABRDN",
  "ABRDN_BALMORAL",
  "ABRDN_CAPITAL",
  "ABRDN_GIBBS",
  "ABRDN_LUDLOW",
  "ABRDN_SHERIDAN",
  "ABRDN_SUCCESSION",
  "ABRDN_VANILLA",
  "AMADEUS",
  "CO_OP",
  "LIFESTAGE",
]
