import { useEffect } from "react"
import {
  Navigate,
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import { IUser } from "../api/api-client/api-types"
import { getWorkFlowCaseList } from "../api/lib/workflow/workflow"
import { LoadingFallBack } from "../component/organism/LoadingFallBack"
import { setLoadingState } from "../contexts/application/action"
import {
  PAGE_PATH,
  UserModuleType,
  WorkflowCaseStatus,
} from "../contexts/application/constants"
import { useApplicationContext } from "../contexts/application/context"
import { useAuthContext } from "../contexts/auth/authContextProvider"
import { useUserContext } from "../contexts/users"
import { UnauthorisedCoopPageVariant } from "../pages/UnauthorisedPages/Coop/CoopUnauthorisedPage"

const availableRoutesForCaseTracker = [
  PAGE_PATH.Cases,
  PAGE_PATH.CaseDetailsPage,
  PAGE_PATH.CaseDocuments,
  PAGE_PATH.CaseDocumentDetailsPage,
  PAGE_PATH.CaseProfilePage,
  PAGE_PATH.CaseNotificationsPage,
  PAGE_PATH.CaseSharingPage,
  PAGE_PATH.CaseSharedWithMePage,
  PAGE_PATH.CaseSharedWithMeFileDetailsPage,
  PAGE_PATH.CaseDocumentAddSignature,
  PAGE_PATH.NotificationsPage,
  PAGE_PATH.Logout,
  PAGE_PATH.LoginPage,
  PAGE_PATH.RegisterPage,
  PAGE_PATH.ResetPasswordPage,
  PAGE_PATH.CaseSearchPage,
]

const availableRoutesForCaseManager = [
  PAGE_PATH.CaseManagerHomePage,
  PAGE_PATH.CaseManagerDocumentRequestPage,
  PAGE_PATH.CaseManagerDocumentTaggerIframePage,
  PAGE_PATH.CaseManagerDocumentSentPage,
  PAGE_PATH.CaseManagerLogout,
]

const availableRoutesForCaseContact = [
  PAGE_PATH.CaseContactSharedWithMePage,
  PAGE_PATH.CaseContactFileDetailsPage,
  PAGE_PATH.ProfilePage,
  PAGE_PATH.Logout,
  PAGE_PATH.LoginPage,
  PAGE_PATH.RegisterPage,
  PAGE_PATH.ResetPasswordPage,
]

function matchRoute({
  pathname,
  routes,
}: {
  pathname: string
  routes: string[]
}) {
  return routes.some((route) => {
    const routeParts = route.split("/")
    const pathParts = pathname.split("/")

    if (routeParts.length !== pathParts.length) {
      return false
    }

    return routeParts.every((part, index) => {
      if (part.startsWith(":")) {
        return true
      }
      return part === pathParts[index]
    })
  })
}

function rerouteUserIfInForbiddenRoute({
  pathname,
  currentUser,
  navigate,
  isAuthorisedToViewCase = true,
}: {
  pathname: string
  currentUser: IUser
  navigate: NavigateFunction
  isAuthorisedToViewCase?: boolean
}) {
  if (currentUser?.modules?.includes(UserModuleType.CASE_TRACKER)) {
    const isCurrentRouteAllowedForCaseTracker = matchRoute({
      pathname,
      routes: availableRoutesForCaseTracker,
    })
    if (!isCurrentRouteAllowedForCaseTracker || !isAuthorisedToViewCase) {
      return navigate(PAGE_PATH.UnauthorisedPage)
    }
  }

  if (
    currentUser?.modules?.includes(
      UserModuleType.CASE_MANAGER_DOCUMENT_EXCHANGE
    )
  ) {
    const isCurrentRouteAllowedForCaseManager =
      matchRoute({
        pathname,
        routes: availableRoutesForCaseManager,
      }) || pathname.includes(PAGE_PATH.CaseManagerDocumentTaggerIframePage) // Allow iframe special page since has many query params

    if (!isCurrentRouteAllowedForCaseManager) {
      return navigate(PAGE_PATH.UnauthorisedPage)
    }
  }

  if (currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_VIEW)) {
    const isCurrentRouteAllowedForCaseUserContact = matchRoute({
      pathname,
      routes: availableRoutesForCaseContact,
    })
    if (!isCurrentRouteAllowedForCaseUserContact) {
      return navigate(PAGE_PATH.UnauthorisedPage)
    }
  }

  return <Navigate to={PAGE_PATH.LoginPage} />
}

export const ProtectedRoute = () => {
  const {
    userState: { currentUser },
  } = useUserContext()
  const {
    applicationState: { authLoading },
    dispatch,
  } = useApplicationContext()
  const { isAuthenticated } = useAuthContext()

  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const caseId = params?.caseId
    const workflowId = params?.workflowId
    const rerouteUserToUnauthorisedBasedOnCaseId = async ({
      currentUser,
    }: {
      currentUser: IUser
    }) => {
      dispatch(setLoadingState(true))
      try {
        const cases = await getWorkFlowCaseList()
        const shouldNavigateToUnauthorisedClosed = cases.some(
          (caseInfo) =>
            caseInfo.workflowId === workflowId &&
            caseInfo.id.toString() === caseId &&
            caseInfo?.caseStatus?.toLowerCase() === WorkflowCaseStatus.CLOSED
        )

        if (shouldNavigateToUnauthorisedClosed) {
          navigate(PAGE_PATH.UnauthorisedPage, {
            state: {
              pageVariant: UnauthorisedCoopPageVariant.CASE_CLOSED,
            },
          })
        } else {
          // redirect to unauthorised, if PR doesn't belong to the case (caseId) they are trying to access
          rerouteUserIfInForbiddenRoute({
            pathname: location.pathname,
            currentUser,
            navigate,
            isAuthorisedToViewCase: cases.some(
              (caseInfo) =>
                caseInfo.id.toString() === caseId &&
                caseInfo.workflowId === workflowId
            ),
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(setLoadingState(false))
      }
    }
    if (currentUser) {
      // once user is authenticated they could alter the caseId in the url to view a case they are not authorised to view/ is closed
      // rerouteUserToUnauthorisedBasedOnCaseId redirects the user to a correct unauthorised page (unauthorised or case closed)
      if (caseId && workflowId) {
        rerouteUserToUnauthorisedBasedOnCaseId({ currentUser })
      }
      rerouteUserIfInForbiddenRoute({
        pathname: location.pathname,
        currentUser,
        navigate,
      })
    }
  }, [
    currentUser,
    dispatch,
    location.pathname,
    navigate,
    params?.caseId,
    params?.workflowId,
  ])

  // This was added as a workaround to fix the issue with flickering when starting or refreshing the application.
  if (authLoading) {
    return <LoadingFallBack />
  }

  if (!isAuthenticated()) {
    return <Navigate to={PAGE_PATH.LoginPage} />
  }

  return <Outlet />
}
