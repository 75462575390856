import { FC } from "react"
import "./LoadingSpinner.css"

export type ILoadingSpinner = {
  size?: string
  thickness?: string
  color?: string
}
export const LoadingSpinner: FC<ILoadingSpinner> = ({
  size = "16px",
  thickness = "2px",
  color,
}) => {
  return (
    <span
      style={{
        height: size,
        width: size,
        borderWidth: thickness,
        borderTopColor: color ?? undefined,
        borderRightColor: color ?? undefined,
        borderLeftColor: color ?? undefined,
        borderBottomColor: "transparent",
      }}
      className="loadingspinner"
      role="alert"
    ></span>
  )
}
