import { v4 as uuidv4 } from "uuid"
import {
  DistributionFileNodeDto,
  DistributionProjectInfoDto,
} from "legado-generated-api-client"
import { testData } from "../../FileDetails"
import { BulkDistributionVisibility } from "../../../contexts/application/constants"
const testFile = testData.files.interestStatementFile as DistributionFileNodeDto

export const projectOneFile = {
  id: "1",
  name: "Repair Planet Express Ship guide",
  status: "Draft",
  sendDateAdviser: "2023-04-20",
  sendDateClient: "2023-03-25",
  sizeOfDistribution: 1000,
  readCount: 0,
  version: "1",
  fileVisibility: BulkDistributionVisibility.ClientAndAdviser,
  isSubAccount: false,
  versionedFiles: [
    {
      currentVersion: 1,
      tags: ["Sample 11", "PDF", "Private"],
      files: [
        {
          ...testFile,
          version: 1,
          fileName: "sample11.pdf",
          sizeInBytes: 104000,
        },
      ],
    },
  ],
  lastModifiedBy: "John Doe",
  lastModifiedDate: "2023-03-20",
} as DistributionProjectInfoDto

export const projectMultipleFiles = {
  id: "1",
  name: "Repair Planet Express Ship guide",
  status: "Sent",
  sendDateAdviser: "Sat Apr 20 2023",
  sendDateClient: "Sat Mar 25 2023",
  sizeOfDistribution: 1000,
  readCount: 0,
  version: "1",
  fileVisibility: BulkDistributionVisibility.ClientAndAdviser,
  versionedFiles: [
    {
      currentVersion: 1,
      tags: ["Sample 11", "PDF", "Private"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "sample11.pdf",
          sizeInBytes: 104511,
        },
        {
          ...testFile,
          version: 2,
          fileName: "sample11.pdf",
          sizeInBytes: 104511,
        },
      ],
    },
    {
      currentVersion: 1,
      tags: ["Sample 12", "Important"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "sample12.pdf",
          sizeInBytes: 250421,
        },
      ],
    },
    {
      currentVersion: 1,
      tags: ["Letter 13", "Jury", "Court"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "juryLetter13.pdf",
          sizeInBytes: 1620560,
        },
      ],
    },
  ],
  isSubAccount: false,
} as DistributionProjectInfoDto

export const projectMultipleFilesOneReworked = {
  id: "1",
  name: "Repair Planet Express Ship guide",
  status: "Sent",
  sendDateAdviser: "Sat Apr 20 2023",
  sendDateClient: "Sat Mar 25 2023",
  sizeOfDistribution: 1000,
  readCount: 0,
  version: "1",
  fileVisibility: BulkDistributionVisibility.ClientAndAdviser,
  versionedFiles: [
    {
      currentVersion: 1,
      tags: ["Sample 11", "PDF", "Private"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "sample11.pdf",
          sizeInBytes: 104511,
          deleted: true,
        },
        {
          ...testFile,
          version: 2,
          fileName: "sample11.pdf",
          sizeInBytes: 104511,
          deleted: true,
        },
      ],
    },
    {
      currentVersion: 1,
      tags: ["Sample 12", "Important"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "sample12.pdf",
          sizeInBytes: 250421,
        },
      ],
    },
    {
      currentVersion: 1,
      tags: ["Letter 13", "Jury", "Court"],
      files: [
        {
          ...testFile,
          id: uuidv4(),
          version: 1,
          fileName: "juryLetter13.pdf",
          sizeInBytes: 1620560,
        },
        {
          ...testFile,
          id: uuidv4(),
          version: 2,
          fileName: "juryLetter13.pdf",
          sizeInBytes: 1620560,
        },
      ],
    },
  ],
  isSubAccount: false,
} as DistributionProjectInfoDto
