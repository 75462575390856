"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadApi = exports.UploadApiFp = exports.UploadApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
const base_1 = require("../base");
const UploadApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderIdUploadPost: (folderId, fileName, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError('folderId', 'Required parameter folderId was null or undefined when calling apiNodeFolderIdUploadPost.');
            }
            const localVarPath = `/api/node/{folderId}/upload`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            var formData = new FormData();
            formData.append("file", file, fileName);
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = formData;
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UploadApiAxiosParamCreator = UploadApiAxiosParamCreator;
/**
 * UploadApi - functional programming interface
 * @export
 */
const UploadApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderIdUploadPost(folderId, fileName, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UploadApiAxiosParamCreator)(configuration).apiNodeFolderIdUploadPost(folderId, fileName, file, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.UploadApiFp = UploadApiFp;
/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
class UploadApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    apiNodeFolderIdUploadPost(folderId, fileName, file, options) {
        return (0, exports.UploadApiFp)(this.configuration).apiNodeFolderIdUploadPost(folderId, fileName, file, options).then((request) => request(this.axios, this.basePath));
    }
    apiUploadPost(folderId, fileName, file, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosArgs = yield (0, exports.UploadApiAxiosParamCreator)(this.configuration).apiNodeFolderIdUploadPost(folderId, fileName, file, options);
            var formData = new FormData();
            formData.append(fileName, file, fileName);
            const url = this.basePath + localVarAxiosArgs.url;
            var response = yield this.axios.post(url, formData, {
                headers: localVarAxiosArgs.options.headers
            });
            return response;
        });
    }
}
exports.UploadApi = UploadApi;
