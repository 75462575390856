"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * UserApi - axios parameter creator
 * @export
 */
const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Updates the Terms and Conditions records for logged in User.
         * @summary Accept Terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAcceptTermsPut: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/AcceptTerms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Recieves a four digit security code and confirms if it matches the code associated with the user
         * @summary Confirm security code
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserConfirmCodePost: (code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/confirmCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the user by their email address or id, or current user if not supplied
         * @summary Get User
         * @param {string} [email] The user email
         * @param {string} [id] The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetGet: (email, id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the most recent count of all users of Legado's Platforms
         * @summary Get the Latest Count of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetlatestcountGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/getlatestcount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Register a user - client is registering themselves through the UI
         * @summary Register a User
         * @param {UserDto} [body] the new user to be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Requests for the account to be cancelled
         * @summary A user requesting to have their account cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRequestCancellationPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/requestCancellation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Sychronise the user's data from the external system
         * @summary Sync External User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSyncexternaluserPut: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/syncexternaluser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update a user's details
         * @summary Update User
         * @param {string} userId the user id
         * @param {UserDto} [body] the new user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut: (userId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling apiUserUserIdPut.');
            }
            const localVarPath = `/api/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Sends an email/text containing a verification code
         * @summary Send verification code via email or text
         * @param {string} [email]
         * @param {string} [phoneNumber]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationCodePost: (email, phoneNumber, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/User/verificationCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }
            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
const UserApiFp = function (configuration) {
    return {
        /**
         * Updates the Terms and Conditions records for logged in User.
         * @summary Accept Terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAcceptTermsPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserAcceptTermsPut(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Recieves a four digit security code and confirms if it matches the code associated with the user
         * @summary Confirm security code
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserConfirmCodePost(code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserConfirmCodePost(code, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the user by their email address or id, or current user if not supplied
         * @summary Get User
         * @param {string} [email] The user email
         * @param {string} [id] The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetGet(email, id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserGetGet(email, id, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the most recent count of all users of Legado's Platforms
         * @summary Get the Latest Count of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetlatestcountGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserGetlatestcountGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Register a user - client is registering themselves through the UI
         * @summary Register a User
         * @param {UserDto} [body] the new user to be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserRegisterPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Requests for the account to be cancelled
         * @summary A user requesting to have their account cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRequestCancellationPost(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserRequestCancellationPost(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Sychronise the user's data from the external system
         * @summary Sync External User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSyncexternaluserPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserSyncexternaluserPut(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Update a user's details
         * @summary Update User
         * @param {string} userId the user id
         * @param {UserDto} [body] the new user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut(userId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserUserIdPut(userId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Sends an email/text containing a verification code
         * @summary Send verification code via email or text
         * @param {string} [email]
         * @param {string} [phoneNumber]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationCodePost(email, phoneNumber, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.UserApiAxiosParamCreator)(configuration).apiUserVerificationCodePost(email, phoneNumber, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
const UserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Updates the Terms and Conditions records for logged in User.
         * @summary Accept Terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAcceptTermsPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserAcceptTermsPut(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Recieves a four digit security code and confirms if it matches the code associated with the user
         * @summary Confirm security code
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserConfirmCodePost(code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserConfirmCodePost(code, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the user by their email address or id, or current user if not supplied
         * @summary Get User
         * @param {string} [email] The user email
         * @param {string} [id] The user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetGet(email, id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserGetGet(email, id, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the most recent count of all users of Legado's Platforms
         * @summary Get the Latest Count of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetlatestcountGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserGetlatestcountGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Register a user - client is registering themselves through the UI
         * @summary Register a User
         * @param {UserDto} [body] the new user to be registered
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRegisterPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserRegisterPost(body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Requests for the account to be cancelled
         * @summary A user requesting to have their account cancelled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRequestCancellationPost(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserRequestCancellationPost(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Sychronise the user's data from the external system
         * @summary Sync External User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSyncexternaluserPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserSyncexternaluserPut(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Update a user's details
         * @summary Update User
         * @param {string} userId the user id
         * @param {UserDto} [body] the new user details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut(userId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserUserIdPut(userId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Sends an email/text containing a verification code
         * @summary Send verification code via email or text
         * @param {string} [email]
         * @param {string} [phoneNumber]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationCodePost(email, phoneNumber, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.UserApiFp)(configuration).apiUserVerificationCodePost(email, phoneNumber, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     * Updates the Terms and Conditions records for logged in User.
     * @summary Accept Terms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserAcceptTermsPut(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserAcceptTermsPut(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Recieves a four digit security code and confirms if it matches the code associated with the user
     * @summary Confirm security code
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserConfirmCodePost(code, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserConfirmCodePost(code, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the user by their email address or id, or current user if not supplied
     * @summary Get User
     * @param {string} [email] The user email
     * @param {string} [id] The user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserGetGet(email, id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserGetGet(email, id, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the most recent count of all users of Legado's Platforms
     * @summary Get the Latest Count of Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserGetlatestcountGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserGetlatestcountGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Register a user - client is registering themselves through the UI
     * @summary Register a User
     * @param {UserDto} [body] the new user to be registered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserRegisterPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserRegisterPost(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Requests for the account to be cancelled
     * @summary A user requesting to have their account cancelled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserRequestCancellationPost(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserRequestCancellationPost(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Sychronise the user's data from the external system
     * @summary Sync External User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserSyncexternaluserPut(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserSyncexternaluserPut(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Update a user's details
     * @summary Update User
     * @param {string} userId the user id
     * @param {UserDto} [body] the new user details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserUserIdPut(userId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserUserIdPut(userId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Sends an email/text containing a verification code
     * @summary Send verification code via email or text
     * @param {string} [email]
     * @param {string} [phoneNumber]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    apiUserVerificationCodePost(email, phoneNumber, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.UserApiFp)(this.configuration).apiUserVerificationCodePost(email, phoneNumber, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.UserApi = UserApi;
