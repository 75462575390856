import { ApiController } from "../apiController"
import { BulkDistributionController } from "./BulkDistributionController"
import { CorporateAdminController } from "./CorporateAdminController"
import { OrganisationController } from "./OrganisationController"

export const getBulkDistributionController = (): BulkDistributionController => {
  const apiBase = ApiController.getInstance()
  const api = new BulkDistributionController(apiBase)
  return api
}

export const getOrganisationController = (): OrganisationController => {
  const apiBase = ApiController.getInstance()
  const api = new OrganisationController(apiBase)
  return api
}

export const getCorporateAdminController = (): CorporateAdminController => {
  const apiBase = ApiController.getInstance()
  const api = new CorporateAdminController(apiBase)
  return api
}
