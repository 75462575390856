export class ApiConnection {
  apiKey!: string | undefined
  /**
   * This apiUsername looks like is for testing purposes or for static data, but also when setting credentials for a user
   * and when getting a user details from BE (getUser endpoint).
   */
  apiUsername!: string | undefined
  /**
   * At the moment is only used for FNZ Integration.
   */
  refreshToken: string | undefined
  connected: boolean = false
  failed: boolean = false
  /**
   * Indicates that the request was for primary user route (for FNZ Integration)
   * We check this by looking at the current route and seeing if it is the document library page
   */
  isPrimaryUser: boolean = false

  constructor(apiKey: string | undefined, userName: string | undefined) {
    this.apiKey = apiKey
    this.apiUsername = userName
  }
}
