"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationApi = exports.NotificationApiFactory = exports.NotificationApiFp = exports.NotificationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * NotificationApi - axios parameter creator
 * @export
 */
const NotificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get all Notifications for the user
         * @summary Get Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * [Depreciated]  - Returns the notification text for a specific notification. The PubSubNotificationDto parameter allows the caller to include the File ID, Folder ID and additional information add to the message. The additional information can then be included in the notification text body for that particular notification type.  I.e By passing in UserId, FileID and Folder ID the following notification can be created\"{adviserName} has uploaded {fileName} to your Folder {folderName}\"
         * @summary Get Popup Notification Content
         * @param {PubSubNotificationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGetPopupNotificationContentPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Notification/GetPopupNotificationContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Pass in a list of notification ID’s to set them as ‘Read’
         * @summary Set Notifications As Read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationSetNotificationsAsReadPut: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Notification/SetNotificationsAsRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.NotificationApiAxiosParamCreator = NotificationApiAxiosParamCreator;
/**
 * NotificationApi - functional programming interface
 * @export
 */
const NotificationApiFp = function (configuration) {
    return {
        /**
         * Get all Notifications for the user
         * @summary Get Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NotificationApiAxiosParamCreator)(configuration).apiNotificationGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * [Depreciated]  - Returns the notification text for a specific notification. The PubSubNotificationDto parameter allows the caller to include the File ID, Folder ID and additional information add to the message. The additional information can then be included in the notification text body for that particular notification type.  I.e By passing in UserId, FileID and Folder ID the following notification can be created\"{adviserName} has uploaded {fileName} to your Folder {folderName}\"
         * @summary Get Popup Notification Content
         * @param {PubSubNotificationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGetPopupNotificationContentPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NotificationApiAxiosParamCreator)(configuration).apiNotificationGetPopupNotificationContentPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Pass in a list of notification ID’s to set them as ‘Read’
         * @summary Set Notifications As Read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationSetNotificationsAsReadPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NotificationApiAxiosParamCreator)(configuration).apiNotificationSetNotificationsAsReadPut(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.NotificationApiFp = NotificationApiFp;
/**
 * NotificationApi - factory interface
 * @export
 */
const NotificationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get all Notifications for the user
         * @summary Get Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NotificationApiFp)(configuration).apiNotificationGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * [Depreciated]  - Returns the notification text for a specific notification. The PubSubNotificationDto parameter allows the caller to include the File ID, Folder ID and additional information add to the message. The additional information can then be included in the notification text body for that particular notification type.  I.e By passing in UserId, FileID and Folder ID the following notification can be created\"{adviserName} has uploaded {fileName} to your Folder {folderName}\"
         * @summary Get Popup Notification Content
         * @param {PubSubNotificationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationGetPopupNotificationContentPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NotificationApiFp)(configuration).apiNotificationGetPopupNotificationContentPost(body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Pass in a list of notification ID’s to set them as ‘Read’
         * @summary Set Notifications As Read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationSetNotificationsAsReadPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NotificationApiFp)(configuration).apiNotificationSetNotificationsAsReadPut(body, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.NotificationApiFactory = NotificationApiFactory;
/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
class NotificationApi extends base_1.BaseAPI {
    /**
     * Get all Notifications for the user
     * @summary Get Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    apiNotificationGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NotificationApiFp)(this.configuration).apiNotificationGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * [Depreciated]  - Returns the notification text for a specific notification. The PubSubNotificationDto parameter allows the caller to include the File ID, Folder ID and additional information add to the message. The additional information can then be included in the notification text body for that particular notification type.  I.e By passing in UserId, FileID and Folder ID the following notification can be created\"{adviserName} has uploaded {fileName} to your Folder {folderName}\"
     * @summary Get Popup Notification Content
     * @param {PubSubNotificationDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    apiNotificationGetPopupNotificationContentPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NotificationApiFp)(this.configuration).apiNotificationGetPopupNotificationContentPost(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Pass in a list of notification ID’s to set them as ‘Read’
     * @summary Set Notifications As Read
     * @param {Array<string>} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    apiNotificationSetNotificationsAsReadPut(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NotificationApiFp)(this.configuration).apiNotificationSetNotificationsAsReadPut(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.NotificationApi = NotificationApi;
