import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { PAGE_PATH } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useAuthContext } from "../../contexts/auth/authContextProvider"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"
import { useGetIcon } from "../../styled-components/GetIconLibraryInTheme"
import { disconnect } from "../../utils/auth/login"

import { usePlatformContext } from "../../contexts/platform/platformContextProvider"
import { loginTypes } from "../../utils/auth/loginTypes"
import "./LogoutLink.css"

export interface LougoutLinkProps {
  textContent?: string
}

export const LogoutLink: FC<LougoutLinkProps> = ({
  textContent = "Log out",
}) => {
  const {
    applicationState: { hostUrl },
    dispatch,
  } = useApplicationContext()
  const navigate = useNavigate()

  const { isAuthenticated } = useAuthContext()
  const { loginType } = usePlatformContext()

  let linkTarget = "_self"
  try {
    if (window !== window.parent) {
      linkTarget = "_parent"
    }
  } catch (e) {}

  function getLogoutUrl() {
    if (hostUrl) {
      return `https://${hostUrl}/identity/logout`
    } else {
      return "/"
    }
  }

  function doLogout() {
    // Disconnect from the API this is for apiController logic
    disconnect({ apiConnection: undefined, dispatch: dispatch })
    localStorage.removeItem(LocalStorageKeys.AppType)
    if (loginType !== loginTypes.PASSWORD) {
      localStorage.removeItem(LocalStorageKeys.ApiKey)
      localStorage.removeItem(LocalStorageKeys.Token)
    }
    // If the user is authenticated, navigate to the logout page, this is for new way of authentication (COOP)
    if (isAuthenticated()) {
      navigate(PAGE_PATH.Logout)
    }
  }

  const linkIcon = useGetIcon("Logout")

  return (
    <>
      <a
        href={getLogoutUrl()}
        target={linkTarget}
        className="logout-link"
        onClick={doLogout}
        aria-label={textContent}
      >
        <span className="icon-box link-icon">{linkIcon}</span>
        {textContent}
      </a>
    </>
  )
}
