interface Platforms {
  [key: string]: { theme: string; excludedModules?: string[] };
}

export const platforms = <Platforms>{
  LEGADO: {
    theme: "Legado",
    excludedModules: [
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "QuickAccess",
      "DocumentCommenting",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  ONE_X: {
    theme: "OneX",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Sharing",
      "Contacts",
      "SharedWithMeView",
      "SharedWithMeEdit",
      "LeftNav",
      "DataExtractionUpload",
      "Reminders",
      "Dates",
      "Notifications",
      "ContactVaultPersonalisation",
      "Connectivity",
      "Scraping",
      "ClientInvite",
      "Tagging",
      "OrganisationsAdmin",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  SCOTTISH_WIDOWS: {
    theme: "Scottish Widows",
    excludedModules: [
      "PersonaliseVault",
      "Sharing",
      "Contacts",
      "SharedWithMeList",
      "SharedWithMeView",
      "SharedWithMeEdit",
      "DataExtractionUpload",
      "LeftNav",
      "Dates",
      "Tagging",
      "Reminders",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  BARCLAYS: {
    theme: "Barclays",
    excludedModules: [
      "LeftNav",
      "WelcomeScreen",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  JAMES_HAY: {
    theme: "James Hay",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Contacts",
      "DataExtractionUpload",
      "LeftNav",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  AVIVA: {
    theme: "Aviva",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Contacts",
      "DataExtractionUpload",
      "LeftNav",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  ABRDN: {
    theme: "Abrdn",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Sharing",
      "Contacts",
      "SharedWithMeView",
      "SharedWithMeEdit",
      "LeftNav",
      "DataExtractionUpload",
      "Reminders",
      "Dates",
      "Notifications",
      "ContactVaultPersonalisation",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  SUCCESSION: {
    theme: "Succession",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Contacts",
      "DataExtractionUpload",
      "LeftNav",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  INTERACTIVE_INVESTOR: {
    theme: "Interactive Investor",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Contacts",
      "DataExtractionUpload",
      "LeftNav",
      "Connectivity",
      "Scraping",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  MONEYHUB: {
    theme: "Moneyhub",
  },
  MONEYHUB_EXL: {
    theme: "Moneyhub Exl",
    excludedModules: [
      "LeftNav",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "Organisations",
      "QuickAccess",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  QUILTER: {
    theme: "Quilter",
    excludedModules: [
      "WelcomeScreen",
      "PersonaliseVault",
      "Sharing",
      "Contacts",
      "SharedWithMeView",
      "SharedWithMeEdit",
      "LeftNav",
      "DataExtractionUpload",
      "Reminders",
      "Dates",
      "Notifications",
      "ContactVaultPersonalisation",
      "Connectivity",
      "Scraping",
      "ClientInvite",
      "Tagging",
      "OrganisationsAdmin",
      "Organisations",
      "DocumentCommenting",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "ChangePhoneNumber",
    ],
  },
  THE_FAMILY_ELEPHANT: {
    theme: "The Family Elephant",
    excludedModules: [
      "Connectivity",
      "Scraping",
      "OrganisationsAdmin",
      "QuickAccess",
      "DocumentCommenting",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
    ],
  },
  FORT_CIRRUS: {
    theme: "Fort Cirrus",
    excludedModules: [
      "Connectivity",
      "Scraping",
      "QuickAccess",
      "DocumentPreview",
      "DocumentCommenting",
      "DataExtractionUpload",
      "ContactVaultPersonalisation",
      "Communications",
      "ClientInvite",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "ClientSearchInDocHub",
      "WelcomeScreen",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  CO_OP: {
    theme: "CO OP",
    excludedModules: [
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "OrganisationsAdmin",
      "QuickAccess",
      "DocumentCommenting",
      "HideOrganisationFromSharing",
      "CancelSubscription",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  ONE_LEGACY: {
    theme: "One Legacy",
    excludedModules: [
      "Connectivity",
      "Scraping",
      "QuickAccess",
      "DocumentPreview",
      "DocumentCommenting",
      "DataExtractionUpload",
      "ContactVaultPersonalisation",
      "Communications",
      "ClientInvite",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "ClientSearchInDocHub",
      "WelcomeScreen",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "ChangePhoneNumber",
    ],
  },
  AMADEUS: {
    theme: "Amadeus",
    excludedModules: [
      "Connectivity",
      "Scraping",
      "QuickAccess",
      "DocumentPreview",
      "DocumentCommenting",
      "DataExtractionUpload",
      "ContactVaultPersonalisation",
      "Communications",
      "ClientInvite",
      "DocumentHub",
      "DocumentsLibraryAdviserView",
      "DocumentLibrary",
      "ClientSearchInDocHub",
      "WelcomeScreen",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
    ],
  },
  LIFESTAGE: {
    theme: "LifeStage",
    excludedModules: [
      "CancelSubscription",
      "ClientInvite",
      "ClientSearchInDocHub",
      "Communications",
      "Connectivity",
      "DataExtractionUpload",
      "Dates",
      "DocumentCommenting",
      "DocumentHub",
      "DocumentLibrary",
      "DocumentLibraryNotificationButton",
      "DocumentLibraryVisibility",
      "DocumentsLibraryAdviserView",
      "HideOrganisationFromSharing",
      "Organisations",
      "OrganisationsAdmin",
      "QuickAccess",
      "Scraping",
      "SharedWithMeEdit",
      "Tagging",
      "WelcomeScreen",
    ],
  },
};

export enum featureModules {
  Vault = "Vault",
  Other = "Other",
  Sharing = "Sharing",
  Intelligence = "Intelligence",
  Connectivity = "Connectivity",
  Communications = "Communications",
  Organisations = "Organisations",
  QuickAccess = "QuickAccess",
}

export enum userRoles {
  SelectedNone = "none selected",
  PrimaryUser = "Primary User",
  Adviser = "Adviser",
  PowerAdviser = "Power Adviser",
  CorporateAdmin = "Corporate Admin",
  Contact = "Contact",
}

export const features = [
  {
    feature: "WelcomeScreen",
    module: featureModules.Other,
    allowed: [userRoles.PrimaryUser, userRoles.Contact],
  },
  {
    feature: "Vault",
    module: featureModules.Vault,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "AuditLog",
    module: featureModules.Vault,
    allowed: [userRoles.PrimaryUser, userRoles.Contact],
  },
  {
    feature: "Upload",
    module: featureModules.Vault,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "PersonaliseVault",
    module: featureModules.Vault,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "Sharing",
    module: featureModules.Sharing,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "Contacts",
    module: featureModules.Sharing,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "SharedWithMeList",
    module: featureModules.Sharing,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "SharedWithMeView",
    module: featureModules.Sharing,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "SharedWithMeEdit",
    module: featureModules.Sharing,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "HideOrganisationFromSharing",
    module: featureModules.Sharing,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "DataExtractionUpload",
    module: featureModules.Intelligence,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "LeftNav",
    module: featureModules.Other,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Search",
    module: featureModules.Vault,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Dates",
    module: featureModules.Intelligence,
    allowed: [
      userRoles.Contact,
      userRoles.PrimaryUser,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Tagging",
    module: featureModules.Intelligence,
    allowed: [
      userRoles.Contact,
      userRoles.PrimaryUser,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Reminders",
    module: featureModules.Intelligence,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "Notifications",
    module: featureModules.Vault,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Preview",
    module: featureModules.Vault,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Thumbnail",
    module: featureModules.Vault,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "Connectivity",
    module: featureModules.Connectivity,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "Scraping",
    module: featureModules.Connectivity,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "ClientInvite",
    module: featureModules.Organisations,
    allowed: [
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "ClientList",
    module: featureModules.Organisations,
    allowed: [
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "CancelSubscription",
    module: featureModules.Organisations,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "DocumentHub",
    module: featureModules.Communications,
    allowed: [userRoles.PowerAdviser, userRoles.CorporateAdmin],
  },
  {
    feature: "DocumentsLibraryAdviserView",
    module: featureModules.Communications,
    allowed: [
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "DocumentLibrary",
    module: featureModules.Communications,
    allowed: [userRoles.PrimaryUser],
  },
  {
    feature: "ClientSearchInDocHub",
    module: featureModules.Communications,
    allowed: [userRoles.PrimaryUser, userRoles.CorporateAdmin],
  },
  {
    feature: "DocumentPreview",
    module: featureModules.Vault,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "ContactVaultPersonalisation",
    module: featureModules.Sharing,
    allowed: [
      userRoles.PrimaryUser,
      userRoles.Contact,
      userRoles.Adviser,
      userRoles.PowerAdviser,
    ],
  },
  {
    feature: "OrganisationAdmin", // TODO: This is a typo, should be OrganisationsAdmin but tests are failing when changed https://dev.azure.com/secure-the-file/Application/_workitems/edit/15777
    module: featureModules.Organisations,
    allowed: [userRoles.CorporateAdmin],
  },
  {
    feature: "QuickAccess",
    module: featureModules.QuickAccess,
    allowed: [userRoles.PrimaryUser, userRoles.Adviser],
  },
  {
    feature: "DocumentCommenting",
    module: featureModules.Vault,
    allowed: [
      userRoles.Contact,
      userRoles.PrimaryUser,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "DocumentLibraryNotificationButton",
    module: featureModules.Communications,
    allowed: [
      userRoles.Contact,
      userRoles.PrimaryUser,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
  {
    feature: "DocumentLibraryVisibility",
    module: featureModules.Communications,
    allowed: [
      userRoles.Contact,
      userRoles.PrimaryUser,
      userRoles.Adviser,
      userRoles.PowerAdviser,
      userRoles.CorporateAdmin,
    ],
  },
];
