import { Dispatch } from "react"

import { ApiConnection } from "../../api/api-client/common/ApiConnection"
import { ApiController } from "../../api/apiController"
import {
  setApiConnection,
  setApiKey,
  setAuthLoading,
  setHeaderState,
  setHostUrl,
  setSelectedTheme,
} from "../../contexts/application/action"
import { PAGE_PATH } from "../../contexts/application/constants"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"
import { IThumbnailAction } from "../../contexts/thumbnails/reducer"
import { IToastAction } from "../../contexts/toasts/reducer"
import { TThemeName } from "../../design-tokens/types"
import { demoUsersMap } from "../demoUsersMap"
import { castToBool } from "../helpers"
import { checkForParamInUrlSearchParams } from "./lib/checkForParamInUrlSearchParams"
import { checkTokenForIframe } from "./lib/checkTokenForIframe"
import { clearUrl } from "./lib/clearUrl"
import { connectToApiAndReturnUser } from "./lib/connectToApiAndReturnUser"
import { createApiConnection } from "./lib/createApiConnection"
import { setCurrentUserFromLocalData } from "./lib/setCurrentUserFromLocalData"
import { loginTypes } from "./loginTypes"

const isCurrentRouteDocumentLibraryPage =
  window.location.pathname === PAGE_PATH.DocumentLibraryPage

const prepareStaticDataOrConnectToApiForTesting = (
  emailForTesting: string,
  dispatch: (value: any) => void,
  dispatchUser: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>,
  token?: string,
  apiKeyFromUrl?: string,
  themeFromUrl?: string
) => {
  // Use static data for FNZ demo (specifically for proposition guide)
  if (demoUsersMap.has(emailForTesting)) {
    const userModules = demoUsersMap.get(emailForTesting)
    setCurrentUserFromLocalData({
      selectedModules: userModules ? userModules.modules : [],
      dispatch,
      dispatchUser,
      email: emailForTesting,
    })

    dispatch(setSelectedTheme("ONE_X" as TThemeName))
    return
  }

  // We connect to the API here, as we have the email and token but for testing purposes.
  const apiConnection = new ApiConnection(apiKeyFromUrl, emailForTesting)

  connectToApiAndReturnUser({
    apiConnection,
    token,
    dispatch,
    dispatchUser,
    thumbnailDispatch,
    toastDispatch,
    themeFromUrl,
  })
}

export const disconnect = async ({
  apiConnection,
  dispatch,
}: {
  apiConnection?: ApiConnection
  dispatch: (value: any) => void
}) => {
  const apiController = ApiController.getInstance()
  await apiController.setApiConnection()
  if (apiConnection) {
    apiConnection.connected = false
    dispatch(setApiConnection(apiConnection))
  }
}

export const apiConnect = async (
  dispatch: (value: any) => void,
  dispatchUser: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void,
  toastDispatch: Dispatch<IToastAction>,
  loginType: string
) => {
  if (loginType !== loginTypes.SSO && loginType !== loginTypes.TOKEN) {
    return
  }

  const params = new URLSearchParams(window.location.search.toLowerCase())
  let apiKey =
    checkForParamInUrlSearchParams(params, "apikey") ??
    process.env.REACT_APP_APIKEY
  //This code is only use for FNZ. At the moment.
  const code = checkForParamInUrlSearchParams(params, "code")
  const hostUrl = checkForParamInUrlSearchParams(params, "host")
  const context = checkForParamInUrlSearchParams(params, "context")
  const header = checkForParamInUrlSearchParams(params, "hideHeader")

  const appTypeValue = checkForParamInUrlSearchParams(params, "appType")
  if (appTypeValue) {
    localStorage.setItem(LocalStorageKeys.AppType, appTypeValue)
  }

  // Theme can be passed through query string in dev and qa
  const allowApiOverrideForTheme = castToBool(
    process.env.REACT_APP_ALLOW_THEME_QUERYSTRING
  )
  const themeFromUrl = allowApiOverrideForTheme
    ? checkForParamInUrlSearchParams(params, "theme")
    : undefined

  let externalToken = checkTokenForIframe()

  clearUrl(hostUrl, context, themeFromUrl)

  // hostUrl is used for the logout link, when login happens through middle end
  if (hostUrl) {
    dispatch(setHostUrl(hostUrl))
  }
  if (header) {
    dispatch(setHeaderState(true))
  }

  // user email can be passed through query string in dev and qa this allows auto login which is useful for demo links and automated tests
  const allowUserInQuerystring = castToBool(
    process.env.REACT_APP_ALLOW_USER_QUERYSTRING
  )

  const emailForTesting = allowUserInQuerystring
    ? checkForParamInUrlSearchParams(params, "user")
    : undefined

  if (allowUserInQuerystring && apiKey && emailForTesting) {
    prepareStaticDataOrConnectToApiForTesting(
      emailForTesting,
      dispatch,
      dispatchUser,
      thumbnailDispatch,
      toastDispatch,
      externalToken,
      apiKey,
      themeFromUrl
    )
    localStorage.setItem(LocalStorageKeys.ApiKey, apiKey)
    return
  }

  // For PP and Prod from here and bellow because the environment variables are not set
  if (apiKey && apiKey !== "undefined") {
    localStorage.setItem(LocalStorageKeys.ApiKey, apiKey)
    dispatch(setApiKey(apiKey))
  }

  let savedRefreshToken: string | null = null
  //This code is only use for FNZ. At the moment.
  if (code) {
    localStorage.removeItem(LocalStorageKeys.RefreshToken)
    localStorage.removeItem(LocalStorageKeys.ApiKey)
    const apiController = ApiController.getInstance()
    const externalTokenDto = await apiController.getExternalToken(
      code,
      isCurrentRouteDocumentLibraryPage
    )
    if (externalTokenDto) {
      apiKey = externalTokenDto.apiKey ?? undefined
      externalToken = externalTokenDto.accessToken ?? undefined
      if (externalTokenDto.refreshToken) {
        savedRefreshToken = externalTokenDto.refreshToken
        localStorage.setItem(LocalStorageKeys.RefreshToken, savedRefreshToken)
        localStorage.setItem(LocalStorageKeys.ApiKey, apiKey ?? "")
      }
    }
  } else {
    // no new sso code was provided, so check for a refresh token
    // the refresh token has to match the current API key
    // This mechanism is only use for FNZ or when using refreshToken to get a new token
    savedRefreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken)
    if (savedRefreshToken) {
      const savedApiKey = localStorage.getItem(LocalStorageKeys.ApiKey)
      apiKey = savedApiKey ?? apiKey

      if (apiKey !== undefined && apiKey !== savedApiKey) {
        // API key is different, so don't use this saved Refresh Token
        savedRefreshToken = null
      }
    }
  }

  if (!externalToken && !hostUrl && savedRefreshToken === null) {
    const failedConnection = new ApiConnection(undefined, undefined)
    failedConnection.failed = true
    dispatch(setApiConnection(failedConnection))
    dispatch(setAuthLoading(false))
    return
  }

  // Connect to the API for real (Not for testing or static data)
  const apiConnection = createApiConnection({
    apiKey,
    refreshToken: savedRefreshToken,
    isCurrentRouteDocumentLibraryPage,
  })

  connectToApiAndReturnUser({
    apiConnection,
    token: externalToken,
    dispatch,
    dispatchUser,
    thumbnailDispatch,
    toastDispatch,
  })
}
