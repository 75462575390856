import { ComponentVariablesTypes } from "../../tier3-component-specific-variables/bridgeTokenTypes"
import { definitionTokens } from "../tier1-definitions/definitionTokens"

export const applicationTokens = {
  colors: {
    /////////////////////////////////////////////////////////////////
    ////Universal colors
    /////////////////////////////////////////////////////////////////

    //TODO: colors which were not component specific on Figma,
    // are likely to disappear once all themes were migrated to the new design system
    colorUniversalNaturalBlack: definitionTokens.colors?.["#232323"],
    colorUniversalNaturalWhite: definitionTokens.colors?.["#FEFEFE"],
    colorUniversalNaturalGrey: definitionTokens.colors?.["#F9F9F9"],
    colorUniversalSecondaryA: definitionTokens.colors?.["#232323"],
    colorUniversalSecondaryB: definitionTokens.colors?.["#282828"],
    colorUniversalSecondaryC: definitionTokens.colors?.["#BCBCBC"],
    colorUniversalSecondaryD: definitionTokens.colors?.["#E8E8E8"],
    colorUniversalSecondaryE: definitionTokens.colors?.["#F2F4F7"],
    colorUniversalSecondaryF: definitionTokens.colors?.["#CCF2F7"],
    colorUniversalSecondaryG: definitionTokens.colors?.["#00729A"],
    colorUniversalSecondaryH: definitionTokens.colors?.["#244C8F"],
    colorUniversalToneA: definitionTokens.colors?.["#FD7838"],
    colorUniversalToneA25: definitionTokens.colors?.["#FEE7C1"],
    colorUniversalPrimaryBase: definitionTokens.colors?.["#00B1E7"],
    colorUniversalPrimaryBlack: definitionTokens.colors?.["#232323"],
    colorUniversalPrimaryGrey: definitionTokens.colors?.["#F5F5F5"],
    colorUniversalPrimaryBackground: definitionTokens.colors?.["#FEFEFE"],

    /////////////////////////////////////////////////////////////////
    ////Action colors
    /////////////////////////////////////////////////////////////////

    // background color
    colorActionBackgroundPrimary: definitionTokens.colors?.["#0F8482"],
    colorActionBackgroundPrimaryHover: definitionTokens.colors?.["#0B6361"],
    colorActionBackgroundSecondary: definitionTokens.colors?.["#005878"],
    colorActionBackgroundSecondaryHover: definitionTokens.colors?.["#003E55"],
    colorActionBackgroundDanger: definitionTokens.colors?.["#D82927"],
    colorActionBackgroundDangerHover: definitionTokens.colors?.["#B4201E"],
    colorActionBackgroundRemove: definitionTokens.colors?.["#FFFFFF"],
    colorActionBackgroundRemoveHover: definitionTokens.colors?.["#B4201E"],
    colorActionBackgroundDisabled: definitionTokens.colors?.["#E8E8E8"],

    //border color
    colorActionBorderPrimary: definitionTokens.colors?.["#0F8482"],
    colorActionBorderPrimaryHover: definitionTokens.colors?.["#0B6361"],
    colorActionBorderSecondary: definitionTokens.colors?.["#005878"],
    colorActionBorderSecondaryHover: definitionTokens.colors?.["#003E55"],
    colorActionBorderDanger: definitionTokens.colors?.["#D82927"],
    colorActionBorderDisabled: definitionTokens.colors?.["#D82927"],
    colorActionBorderDangerHover: definitionTokens.colors?.["#B4201E"],
    colorFeedbackBorderInfo: definitionTokens.colors?.["#27AAAB"],
    colorFeedbackBorderSuccess: definitionTokens.colors?.["#50811B"],
    colorFeedbackBorderWarning: definitionTokens.colors?.["#F8D156"],
    colorFeedbackBorderError: definitionTokens.colors?.["#E20F14"],

    // outline color
    colorInputOutlineFocus: definitionTokens.colors?.["#8D44D8"],

    // text color
    colorActionTextPrimary: definitionTokens.colors?.["#FFFFFF"],
    colorActionTextSecondary: definitionTokens.colors?.["#00729A"],
    colorActionTextDanger: definitionTokens.colors?.["#D82927"],
    colorActionTextDisabled: definitionTokens.colors?.["#ADAEB9"],
    colorActionTextHover: definitionTokens.colors?.["#00394E"],
    colorActionLinkPrimary: definitionTokens.colors?.["#00729A"],
    colorActionLinkPrimaryHover: definitionTokens.colors?.["#00394E"],

    /////////////////////////////////////////////////////////////////
    ////context colors
    /////////////////////////////////////////////////////////////////
    colorContextBackgroundPrimary: definitionTokens.colors?.["#FFFFFF"],
    colorContextBackgroundSecondary: definitionTokens.colors?.["#F3F9FD"],
    colorContextBackgroundTertiary: definitionTokens.colors?.["#C4C4C4"],
    colorContextBackgroundQuaternary: definitionTokens.colors?.["#E8E8E8"],

    colorContextBorderPrimary: definitionTokens.colors?.["#6E6E6E"],

    /////////////////////////////////////////////////////////////////
    ////Feedback colors
    /////////////////////////////////////////////////////////////////
    colorFeedbackSuccess: definitionTokens.colors?.["#3A6002"],
    colorFeedbackSuccessHover: definitionTokens.colors?.["#3A6002"],
    colorFeedbackSuccessShade: definitionTokens.colors?.["#D7E3C6"],
    colorFeedbackWarning: definitionTokens.colors?.["#F8D156"],
    colorFeedbackWarningHover: definitionTokens.colors?.["#BF7C18"],
    colorFeedbackWarningShade: definitionTokens.colors?.["#F8EEC7"],
    colorFeedbackDanger: definitionTokens.colors?.["#E20F14"],
    colorFeedbackDangerHover: definitionTokens.colors?.["#B4201E"],
    colorFeedbackDangerShade: definitionTokens.colors?.["#F7D4D5"],
    colorFeedbackMessage: definitionTokens.colors?.["#27AAAB"],
    colorFeedbackMessageHover: definitionTokens.colors?.["#046062"],
    colorFeedbackMessageShade: definitionTokens.colors?.["#CCF2F7"],

    // Inline feedback
    colorFeedbackBackgroundInlineMessage: definitionTokens.colors?.["#CCF2F7"],
    colorFeedbackInlineBackgroundInfo: definitionTokens.colors?.["#FEFEFE"],
    colorFeedbackInlineBackgroundError: definitionTokens.colors?.["#FCF1F1"],
    colorFeedbackInlineBackgroundSuccess: definitionTokens.colors?.["#D7E3C6"],
    colorFeedbackInlineBackgroundWarning: definitionTokens.colors?.["#F8EEC7"],

    // Background color
    colorFeedbackPrimary: definitionTokens.colors?.["#EEF3FC"],
    colorFeedbackBackgroundInfo: definitionTokens.colors?.["#00729A"],
    colorFeedbackBackgroundEmphasized: definitionTokens.colors?.["#FFFBCD"],
    colorFeedbackBackgroundNegative: definitionTokens.colors?.["#F8E9E9"],
    colorFeedbackBackgroundMultiple: definitionTokens.colors?.["#F9EDFC"],
    colorFeedbackBackgroundPositive: definitionTokens.colors?.["#EAF5DA"],

    // Text color
    colorFeedbackTextPrimary: definitionTokens.colors?.["#282828"],
    colorFeedbackTextSecondary: definitionTokens.colors?.["#FFFFFF"],

    // Border color
    colorFeedbackBorderPrimary: definitionTokens.colors?.["#EEF3FC"],
    colorFeedbackBorderPositive: definitionTokens.colors?.["#EAF5DA"],
    colorFeedbackBorderInfoSecondary: definitionTokens.colors?.["#00729A"],
    colorFeedbackBorderEmphasized: definitionTokens.colors?.["#FFFBCD"],
    colorFeedbackBorderNegative: definitionTokens.colors?.["#F8E9E9"],
    colorFeedbackBorderMultiple: definitionTokens.colors?.["#F9EDFC"],

    colorFeedbackInlineBorderInfo: definitionTokens.colors?.["#27AAAB"],
    colorFeedbackInlineBorderSuccess: definitionTokens.colors?.["#50811B"],
    colorFeedbackInlineBorderError: definitionTokens.colors?.["#E20F14"],
    colorFeedbackInlineBorderWarning: definitionTokens.colors?.["#F8D156"],

    /////////////////////////////////////////////////////////////////
    ////interaction colors
    /////////////////////////////////////////////////////////////////

    colorInteractionBackgroundPrimary: definitionTokens.colors?.["#FFFFFF"],
    colorInteractionBackgroundPrimaryHover:
      definitionTokens.colors?.["#EBFFFF"],
    colorInteractionBackgroundPrimaryFocus:
      definitionTokens.colors?.["#0F8482"],
    colorInteractionBackgroundSecondary: definitionTokens.colors?.["#003E55"],
    colorInteractionBackgroundSecondaryHover:
      definitionTokens.colors?.["#CFF9F9"],
    colorInteractionBackgroundTertiary: definitionTokens.colors?.["#F0F0F0"],
    colorInteractionBackgroundTertiaryHover:
      definitionTokens.colors?.["#F7FCFB"],
    colorInteractionBackgroundQuaternary: definitionTokens.colors?.["#CCF2F7"],
    colorInteractionBackgroundRemoveHover: definitionTokens.colors?.["#FDE8E9"],

    // Chevron color
    colorInteractionChevronPrimary: definitionTokens.colors?.["#0F8482"],
    colorInteractionChevronSecondary: definitionTokens.colors?.["#3D3E46"],

    // text color
    colorInteractionTextPrimary: definitionTokens.colors?.["#0F8482"],
    colorInteractionTextSecondary: definitionTokens.colors?.["#6E6E6E"],
    colorInteractionTextTertiary: definitionTokens.colors?.["#3D3E46"],
    colorInteractionTextQuaternary: definitionTokens.colors?.["#FFFFFF"],
    colorInteractionTextQuinary: definitionTokens.colors?.["#282828"],

    // border color
    colorInteractionBorderPrimary: definitionTokens.colors?.["#6E6E6E"],
    colorInteractionBorderPrimaryHover: definitionTokens.colors?.["#0B6361"],
    colorInteractionBorderPrimaryFocus: definitionTokens.colors?.["#0F8482"],
    colorInteractionBorderSecondaryHover: definitionTokens.colors?.["#0B6361"],
    colorInteractionBorderSecondaryFocus: definitionTokens.colors?.["#6FC7B6"],
    colorInteractionBorderTertiaryHover: definitionTokens.colors?.["#CFF9F9"],

    // Line color
    colorInteractionLinePrimary: definitionTokens.colors?.["#00B1E7"],

    /////////////////////////////////////////////////////////////////
    ////additional colors
    /////////////////////////////////////////////////////////////////

    lightBackground: definitionTokens.colors?.["#E6E6E6"],
  },
  boxStyles: {
    //radius
    boxStyleActionBorderRadiusSmallX: definitionTokens.radius?.radius5px,
    boxStyleActionBorderRadiusSmall: definitionTokens.radius?.radius8px,
    boxStyleActionBorderRadiusLarge: definitionTokens.radius?.radius100px,

    //border
    boxStyleActionBorderPrimary: definitionTokens.borders?.border1pxSolid,

    // shadow
    actionShadowPrimary: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)",
    headerShadow: "0px 1px 0px 0px #a1a1a1",
    documentShadow: "0px 2px 10px 0px #00000026",
  },
  typeface: {
    fontFamilyBase: definitionTokens.typeface?.fontFamilyBase,
    fontFamilyBaseSrc: definitionTokens.typeface?.fontFamilyBaseSrc,

    fontSizeDefault: definitionTokens.typeface?.fontSize,

    fontSizeExtraSmall: definitionTokens.typeface?.fontSizeExtraSmall,
    fontSizeSmall: definitionTokens.typeface?.fontSizeSmall,
    fontSizeMedium: definitionTokens.typeface?.fontSizeMedium,
    fontSizeLarge: definitionTokens.typeface?.fontSizeLarge,
    fontSizeExtraLarge: definitionTokens.typeface?.fontSizeExtraLarge,
    fontSizeExtraExtraLarge: definitionTokens.typeface?.fontSizeExtraExtraLarge,
    fontSizeExtraExtraExtraLarge:
      definitionTokens.typeface?.fontSizeExtraExtraExtraLarge,
    fontSizeDisplay: definitionTokens.typeface?.fontSizeDisplay,
    fontSizeDisplayLarge: definitionTokens.typeface?.fontSizeDisplayLarge,
    fontSizeDisplayExtraLarge:
      definitionTokens.typeface?.fontSizeDisplayExtraLarge,

    lineHeightExtraSmall: definitionTokens.typeface?.lineHeightExtraSmall,
    lineHeightSmall: definitionTokens.typeface?.lineHeightSmall,
    lineHeightMedium: definitionTokens.typeface?.lineHeightMedium,
    lineHeightLarge: definitionTokens.typeface?.lineHeightLarge,
    lineHeightExtraLarge: definitionTokens.typeface?.lineHeightExtraLarge,
    lineHeightExtraExtraLarge:
      definitionTokens.typeface?.lineHeightExtraExtraLarge,
    lineHeightExtraExtraExtraLarge:
      definitionTokens.typeface?.lineHeightExtraExtraExtraLarge,
    lineHeightDisplay: definitionTokens.typeface?.lineHeightDisplay,
    lineHeightDisplayLarge: definitionTokens.typeface?.lineHeightDisplayLarge,
    lineHeightDisplayExtraLarge:
      definitionTokens.typeface?.lineHeightDisplayExtraLarge,
  },
  flexLayout: {
    flexEnd: "flex-end",
    columnReverse: "column-reverse",
    row: "row",
  },
}

// Bridge Tokens from Tier 2 to Tier 3
// This object is used for mapping theme-related variables from Tier 2 to Tier 3 and
// should be setup in each theme. This is so the T3 componentVariables object in componentVariables.ts
// can remain the same and be reused across the themes.
export const bridgeTokensT2ToT3: ComponentVariablesTypes = {
  universal: {
    colors: {
      // Action colors
      componentColorActionBackgroundDisabledT2Token:
        applicationTokens.colors.colorActionBackgroundDisabled,
      componentColorActionBorderDisabledT2Token:
        applicationTokens.colors.colorActionBorderDisabled,
      componentColorActionTextDisabledT2Token:
        applicationTokens.colors.colorActionTextDisabled,

      // Feedback colors
      componentColorFeedbackSuccessT2Token:
        applicationTokens.colors.colorFeedbackSuccess,
      componentColorFeedbackSuccessHoverT2Token:
        applicationTokens.colors.colorFeedbackSuccessHover,
      componentColorFeedbackSuccessShadeT2Token:
        applicationTokens.colors.colorFeedbackSuccessShade,
      componentColorFeedbackWarningT2Token:
        applicationTokens.colors.colorFeedbackWarning,
      componentColorFeedbackWarningHoverT2Token:
        applicationTokens.colors.colorFeedbackWarningHover,
      componentColorFeedbackWarningShadeT2Token:
        applicationTokens.colors.colorFeedbackWarningShade,
      componentColorFeedbackDangerT2Token:
        applicationTokens.colors.colorFeedbackDanger,
      componentColorFeedbackDangerHoverT2Token:
        applicationTokens.colors.colorFeedbackDangerHover,
      componentColorFeedbackDangerShadeT2Token:
        applicationTokens.colors.colorFeedbackDangerShade,
      componentColorFeedbackMessageT2Token:
        applicationTokens.colors.colorFeedbackMessage,
      componentColorFeedbackMessageHoverT2Token:
        applicationTokens.colors.colorFeedbackMessageHover,
      componentColorFeedbackMessageShadeT2Token:
        applicationTokens.colors.colorFeedbackMessageShade,

      // Interaction colors
      componentColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryHover,
      componentColorInteractionBackgroundSecondaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondaryHover,
      componentColorInteractionBackgroundRemoveHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundRemoveHover,
      componentColorInteractionBorderHoverT2Token:
        applicationTokens.colors.colorInteractionBorderTertiaryHover,

      // Brand colors
      colorUniversalNaturalBlackT2Token:
        applicationTokens.colors.colorUniversalNaturalBlack,
      colorUniversalNaturalWhiteT2Token:
        applicationTokens.colors.colorUniversalNaturalWhite,
      colorUniversalNaturalGreyT2Token:
        applicationTokens.colors.colorUniversalNaturalGrey,
      colorUniversalSecondaryAT2Token:
        applicationTokens.colors.colorUniversalSecondaryA,
      colorUniversalSecondaryBT2Token:
        applicationTokens.colors.colorUniversalSecondaryB,
      colorUniversalSecondaryCT2Token:
        applicationTokens.colors.colorUniversalSecondaryC,
      colorUniversalSecondaryDT2Token:
        applicationTokens.colors.colorUniversalSecondaryD,
      colorUniversalSecondaryET2Token:
        applicationTokens.colors.colorUniversalSecondaryE,
      colorUniversalSecondaryFT2Token:
        applicationTokens.colors.colorUniversalSecondaryF,
      colorUniversalSecondaryGT2Token:
        applicationTokens.colors.colorUniversalSecondaryG,
      colorUniversalSecondaryHT2Token:
        applicationTokens.colors.colorUniversalSecondaryH,
      colorUniversalToneAT2Token: applicationTokens.colors.colorUniversalToneA,
      colorUniversalToneA25T2Token:
        applicationTokens.colors.colorUniversalToneA25,
      colorUniversalPrimaryBlackT2Token:
        applicationTokens.colors.colorUniversalPrimaryBlack,
      colorUniversalPrimaryGreyT2Token:
        applicationTokens.colors.colorUniversalPrimaryGrey,
      colorUniversalPrimaryBackgroundT2Token:
        applicationTokens.colors.colorUniversalPrimaryBackground,
      colorUniversalPrimaryBaseT2Token:
        applicationTokens.colors.colorUniversalPrimaryBase,
      cardColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,

      // Focus colors
      colorInputOutlineFocusT2Token:
        applicationTokens.colors.colorInputOutlineFocus,
    },
  },
  alertMessage: {
    colors: {
      // background color
      alertMessageColorFeedbackBackgroundMessageT2Token:
        applicationTokens.colors?.colorFeedbackBackgroundInlineMessage,
      alertMessageColorFeedbackBackgroundInfoT2Token:
        applicationTokens.colors?.colorFeedbackInlineBackgroundInfo,
      alertMessageColorFeedbackBackgroundSuccessT2Token:
        applicationTokens.colors?.colorFeedbackInlineBackgroundSuccess,
      alertMessageColorFeedbackBackgroundWarningT2Token:
        applicationTokens.colors?.colorFeedbackInlineBackgroundWarning,
      alertMessageColorFeedbackBackgroundErrorT2Token:
        applicationTokens.colors?.colorFeedbackInlineBackgroundError,

      // border color
      alertMessageColorFeedbackBorderInfoT2Token:
        applicationTokens.colors?.colorFeedbackInlineBorderInfo,
      alertMessageColorFeedbackBorderSuccessT2Token:
        applicationTokens.colors?.colorFeedbackInlineBorderSuccess,
      alertMessageColorFeedbackBorderWarningT2Token:
        applicationTokens.colors?.colorFeedbackInlineBorderWarning,
      alertMessageColorFeedbackBorderErrorT2Token:
        applicationTokens.colors?.colorFeedbackInlineBorderError,
    },
  },
  accordion: {
    colors: {
      background: {
        accordionColorContextBackgroundSectionActiveT2Token:
          applicationTokens.colors.colorContextBackgroundPrimary,
      },
    },
  },
  button: {
    colors: {
      // Button background color
      buttonColorActionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorActionBackgroundPrimary,
      buttonColorActionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundPrimaryHover,
      buttonColorActionBackgroundSecondaryT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
      buttonColorActionBackgroundSecondaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundSecondaryHover,
      buttonColorActionBackgroundTertiaryT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
      buttonColorActionBackgroundTertiaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundSecondaryHover,
      buttonColorActionBackgroundDangerT2Token:
        applicationTokens.colors.colorActionBackgroundDanger,
      buttonColorActionBackgroundDangerHoverT2Token:
        applicationTokens.colors.colorActionBackgroundDangerHover,
      buttonColorActionBackgroundRemoveT2Token:
        applicationTokens.colors.colorActionBackgroundRemove,
      buttonColorActionBackgroundRemoveHoverT2Token:
        applicationTokens.colors.colorActionBackgroundRemoveHover,
      buttonColorActionBackgroundDisabledT2Token:
        applicationTokens.colors.colorActionBackgroundDisabled,

      // Text color
      buttonColorActionTextPrimaryT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextPrimaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextSecondaryT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextSecondaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextTertiaryT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextTertiaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDangerT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDangerHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextRemoveT2Token:
        applicationTokens.colors.colorActionTextDanger,
      buttonColorActionTextRemoveHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDisabledT2Token:
        applicationTokens.colors.colorActionTextDisabled,
    },
    boxStyles: {
      // Border radius
      buttonActionBorderRadiusButtonXT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusSmall,
      // Border
      buttonActionBorderPrimaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderPrimary ?? "Not set"
        ),
      buttonActionBorderPrimaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderPrimaryHover ?? "Not set"
        ),
      buttonActionBorderSecondaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondary ?? "Not set"
        ),
      buttonActionBorderSecondaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondaryHover ?? "Not set"
        ),
      buttonActionBorderTertiaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondary ?? "Not set"
        ),
      buttonActionBorderTertiaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondaryHover ?? "Not set"
        ),
      buttonActionBorderDangerT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      buttonActionBorderDangerHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      buttonActionBorderRemoveT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      buttonActionBorderRemoveHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      // Box shadow
      // Coop buttons do not have shadows
      buttonActionShadowPrimaryT2Token: "Not set",
      buttonActionShadowPrimaryHoverT2Token: "Not set",
      buttonActionShadowSecondaryT2Token: "Not set",
      buttonActionShadowSecondaryHoverT2Token: "Not set",
      buttonActionShadowTertiaryT2Token: "Not set",
      buttonActionShadowTertiaryHoverT2Token: "Not set",
    },
  },
  breadcrumb: {
    colors: {
      breadcrumbColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorActionTextSecondary,
      breadcrumbColorInteractionTextPrimaryHoverT2Token:
        applicationTokens.colors.colorActionTextHover,
    },
  },
  card: {
    colors: {
      border: {
        cardColorContextBorderLighterT2Token:
          applicationTokens.colors.colorContextBorderPrimary,
        cardColorContextBorderDarkerT2Token:
          applicationTokens.colors.colorContextBorderPrimary,
      },
    },
  },
  contactCard: {
    fontSizes: {
      contactCardLinkFontSizeT2Token: applicationTokens.typeface.fontSizeMedium,
      contactCardNameFontSizeT2Token: applicationTokens.typeface.fontSizeLarge,
    },
    lineHeights: {
      contactCardLinkLineHeightT2Token:
        applicationTokens.typeface.lineHeightMedium,
      contactCardNameLineHeightT2Token:
        applicationTokens.typeface.lineHeightLarge,
    },
  },
  divider: {
    colors: {
      background: {
        dividerColorContextBackgroundPrimaryT2Token:
          applicationTokens.colors.colorContextBackgroundTertiary,
        dividerColorContextBackgroundLighterT2Token:
          applicationTokens.colors.colorContextBackgroundSecondary,
      },
    },
  },
  dragAndDrop: {
    colors: {
      background: {
        dragAndDropInteractionColorBackgroundT2Token:
          applicationTokens.colors.colorInteractionBackgroundQuaternary,
      },
    },
  },
  tabBar: {
    colors: {
      tabBarColorInteractionTextFocusT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,
      tabBarColorInteractionTextInactiveT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,
      tabBarColorInteractionTabLineFocusT2Token:
        applicationTokens.colors.colorInteractionLinePrimary,
      tabBarColorInteractionTabLineInactiveT2Token: "not set",
    },
  },
  toggle: {
    colors: {
      toggleColorInteractionInteractiveT2Token: "not set",
      toggleColorInteractionFocusT2Token: "not set",
    },
  },
  documentTaggingPage: {
    colors: {
      headerBackgroundColor: applicationTokens.colors.lightBackground,
      borderColor: applicationTokens.colors.colorInteractionBorderPrimary,
    },
    boxShadows: {
      header: applicationTokens.boxStyles.headerShadow,
      document: applicationTokens.boxStyles.documentShadow,
    },
  },
  stageDetails: {
    colors: {
      subHeaderBackgroundColor:
        applicationTokens.colors.colorUniversalSecondaryE,
      firstSectionBackgroundColor:
        applicationTokens.colors.colorUniversalSecondaryF,
      uploadFileColor: applicationTokens.colors.colorUniversalSecondaryG,
      uploadFileStroke: applicationTokens.colors.colorUniversalSecondaryG,
    },
  },
  tabbedFolder: {
    boxStyles: {
      boxStyleActionBorderRadiusSmallT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusSmall,
      actionShadowPrimaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
    },
  },
  table: {
    colors: {
      tableColorContextBackgroundLabelT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
    },
  },
  tag: {
    colors: {
      // Background color
      tagColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      tagColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryHover,
      tagColorInteractionBackgroundPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryFocus,

      // Text color
      tagColorInteractionTextT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      tagColorInteractionTextHoverT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      tagColorInteractionTextFocusT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,

      // border color
      tagColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,
      tagColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
    },
    boxStyles: {
      // radius
      borderRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusLarge,
    },
  },
  toast: {
    colors: {
      // border color
      toastColorFeedbackBorderInfoT2Token:
        applicationTokens.colors.colorFeedbackBorderInfo,
      toastColorFeedbackBorderSuccessT2Token:
        applicationTokens.colors.colorFeedbackBorderSuccess,
    },
  },
  search: {
    colors: {
      // Background color
      searchColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      searchColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundTertiaryHover,
      // Chevron color
      searchColorInteractionChevronT2Token:
        applicationTokens.colors.colorInteractionChevronPrimary,

      // Text color
      searchColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextSecondary,
      searchColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionTextTertiary,

      // border color
      searchColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,
      searchColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
    },
  },
  select: {
    colors: {
      selectColorInteractionTextTertiaryT2Token:
        applicationTokens.colors.colorInteractionTextTertiary,
    },
  },
  footer: {
    colors: {
      footerColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundSecondary,
    },
  },
  form: {
    colors: {
      // Background color
      formColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      formColorInteractionBackgroundPrimaryHoverT2Token: "not set",

      // Text color
      formColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      formLabelColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextTertiary,
      formColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionTextTertiary,

      // border color
      formColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,
      formColorInteractionBorderPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBorderSecondaryHover,
      formColorInteractionBorderPrimaryFocusT2Token: "not set",
    },
    boxStyles: {
      formColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusSmallX,
    },
  },
  lozenge: {
    colors: {
      // Background color
      lozengeColorFeedbackBackgroundPrimaryT2Token:
        applicationTokens.colors.colorFeedbackPrimary,
      lozengeColorFeedbackBackgroundPendingT2Token:
        applicationTokens.colors.colorFeedbackPrimary,
      lozengeColorFeedbackBackgroundPositiveT2Token:
        applicationTokens.colors.colorFeedbackBackgroundPositive,
      lozengeColorFeedbackBackgroundInfoT2Token:
        applicationTokens.colors.colorFeedbackBackgroundInfo,
      lozengeColorFeedbackBackgroundEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackBackgroundEmphasized,
      lozengeColorFeedbackBackgroundNegativeT2Token:
        applicationTokens.colors.colorFeedbackBackgroundNegative,
      lozengeColorFeedbackBackgroundRevokeT2Token:
        applicationTokens.colors.colorFeedbackBackgroundNegative,
      lozengeColorFeedbackBackgroundMultipleT2Token:
        applicationTokens.colors.colorFeedbackBackgroundMultiple,

      // Text color
      lozengeColorFeedbackTextPrimaryT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTextNegativeT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTexRevokeT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTexInfoT2Token:
        applicationTokens.colors.colorFeedbackTextSecondary,
      lozengeColorFeedbackTexPositiveT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTexEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTexMultipleT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,

      // Border color
      lozengeColorFeedbackBorderPrimaryT2Token:
        applicationTokens.colors.colorFeedbackBorderPrimary,
      lozengeColorFeedbackBorderPendingT2Token:
        applicationTokens.colors.colorFeedbackBorderPrimary,
      lozengeColorFeedbackBorderPositiveT2Token:
        applicationTokens.colors.colorFeedbackBorderPositive,
      lozengeColorFeedbackBorderNegativeT2Token:
        applicationTokens.colors.colorFeedbackBorderNegative,
      lozengeColorFeedbackBorderRevokeT2Token:
        applicationTokens.colors.colorFeedbackBorderNegative,
      lozengeColorFeedbackBorderInfoT2Token:
        applicationTokens.colors.colorFeedbackBorderInfoSecondary,
      lozengeColorFeedbackBorderEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackBorderEmphasized,
      lozengeColorFeedbackBorderMultipleT2Token:
        applicationTokens.colors.colorFeedbackBorderMultiple,
    },
  },
  link: {
    colors: {
      linkColorActionTextLineT2Token:
        applicationTokens.colors?.colorActionTextSecondary,
      linkColorActionTextPrimaryT2Token:
        applicationTokens.colors?.colorActionTextSecondary,
      linkColorActionTextPrimaryHoverT2Token:
        applicationTokens.colors?.colorActionTextHover,
    },
  },
  modal: {
    colors: {
      modalColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
      modalColorInteractionScrollbarForegroundT2Token:
        applicationTokens.colors.colorUniversalSecondaryG,
      modalColorInteractionScrollbarBackgroundT2Token:
        applicationTokens.colors.colorUniversalSecondaryE,
    },
    footer: {
      modalFooterButtonsJustifyContentT2Token:
        applicationTokens.flexLayout.flexEnd,
      modalFooterButtonsFlexDirectionMobileT2Token:
        applicationTokens.flexLayout.columnReverse,
      modalFooterButtonsFlexDirectionTabletT2Token:
        applicationTokens.flexLayout.row,
      modalFooterButtonsFlexDirectionDesktopT2Token:
        applicationTokens.flexLayout.row,
    },
  },
  navbar: {
    colors: {
      navbarColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondary,
      navbarColorInteractionBackgroundExpandT2Token:
        applicationTokens.colors.colorInteractionBackgroundTertiary,
      navbarColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextQuaternary,
      navbarColorInteractionBackgroundActiveT2Token: "not set",
    },
  },
  page: {
    colors: {
      pageColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
      pageColorContextBackgroundSecondaryT2Token:
        applicationTokens.colors.colorContextBackgroundSecondary,
    },
  },
  pagination: {
    colors: {
      // Background color
      paginationColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      paginationColorInteractionBackgroundPrimaryFocusT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimaryFocus,

      // border color
      paginationColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBorderPrimary,
      // Text color
      paginationColorInteractionTextPrimaryT2Token:
        applicationTokens.colors?.colorInteractionTextTertiary,
      paginationColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionTextQuaternary,
      // Chevron color
      paginationColorInteractionChevronT2Token:
        applicationTokens.colors.colorInteractionChevronPrimary,
    },
  },
  panel: {
    colors: {
      panelColorBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
    },
    boxStyles: {
      boxStyleActionBorderRadiusSmallT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusSmall,
      panelShadowPrimary: applicationTokens.boxStyles.actionShadowPrimary,
      actionShadowPrimaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
    },
  },
  profileButton: {
    colors: {
      profileButtonColorTextT2Token:
        applicationTokens.colors.colorUniversalSecondaryE,

      // Background color
      profileButtonColorBackgroundT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
    },
    outlines: {
      profileButtonOutlineT2Token: `3px solid ##E8E8E8`,
      profileButtonOutlineOffsetT2Token: "0px",
    },
  },
  results: {
    colors: {
      resultsColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      // Background color
      resultsColorInteractionBackgroundChevronT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      // Chevron color
      resultsColorInteractionChevronT2Token:
        applicationTokens.colors?.colorInteractionChevronSecondary,
      // border color
      resultsColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBorderPrimary,
    },
  },
  thisIsATestVariable: {
    colors: {
      thisIsATestVariableToken: "#ffffff",
    },
  },
}
