import React, { createContext, useState, useContext, useEffect } from "react"
import { ApiController } from "../../api/apiController"
import { loginTypes } from "../../utils/auth/loginTypes"
import { checkForParamInUrlSearchParams } from "../../utils/auth/lib/checkForParamInUrlSearchParams"
import { castToBool } from "../../utils/helpers"

interface PlatformContextState {
  features: string[]
  themeName: string
  loginType: string
}

interface PlatformContextProps {
  children: React.ReactNode
}

const api = ApiController.getInstance()

const initialPlatformContextState: PlatformContextState = {
  features: [],
  themeName: "",
  loginType: "",
}

export const PlatformContext = createContext<PlatformContextState>(
  initialPlatformContextState
)

export const PlatformContextProvider: React.FC<PlatformContextProps> = ({
  children,
}) => {
  const [platformDetails, setPlatformDetails] = useState<PlatformContextState>(
    initialPlatformContextState
  )

  useEffect(() => {
    async function fetchPlatformData() {
      // Theme can be passed through query string in dev and qa
      const allowApiOverrideForTheme = castToBool(
        process.env.REACT_APP_ALLOW_THEME_QUERYSTRING
      )
      const params = new URLSearchParams(window.location.search.toLowerCase())
      const themeFromUrl = allowApiOverrideForTheme
        ? checkForParamInUrlSearchParams(params, "theme")
        : undefined

      try {
        const featureSet = await api.getAllFeatures()
        if (featureSet && featureSet.featureDtos?.length) {
          const features = featureSet.featureDtos.map(
            (feature) => feature.name ?? ""
          )

          setPlatformDetails({
            features,
            themeName: themeFromUrl
              ? themeFromUrl.toUpperCase()
              : featureSet.theme || "Legado",
            loginType: featureSet.loginMethod
              ? featureSet.loginMethod
              : loginTypes.PASSWORD,
          })
        }
      } catch (error) {
        console.error("Error:", error)
      }
    }
    fetchPlatformData()
  }, [])

  return (
    <PlatformContext.Provider value={platformDetails}>
      {children}
    </PlatformContext.Provider>
  )
}

export const usePlatformContext = (): PlatformContextState =>
  useContext(PlatformContext)
