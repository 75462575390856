export enum LocalStorageKeys {
  /** The below is used for COOP integration or Password Login type */
  Token = "token",
  ApiKey = "apiKey",
  /** Username is used for the Personal Representative role */
  UserName = "userName",
  HasMultipleCases = "hasMultipleCases",
  HasFilesSharedWith = "hasFilesSharedWith",
  IsCurrentStageAllowingUploads = "isCurrentStageAllowingUploads",
  /** When the user has multiple cases the SelectedCaseId is set after the user choose a case from the case list (ChooseProbateCasePage) */
  SelectedCaseId = "selectedCaseId",
  /** The below are only used for FNZ integration at the moment, its value is a code for example: B66099F420DE25B70643673E717A9460380F8AAA1B1D19ACDEAFDFB7F7C4A3E7 */
  RefreshToken = "refreshToken",

  /** To help with COOP SSO integration */
  /** UserEmail is use for the case-handler role */
  UserEmail = "userEmail",
  ExternalId = "externalId",

  /** It is use to identify if the app is being hosted in a mobile app or web
   * appType = web | ios | android
   */
  AppType = "appType",
  /**
   * This is used for the user who logged in via Login Page
   */
  HasUsedLoginPageToSignIn = "hasUsedLoginPageToSignIn",
}
