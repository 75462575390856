"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileVisibility = void 0;
/**
 *   0 = AdminOnly  1 = AdviserOnly  2 = ClientOnly  3 = ClientAndAdviser
 * @export
 * @enum {string}
 */
var FileVisibility;
(function (FileVisibility) {
    FileVisibility[FileVisibility["NUMBER_0"] = 0] = "NUMBER_0";
    FileVisibility[FileVisibility["NUMBER_1"] = 1] = "NUMBER_1";
    FileVisibility[FileVisibility["NUMBER_2"] = 2] = "NUMBER_2";
    FileVisibility[FileVisibility["NUMBER_3"] = 3] = "NUMBER_3";
})(FileVisibility || (exports.FileVisibility = FileVisibility = {}));
