import { useState, KeyboardEvent } from "react"
import { useKeyboard } from "../../../hooks/useKeyboard"
import { LoginSteps } from "../../../pages/LoginPage/LoginPage"
import checkFormValidity, {
  FormError,
  InputField,
} from "../../../utils/forms/checkFormValidity"
import { capitalizeFirstLetter } from "../../../utils/string"
import { BUTTON_VARIANT, Button } from "../../atoms/Button"
import CheckBox from "../../atoms/CheckBox/CheckBox"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { InputFieldType } from "../../../utils/forms/validateInputs"
import PanelHeader from "../../atoms/PanelHeader/PanelHeader"
import { TextInputWithValidation } from "../../atoms/TextInput/TextInputWithValidation"
import Panel from "../../modules/Panel/Panel"
import "./SignInPanel.css"

const fields: InputField = {
  email: {
    // TODO: This `id` should be a string and could be for example "sign_in_email" https://dev.azure.com/secure-the-file/Application/_workitems/edit/15789
    id: 1,
    name: "email",
    type: InputFieldType.SIMPLE_EMAIL,
    label: "Email",
  },
  password: {
    // TODO: This `id` should be a string and could be for example "sign_in_password" https://dev.azure.com/secure-the-file/Application/_workitems/edit/15789
    id: 2,
    name: "password",
    type: InputFieldType.PASSWORD,
    label: "Password",
  },
}

type SignInData = {
  email: string
  password: string
}

type SignInPanelProps = {
  onSignIn: (signInData: SignInData) => void
  serverErrorMessage: string
  isSubmitting?: boolean
  setServerErrorMessage: (message: string) => void
  setLoginStep: (step: LoginSteps) => void
}

const SignInPanel = ({
  onSignIn,
  serverErrorMessage,
  isSubmitting,
  setServerErrorMessage,
  setLoginStep,
}: SignInPanelProps) => {
  const [inputErrors, setInputErrors] = useState<FormError[]>([])

  const [signInData, setSignInData] = useState({ email: "", password: "" })
  const [showPassword, setShowPassword] = useState(false)
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>("")
  const [errorPasswordMessage, setErrorPasswordMessage] = useState<string>("")

  const handleFieldInputChange = (fieldType: string, newValue: string) => {
    setSignInData((prevState) => ({
      ...prevState,
      [fieldType]: newValue,
    }))
  }

  const handleSubmit = () => {
    setServerErrorMessage("")
    const formErrors = checkFormValidity(fields, signInData)
    setInputErrors(formErrors)

    //Set the error validation messages to be displayed on the field inputs
    const emailError = formErrors.find((error) => error.id === fields.email.id)
    if (emailError?.message) {
      const messageWithUppercase = capitalizeFirstLetter(emailError.message)
      setErrorEmailMessage(messageWithUppercase)
    } else {
      setErrorEmailMessage("")
    }

    const passwordError = formErrors.find(
      (error) => error.id === fields.password.id
    )
    if (passwordError?.message) {
      const messageWithUppercase = capitalizeFirstLetter(passwordError.message)
      setErrorPasswordMessage(messageWithUppercase)
    } else {
      setErrorPasswordMessage("")
    }

    if (formErrors.length === 0) {
      onSignIn(signInData)
    }
  }

  const getIsInvalid = (field: "email" | "simple_email" | "password") => {
    if (field === "email" || field === "simple_email") {
      return inputErrors.some((error) => error.id === fields.email.id)
    }
    return inputErrors.some((error) => error.id === fields.password.id)
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLowerCase() === "enter") {
      setLoginStep(LoginSteps.ForgottenPassword)
    }
  }

  useKeyboard("Enter", handleSubmit)

  return (
    <Panel className="sign-in-panel">
      <PanelHeader
        title="Sign in"
        paragraphText={
          <>
            and continue to <strong>probate case tracking</strong>
          </>
        }
        error={serverErrorMessage}
        inputErrors={inputErrors}
      />
      <div className="sign-in-panel__form">
        <TextInputWithValidation
          id={fields.email.id.toString()}
          label={fields.email.label}
          name={fields.email.name}
          type={InputFieldType.EMAIL}
          value={signInData.email}
          onChange={(newValue) =>
            handleFieldInputChange(fields.email.name, newValue)
          }
          displayError={getIsInvalid("email")}
          errorMessagePosition="above"
          showThickErrorBorder={getIsInvalid("email")}
          shouldShowWarningIcon={false}
          validateOnSubmit
          ariaErrorMessageId="email-error"
          errorOnSubmit={{
            hasError: getIsInvalid("email"),
            message: errorEmailMessage ?? "",
          }}
          hasAutoFocus
        />
        <div className="password-labels-container">
          <label className="password-label">Password</label>
          <div
            className="forgot-password-link"
            onClick={() => setLoginStep(LoginSteps.ForgottenPassword)}
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            Forgotten password
          </div>
        </div>
        <TextInputWithValidation
          id={fields.password.id.toString()}
          label={fields.password.label}
          name={fields.password.name}
          value={signInData.password}
          onChange={(newValue) =>
            handleFieldInputChange(fields.password.name, newValue)
          }
          type={InputFieldType.PASSWORD}
          displayError={getIsInvalid("password")}
          errorMessagePosition="above"
          showThickErrorBorder={getIsInvalid("password")}
          hidePassword={!showPassword}
          shouldShowWarningIcon={false}
          errorOnSubmit={{
            hasError: getIsInvalid("password"),
            message: errorPasswordMessage ?? "",
          }}
          ariaErrorMessageId="password-error"
        />
      </div>
      <div className="sign-in-panel__show-password">
        <CheckBox
          id="show-password-checkbox"
          onChange={() => setShowPassword(!showPassword)}
          isChecked={showPassword}
          shouldEnableFocusOutline
        />
        <label htmlFor={"show-password-checkbox"}>Show password</label>
      </div>
      <div className="sign-in-panel__submit">
        {/* TODO: We should add the prop isBusy or isLoading in the button  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437*/}
        <Button
          onClick={handleSubmit}
          variant={BUTTON_VARIANT.PRIMARY}
          isDisabled={isSubmitting}
        >
          {!isSubmitting && "Sign in"}
          {isSubmitting && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="mr-1">Signing in...</span>
              <LoadingSpinner
                size="20px"
                thickness="2px"
                color="var(--color-universal-secondary-e)"
              />
            </div>
          )}
        </Button>
      </div>
    </Panel>
  )
}

export default SignInPanel
