import { SetStateAction, useEffect, Dispatch } from "react"
import { IFile } from "../api/api-client/api-types"
import { useApplicationContext } from "../contexts/application/context"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../contexts/toasts"
import { ApiController } from "../api/apiController"
import { notificationMessages } from "../data/notificationMessages"
import { GetGAFolderType, sendGaData } from "../utils/gaEvents"
import { getFolder } from "../api/api-client/api-handler"
import { useUserContext } from "../contexts/users"
import { AppType } from "../utils/enums/AppType"
import { LocalStorageKeys } from "../contexts/auth/localStorageKeys"

export const useDownloadFile = ({
  file,
  isDownloadFile,
  setIsDownloading,
}: {
  file: IFile
  isDownloadFile: boolean
  setIsDownloading: Dispatch<SetStateAction<boolean>>
}) => {
  const {
    applicationState: { folders, sharedWithMeFolders },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  useEffect(() => {
    const downloadFile = async () => {
      const parentFolder = getFolder({
        folders: file.isOwner ? folders : sharedWithMeFolders ?? [],
        folderId: file.parentId ?? "",
      })

      try {
        var extension = file.extension?.toLowerCase()
        var fileName = file.name + "." + extension

        sendGaData({
          name: "file_download",
          file_extension: file.extension?.toLowerCase(),
          is_node_owner: file.isOwner ? "Y" : "N",
          ...(parentFolder && {
            folder_name: parentFolder.name,
            folder_depth: parentFolder.level,
            folder_type: GetGAFolderType(parentFolder),
            currentUser,
          }),
        })

        const api = ApiController.getInstance()
        const response = await api.download(
          file.id,
          fileName,
          file.mimeType ?? "text/plain"
        )

        if (window.parent) {
          window.parent.postMessage(
            {
              fileName,
              fileId: file.id,
              fileType: file.mimeType ?? "text/plain",
              eventType: "fileDownload",
            },
            "*"
          )
        }

        if (response) {
          sendGaData({
            name: "file_download_response",
            file_extension: file.extension?.toLowerCase(),
            is_node_owner: file.isOwner ? "Y" : "N",
            request_status: "success",
            ...(parentFolder && {
              folder_name: parentFolder.name,
              folder_depth: parentFolder.level,
              folder_type: GetGAFolderType(parentFolder),
              currentUser,
            }),
          })

          const appTypeFromLocalStorage = localStorage.getItem(
            LocalStorageKeys.AppType
          )
          if (
            appTypeFromLocalStorage === AppType.Ios ||
            appTypeFromLocalStorage === AppType.Android
          ) {
            return
          }

          toastDispatch(
            setDisplaySuccessOrErrorMessage({
              message: notificationMessages.downloadFile.SUCCESS,
              messageType: "SUCCESS",
            })
          )
        }
      } catch (e) {
        sendGaData({
          name: "file_download_response",
          file_extension: file.extension?.toLowerCase(),
          is_node_owner: file.isOwner ? "Y" : "N",
          request_status: "error",
          ...(parentFolder && {
            folder_name: parentFolder.name,
            folder_depth: parentFolder.level,
            folder_type: GetGAFolderType(parentFolder),
            currentUser,
          }),
        })

        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            message: notificationMessages.downloadFile.ERROR,
            messageType: "ERROR",
          })
        )
      }
    }

    if (isDownloadFile) {
      setIsDownloading(false)
      downloadFile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadFile])
}
