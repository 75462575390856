import { FC } from "react"
import classNames from "classnames"
import { useApplicationContext } from "../../contexts/application/context"
import LegadoLogo, { LogoVariant } from "../../images/LegadoLogo"
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from "../atoms/Button"
import { ProfileButton } from "../atoms/ProfileButton/ProfileButton"
import { TFEFooterLogo } from "../../design-tokens/imgs/TFEFooter"
import { FCLogo } from "../../design-tokens/imgs/fortCirrus/mainLogo"
import { OneLegacyLogo } from "../../design-tokens/imgs/oneLegacy/legacyLogo"
import AmadeusTopBarLogo from "../../design-tokens/imgs/amadeus/topBarMobileLogo"
import { LifestageMobileLogo } from "../../design-tokens/imgs/lifestage/lifestageMobileLogo"
import "./MenuBar.css"

export interface LeftNavMobileMenuProps {
  onMenuClick: () => void
  isExpanded: boolean
}

/**
 * Mobile menu for the left navigation bar
 * This is basically the top bar that shows up on mobile devices
 * That allows the user to open the left navigation bar
 */
export const LeftNavMobileMenu: FC<LeftNavMobileMenuProps> = ({
  onMenuClick,
  isExpanded,
}) => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()

  const MobileLogo = ({ selectedTheme }: { selectedTheme: string }) => {
    switch (selectedTheme) {
      case "AMADEUS":
        return <AmadeusTopBarLogo />
      case "FORT_CIRRUS":
        return <FCLogo />
      case "LIFESTAGE":
        return <LifestageMobileLogo />
      case "ONE_LEGACY":
        return <OneLegacyLogo />
      case "THE_FAMILY_ELEPHANT":
        return <TFEFooterLogo />
      default:
        return (
          <div>
            <LegadoLogo variant={LogoVariant.WITH_NAME_DEFAULT} />
            <div className="logo-text">Personal Document Vault</div>
          </div>
        )
    }
  }

  const menuButtonClass = classNames("menu-button", {
    "menu-button-closed": !isExpanded,
  })

  return (
    <div className="legado-menu-container">
      <div className="nav-menu-top-bar">
        <div className="bar">
          <Button
            className={menuButtonClass}
            variant={BUTTON_VARIANT.MENU}
            size={BUTTON_SIZE.SMALL}
            onClick={() => onMenuClick()}
          >
            Menu
          </Button>
        </div>
        {selectedTheme && <MobileLogo selectedTheme={selectedTheme} />}
        <div className="account-button">
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}
