import { FC, useEffect, useState } from "react"
import { TextArea } from "../atoms/TextArea"
import { Button } from "../atoms/Button"
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modules/Modal"
import { IContactInvite } from "../../api/api-client/api-types"

export interface PersonalProps {
  addingSharee?: boolean
  contact: IContactInvite
  setContact?: (contact: IContactInvite) => void
  onBackButtonClick: () => void
  onContinueButtonClick: () => void
  sharingOptions?: any
  isClient?: boolean
}

export const Personal: FC<PersonalProps> = ({
  addingSharee,
  contact,
  setContact,
  onBackButtonClick,
  onContinueButtonClick,
  sharingOptions,
  isClient = false,
}) => {
  const [contact2, setContact2] = useState(contact)

  useEffect(() => {
    setContact2(contact)
  }, [contact])

  return (
    <>
      <ModalHeader
        className={`has-subheader personal ${
          isClient ? "create-client-modal" : ""
        }`}
      >
        Add new contact
      </ModalHeader>
      <ModalSubHeader
        className={`header__border-bottom--base ${
          isClient ? "create-client-modal" : ""
        }`}
      >
        {`${sharingOptions.length === 0 ? "2/" : "3/"}${
          addingSharee || sharingOptions.length === 0 ? "3" : "4"
        }: ${isClient ? "Invite client." : "Add a personal note"}`}
      </ModalSubHeader>
      <ModalContent className="create-contact-modal">
        <div className="personal-note-text fw-300">
          Add a personal note? This will help {isClient ? "client" : "contact"}s
          know why content is being shared.
        </div>
        <div key="personal-note" className="mb-3">
          <TextArea
            id="personal-note"
            name="Personal Note"
            label="Add a personal note "
            span="(Optional)"
            value={contact2.message!}
            onChange={(newMessage: string) => {
              const newContact = {
                ...contact2,
                message: newMessage,
              }
              setContact2(newContact)
              if (setContact) {
                setContact(newContact)
              }
            }}
          />
        </div>
      </ModalContent>
      <ModalFooter className="create-contact-footer modal-footer flex">
        <Button
          className="mr-4"
          variant="tertiary"
          type="reset"
          onClick={onBackButtonClick}
        >
          Back
        </Button>
        <Button variant="primary" type="submit" onClick={onContinueButtonClick}>
          Review summary
        </Button>
      </ModalFooter>
    </>
  )
}
