"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthApi = exports.HealthApiFactory = exports.HealthApiFp = exports.HealthApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * HealthApi - axios parameter creator
 * @export
 */
const HealthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Ping the Crypto API
         * @summary Pings the Crypto API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCryptoPingGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Health/CryptoPing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Ping the External Connector API
         * @summary Pings the External Connector API  remoteScheme 0 = None, 1 = FNZ, 2 = WonderBill
         * @param {number} [remoteScheme]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorPingGet: (remoteScheme, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Health/ExternalConnectorPing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (remoteScheme !== undefined) {
                localVarQueryParameter['remoteScheme'] = remoteScheme;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Super Ping the External Connector API
         * @summary Check If The FNZ Resources Are Available For The Platform  externalId The Required externalId to identify platform
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorSuperPingGet: (externalId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Health/ExternalConnectorSuperPing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Ping the API
         * @summary Ping the API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthPingGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Health/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.HealthApiAxiosParamCreator = HealthApiAxiosParamCreator;
/**
 * HealthApi - functional programming interface
 * @export
 */
const HealthApiFp = function (configuration) {
    return {
        /**
         * Ping the Crypto API
         * @summary Pings the Crypto API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCryptoPingGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.HealthApiAxiosParamCreator)(configuration).apiHealthCryptoPingGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Ping the External Connector API
         * @summary Pings the External Connector API  remoteScheme 0 = None, 1 = FNZ, 2 = WonderBill
         * @param {number} [remoteScheme]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorPingGet(remoteScheme, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.HealthApiAxiosParamCreator)(configuration).apiHealthExternalConnectorPingGet(remoteScheme, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Super Ping the External Connector API
         * @summary Check If The FNZ Resources Are Available For The Platform  externalId The Required externalId to identify platform
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorSuperPingGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.HealthApiAxiosParamCreator)(configuration).apiHealthExternalConnectorSuperPingGet(externalId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Ping the API
         * @summary Ping the API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthPingGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.HealthApiAxiosParamCreator)(configuration).apiHealthPingGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.HealthApiFp = HealthApiFp;
/**
 * HealthApi - factory interface
 * @export
 */
const HealthApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Ping the Crypto API
         * @summary Pings the Crypto API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCryptoPingGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.HealthApiFp)(configuration).apiHealthCryptoPingGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Ping the External Connector API
         * @summary Pings the External Connector API  remoteScheme 0 = None, 1 = FNZ, 2 = WonderBill
         * @param {number} [remoteScheme]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorPingGet(remoteScheme, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.HealthApiFp)(configuration).apiHealthExternalConnectorPingGet(remoteScheme, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Super Ping the External Connector API
         * @summary Check If The FNZ Resources Are Available For The Platform  externalId The Required externalId to identify platform
         * @param {string} [externalId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthExternalConnectorSuperPingGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.HealthApiFp)(configuration).apiHealthExternalConnectorSuperPingGet(externalId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Ping the API
         * @summary Ping the API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthPingGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.HealthApiFp)(configuration).apiHealthPingGet(options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.HealthApiFactory = HealthApiFactory;
/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
class HealthApi extends base_1.BaseAPI {
    /**
     * Ping the Crypto API
     * @summary Pings the Crypto API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    apiHealthCryptoPingGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.HealthApiFp)(this.configuration).apiHealthCryptoPingGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Ping the External Connector API
     * @summary Pings the External Connector API  remoteScheme 0 = None, 1 = FNZ, 2 = WonderBill
     * @param {number} [remoteScheme]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    apiHealthExternalConnectorPingGet(remoteScheme, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.HealthApiFp)(this.configuration).apiHealthExternalConnectorPingGet(remoteScheme, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Super Ping the External Connector API
     * @summary Check If The FNZ Resources Are Available For The Platform  externalId The Required externalId to identify platform
     * @param {string} [externalId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    apiHealthExternalConnectorSuperPingGet(externalId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.HealthApiFp)(this.configuration).apiHealthExternalConnectorSuperPingGet(externalId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Ping the API
     * @summary Ping the API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    apiHealthPingGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.HealthApiFp)(this.configuration).apiHealthPingGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.HealthApi = HealthApi;
