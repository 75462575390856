"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkDistributionApi = exports.BulkDistributionApiFactory = exports.BulkDistributionApiFp = exports.BulkDistributionApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * BulkDistributionApi - axios parameter creator
 * @export
 */
const BulkDistributionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Retrieves data from the cache if available, otherwise fetches it from the database and caches it.
         * @summary Get All Product Types by Platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProducttypesGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/BulkDistribution/producttypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets all the information for all available distribution project
         * @summary Get All Distribution Project Infos
         * @param {number} [refresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectAllGet: (refresh, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/BulkDistribution/project/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (refresh !== undefined) {
                localVarQueryParameter['refresh'] = refresh;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns data for the Analytics Campaign Report
         * @summary Generate Campaign Report
         * @param {string} projectId Guid for project
         * @param {Date} [startDate] Start date
         * @param {Date} [endDate] End date
         * @param {string} [fileId] (Optional) Filter by file Id (defaults to returning data for entire project)
         * @param {number} [version] (Optional) Filter by file version (defaults to all)
         * @param {number} [device] (Optional) Filter by device views/downloads (defaults to All; -1 &#x3D; All, 0 &#x3D; Browser, 1 &#x3D; WebApp)
         * @param {number} [userType] (Optional) Filter by user type (defaults to Both; 0 &#x3D; Both, 1 &#x3D; Clients, 2 &#x3D; Advisers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCampaignreportGet: (projectId, startDate, endDate, fileId, version, device, userType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdCampaignreportGet.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/campaignreport`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate instanceof Date) ?
                    startDate.toISOString() :
                    startDate;
            }
            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate instanceof Date) ?
                    endDate.toISOString() :
                    endDate;
            }
            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }
            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }
            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }
            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the client list and relevant information for a particular project
         * @summary Get Client List by Project
         * @param {string} projectId Id of the Project
         * @param {string} [fileId] (Optional) Id of the file.
         * @param {number} [version] (Optional) Version of the distributed documents.
         * @param {number} [status] (Optional) Viewed status of the distributed documents.
         * @param {number} [start] (Optional) Start index of list.
         * @param {number} [count] (Optional) Number of clients to return.
         * @param {number} [sortBy] (Optional) How the list of clients is sorted. 0 &#x3D; By Name, 1 &#x3D; By Email, 2 &#x3D; By Opened Date.
         * @param {boolean} [desc] (Optional) Whether the list is sorted in descending order.
         * @param {string} [search] (Optional) Search string to filter the list of clients.
         * @param {number} [revokedStatus] (Optional) Filter on whether the client has been revoked or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientlistGet: (projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdClientlistGet.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/clientlist`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }
            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }
            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }
            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }
            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }
            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }
            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (revokedStatus !== undefined) {
                localVarQueryParameter['revokedStatus'] = revokedStatus;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets the list of clients for the distribution project
         * @param {string} projectId Id of the project
         * @param {number} [start] start index of results for pagination purposes
         * @param {number} [count] amount of results
         * @param {boolean} [sortDesc] sort by descending or ascending
         * @param {string} [search] search terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientsGet: (projectId, start, count, sortDesc, search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdClientsGet.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/clients`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }
            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }
            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Removes criteria from a distribution project that  currently hasn't been run yet. This will necessarily  bring the proiect back to the criteria upload stage
         * @summary Removes criteria from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCriteriaRemovePut: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdCriteriaRemovePut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/criteria/remove`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a CSV file containing a list of clients the  distribution file is to be shared between
         * @summary Uploads CSV criteria file for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCsvuploadPut: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdCsvuploadPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/csvupload`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Deletes the distribution project and related files if it is in draft.  Cancels the project if it is scheduled but has not distributed yet.
         * @summary Cancel/Delete a distribution project
         * @param {string} projectId Id of the project
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdDelete: (projectId, test, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdDelete.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Revoke a particular file from the distribution list when already pushed to the Vault
         * @summary Revoke a single file from the distribution
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdRevokePut: (projectId, fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdRevokePut.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdRevokePut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/file/{fileId}/revoke`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Uploads a new version of an existing distributing file, replacing the old version
         * @summary Upload a new version of an existing distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {boolean} [sendNotification] (Optional) Determines whether notification should be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut: (projectId, fileId, sendNotification, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/file/{fileId}/version/new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (sendNotification !== undefined) {
                localVarQueryParameter['sendNotification'] = sendNotification;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the version of a particular distributing file
         * @summary Update the version of a particular distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {number} newVersion New version number to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut: (projectId, fileId, newVersion, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut.');
            }
            // verify required parameter 'newVersion' is not null or undefined
            if (newVersion === null || newVersion === undefined) {
                throw new base_1.RequiredError('newVersion', 'Required parameter newVersion was null or undefined when calling apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/file/{fileId}/version/update/{newVersion}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"newVersion"}}`, encodeURIComponent(String(newVersion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Removes file(s) from a distribution project that  currently hasn't been run yet
         * @summary Removes file(s) from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {Array<string>} [body] List of files to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileRemovePut: (projectId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdFileRemovePut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/file/remove`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a list of filter criteria info for clients the  distribution file is to be shared between
         * @summary Uploads filter criteria information for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {DistributionFilterDto} [body] Dto with filter criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFiltercriteriaPut: (projectId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdFiltercriteriaPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/filtercriteria`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets all the information for a distribution project
         * @summary Get Distribution Project Info
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdGet: (projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdGet.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates the name of a project
         * @summary Updates the name of a project
         * @param {string} projectId Id of the project
         * @param {string} [newName] New name of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdNamePatch: (projectId, newName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdNamePatch.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/name`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (newName !== undefined) {
                localVarQueryParameter['newName'] = newName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload details required for a draft distribution project
         * @summary Uploads details for drafted distribution project
         * @param {string} projectId Id of project to save details against
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSaveprojectdetailsPut: (projectId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdSaveprojectdetailsPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/saveprojectdetails`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload the remaining details required and schedules the  file distribution project
         * @summary Uploads details and schedules for distribution project
         * @param {string} projectId Id for project
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSchedulePut: (projectId, body, test, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdSchedulePut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/schedule`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Revokes all distributed files from a project from a particular user's vault
         * @summary Revoke all distributed files from a project from a particular user's vault
         * @param {string} projectId Id of the project the file was distributed from
         * @param {string} userId Id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut: (projectId, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/user/{userId}/revoke/all`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove a user from a distribution project through their email
         * @param {string} projectId Id of the project
         * @param {Array<string>} [body] Array of users emails to be removed from the project (Taken from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUsersPatch: (projectId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdUsersPatch.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/users`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the visibility of a particular distributing project
         * @summary Update the visibility of a particular distributing project
         * @param {string} projectId Id of the project
         * @param {string} newVisibility New visibility to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch: (projectId, newVisibility, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new base_1.RequiredError('projectId', 'Required parameter projectId was null or undefined when calling apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch.');
            }
            // verify required parameter 'newVisibility' is not null or undefined
            if (newVisibility === null || newVisibility === undefined) {
                throw new base_1.RequiredError('newVisibility', 'Required parameter newVisibility was null or undefined when calling apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch.');
            }
            const localVarPath = `/api/BulkDistribution/project/{projectId}/visibility/{newVisibility}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"newVisibility"}}`, encodeURIComponent(String(newVisibility)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get information about number of projects sent and number of documents opened per date within the date range
         * @param {Date} [startDate] date for lower range limit(inclusive), if null it will default to the date of the first completed project
         * @param {Date} [endDate] date for upper range limit (inclusive)
         * @param {PlatformContext} [context] context in which files were opened. -1 &#x3D; All , 0 &#x3D; Browser , 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {TimePeriod} [timePeriod] period range of returned data. 0 &#x3D; daily , 1 &#x3D; weekly , 2 &#x3D; Monthly , 3 &#x3D; Yearly  0 &#x3D; Daily  1 &#x3D; Weekly  2 &#x3D; Monthly  3 &#x3D; Yearly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsDatesGet: (startDate, endDate, context, timePeriod, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/BulkDistribution/projects/analytics/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate instanceof Date) ?
                    startDate.toISOString() :
                    startDate;
            }
            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate instanceof Date) ?
                    endDate.toISOString() :
                    endDate;
            }
            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }
            if (timePeriod !== undefined) {
                localVarQueryParameter['timePeriod'] = timePeriod;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get the list of projects sent within that date range, total amount of documents opened, percentage of opened documents and amount of documents downloaded
         * @param {Date} [startDate] Start date for filtering projects sent list (inclusive)
         * @param {Date} [endDate] End date for filtering projects sent list (inclusive)
         * @param {PlatformContext} [context] Context filter. Possible values are: -1 &#x3D; Any/All, 0 &#x3D; Browser, 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsGet: (startDate, endDate, context, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/BulkDistribution/projects/analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate instanceof Date) ?
                    startDate.toISOString() :
                    startDate;
            }
            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate instanceof Date) ?
                    endDate.toISOString() :
                    endDate;
            }
            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a file or multiple files for distribution
         * @summary Upload file(s) for distribution
         * @param {string} [projectName]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionUploadPost: (projectName, projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/BulkDistribution/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (projectName !== undefined) {
                localVarQueryParameter['projectName'] = projectName;
            }
            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.BulkDistributionApiAxiosParamCreator = BulkDistributionApiAxiosParamCreator;
/**
 * BulkDistributionApi - functional programming interface
 * @export
 */
const BulkDistributionApiFp = function (configuration) {
    return {
        /**
         * Retrieves data from the cache if available, otherwise fetches it from the database and caches it.
         * @summary Get All Product Types by Platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProducttypesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProducttypesGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets all the information for all available distribution project
         * @summary Get All Distribution Project Infos
         * @param {number} [refresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectAllGet(refresh, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectAllGet(refresh, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Returns data for the Analytics Campaign Report
         * @summary Generate Campaign Report
         * @param {string} projectId Guid for project
         * @param {Date} [startDate] Start date
         * @param {Date} [endDate] End date
         * @param {string} [fileId] (Optional) Filter by file Id (defaults to returning data for entire project)
         * @param {number} [version] (Optional) Filter by file version (defaults to all)
         * @param {number} [device] (Optional) Filter by device views/downloads (defaults to All; -1 &#x3D; All, 0 &#x3D; Browser, 1 &#x3D; WebApp)
         * @param {number} [userType] (Optional) Filter by user type (defaults to Both; 0 &#x3D; Both, 1 &#x3D; Clients, 2 &#x3D; Advisers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the client list and relevant information for a particular project
         * @summary Get Client List by Project
         * @param {string} projectId Id of the Project
         * @param {string} [fileId] (Optional) Id of the file.
         * @param {number} [version] (Optional) Version of the distributed documents.
         * @param {number} [status] (Optional) Viewed status of the distributed documents.
         * @param {number} [start] (Optional) Start index of list.
         * @param {number} [count] (Optional) Number of clients to return.
         * @param {number} [sortBy] (Optional) How the list of clients is sorted. 0 &#x3D; By Name, 1 &#x3D; By Email, 2 &#x3D; By Opened Date.
         * @param {boolean} [desc] (Optional) Whether the list is sorted in descending order.
         * @param {string} [search] (Optional) Search string to filter the list of clients.
         * @param {number} [revokedStatus] (Optional) Filter on whether the client has been revoked or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Gets the list of clients for the distribution project
         * @param {string} projectId Id of the project
         * @param {number} [start] start index of results for pagination purposes
         * @param {number} [count] amount of results
         * @param {boolean} [sortDesc] sort by descending or ascending
         * @param {string} [search] search terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Removes criteria from a distribution project that  currently hasn't been run yet. This will necessarily  bring the proiect back to the criteria upload stage
         * @summary Removes criteria from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a CSV file containing a list of clients the  distribution file is to be shared between
         * @summary Uploads CSV criteria file for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Deletes the distribution project and related files if it is in draft.  Cancels the project if it is scheduled but has not distributed yet.
         * @summary Cancel/Delete a distribution project
         * @param {string} projectId Id of the project
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdDelete(projectId, test, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdDelete(projectId, test, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Revoke a particular file from the distribution list when already pushed to the Vault
         * @summary Revoke a single file from the distribution
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Uploads a new version of an existing distributing file, replacing the old version
         * @summary Upload a new version of an existing distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {boolean} [sendNotification] (Optional) Determines whether notification should be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Update the version of a particular distributing file
         * @summary Update the version of a particular distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {number} newVersion New version number to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Removes file(s) from a distribution project that  currently hasn't been run yet
         * @summary Removes file(s) from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {Array<string>} [body] List of files to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a list of filter criteria info for clients the  distribution file is to be shared between
         * @summary Uploads filter criteria information for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {DistributionFilterDto} [body] Dto with filter criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets all the information for a distribution project
         * @summary Get Distribution Project Info
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdGet(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdGet(projectId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Updates the name of a project
         * @summary Updates the name of a project
         * @param {string} projectId Id of the project
         * @param {string} [newName] New name of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload details required for a draft distribution project
         * @summary Uploads details for drafted distribution project
         * @param {string} projectId Id of project to save details against
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload the remaining details required and schedules the  file distribution project
         * @summary Uploads details and schedules for distribution project
         * @param {string} projectId Id for project
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Revokes all distributed files from a project from a particular user's vault
         * @summary Revoke all distributed files from a project from a particular user's vault
         * @param {string} projectId Id of the project the file was distributed from
         * @param {string} userId Id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Remove a user from a distribution project through their email
         * @param {string} projectId Id of the project
         * @param {Array<string>} [body] Array of users emails to be removed from the project (Taken from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Update the visibility of a particular distributing project
         * @summary Update the visibility of a particular distributing project
         * @param {string} projectId Id of the project
         * @param {string} newVisibility New visibility to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get information about number of projects sent and number of documents opened per date within the date range
         * @param {Date} [startDate] date for lower range limit(inclusive), if null it will default to the date of the first completed project
         * @param {Date} [endDate] date for upper range limit (inclusive)
         * @param {PlatformContext} [context] context in which files were opened. -1 &#x3D; All , 0 &#x3D; Browser , 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {TimePeriod} [timePeriod] period range of returned data. 0 &#x3D; daily , 1 &#x3D; weekly , 2 &#x3D; Monthly , 3 &#x3D; Yearly  0 &#x3D; Daily  1 &#x3D; Weekly  2 &#x3D; Monthly  3 &#x3D; Yearly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get the list of projects sent within that date range, total amount of documents opened, percentage of opened documents and amount of documents downloaded
         * @param {Date} [startDate] Start date for filtering projects sent list (inclusive)
         * @param {Date} [endDate] End date for filtering projects sent list (inclusive)
         * @param {PlatformContext} [context] Context filter. Possible values are: -1 &#x3D; Any/All, 0 &#x3D; Browser, 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a file or multiple files for distribution
         * @summary Upload file(s) for distribution
         * @param {string} [projectName]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionUploadPost(projectName, projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.BulkDistributionApiAxiosParamCreator)(configuration).apiBulkDistributionUploadPost(projectName, projectId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.BulkDistributionApiFp = BulkDistributionApiFp;
/**
 * BulkDistributionApi - factory interface
 * @export
 */
const BulkDistributionApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Retrieves data from the cache if available, otherwise fetches it from the database and caches it.
         * @summary Get All Product Types by Platform
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProducttypesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProducttypesGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets all the information for all available distribution project
         * @summary Get All Distribution Project Infos
         * @param {number} [refresh]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectAllGet(refresh, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectAllGet(refresh, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Returns data for the Analytics Campaign Report
         * @summary Generate Campaign Report
         * @param {string} projectId Guid for project
         * @param {Date} [startDate] Start date
         * @param {Date} [endDate] End date
         * @param {string} [fileId] (Optional) Filter by file Id (defaults to returning data for entire project)
         * @param {number} [version] (Optional) Filter by file version (defaults to all)
         * @param {number} [device] (Optional) Filter by device views/downloads (defaults to All; -1 &#x3D; All, 0 &#x3D; Browser, 1 &#x3D; WebApp)
         * @param {number} [userType] (Optional) Filter by user type (defaults to Both; 0 &#x3D; Both, 1 &#x3D; Clients, 2 &#x3D; Advisers)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the client list and relevant information for a particular project
         * @summary Get Client List by Project
         * @param {string} projectId Id of the Project
         * @param {string} [fileId] (Optional) Id of the file.
         * @param {number} [version] (Optional) Version of the distributed documents.
         * @param {number} [status] (Optional) Viewed status of the distributed documents.
         * @param {number} [start] (Optional) Start index of list.
         * @param {number} [count] (Optional) Number of clients to return.
         * @param {number} [sortBy] (Optional) How the list of clients is sorted. 0 &#x3D; By Name, 1 &#x3D; By Email, 2 &#x3D; By Opened Date.
         * @param {boolean} [desc] (Optional) Whether the list is sorted in descending order.
         * @param {string} [search] (Optional) Search string to filter the list of clients.
         * @param {number} [revokedStatus] (Optional) Filter on whether the client has been revoked or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Gets the list of clients for the distribution project
         * @param {string} projectId Id of the project
         * @param {number} [start] start index of results for pagination purposes
         * @param {number} [count] amount of results
         * @param {boolean} [sortDesc] sort by descending or ascending
         * @param {string} [search] search terms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Removes criteria from a distribution project that  currently hasn't been run yet. This will necessarily  bring the proiect back to the criteria upload stage
         * @summary Removes criteria from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a CSV file containing a list of clients the  distribution file is to be shared between
         * @summary Uploads CSV criteria file for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Deletes the distribution project and related files if it is in draft.  Cancels the project if it is scheduled but has not distributed yet.
         * @summary Cancel/Delete a distribution project
         * @param {string} projectId Id of the project
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdDelete(projectId, test, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdDelete(projectId, test, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Revoke a particular file from the distribution list when already pushed to the Vault
         * @summary Revoke a single file from the distribution
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Uploads a new version of an existing distributing file, replacing the old version
         * @summary Upload a new version of an existing distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {boolean} [sendNotification] (Optional) Determines whether notification should be sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Update the version of a particular distributing file
         * @summary Update the version of a particular distributing file
         * @param {string} projectId Id of the project
         * @param {string} fileId Id of the file
         * @param {number} newVersion New version number to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Removes file(s) from a distribution project that  currently hasn't been run yet
         * @summary Removes file(s) from an undistributed project
         * @param {string} projectId ID of the distribution project
         * @param {Array<string>} [body] List of files to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a list of filter criteria info for clients the  distribution file is to be shared between
         * @summary Uploads filter criteria information for distribution project
         * @param {string} projectId ID of the distribution project
         * @param {DistributionFilterDto} [body] Dto with filter criteria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets all the information for a distribution project
         * @summary Get Distribution Project Info
         * @param {string} projectId ID of the distribution project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdGet(projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdGet(projectId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Updates the name of a project
         * @summary Updates the name of a project
         * @param {string} projectId Id of the project
         * @param {string} [newName] New name of the project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload details required for a draft distribution project
         * @summary Uploads details for drafted distribution project
         * @param {string} projectId Id of project to save details against
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload the remaining details required and schedules the  file distribution project
         * @summary Uploads details and schedules for distribution project
         * @param {string} projectId Id for project
         * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
         * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Revokes all distributed files from a project from a particular user's vault
         * @summary Revoke all distributed files from a project from a particular user's vault
         * @param {string} projectId Id of the project the file was distributed from
         * @param {string} userId Id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Remove a user from a distribution project through their email
         * @param {string} projectId Id of the project
         * @param {Array<string>} [body] Array of users emails to be removed from the project (Taken from the body)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Update the visibility of a particular distributing project
         * @summary Update the visibility of a particular distributing project
         * @param {string} projectId Id of the project
         * @param {string} newVisibility New visibility to update to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get information about number of projects sent and number of documents opened per date within the date range
         * @param {Date} [startDate] date for lower range limit(inclusive), if null it will default to the date of the first completed project
         * @param {Date} [endDate] date for upper range limit (inclusive)
         * @param {PlatformContext} [context] context in which files were opened. -1 &#x3D; All , 0 &#x3D; Browser , 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {TimePeriod} [timePeriod] period range of returned data. 0 &#x3D; daily , 1 &#x3D; weekly , 2 &#x3D; Monthly , 3 &#x3D; Yearly  0 &#x3D; Daily  1 &#x3D; Weekly  2 &#x3D; Monthly  3 &#x3D; Yearly
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get the list of projects sent within that date range, total amount of documents opened, percentage of opened documents and amount of documents downloaded
         * @param {Date} [startDate] Start date for filtering projects sent list (inclusive)
         * @param {Date} [endDate] End date for filtering projects sent list (inclusive)
         * @param {PlatformContext} [context] Context filter. Possible values are: -1 &#x3D; Any/All, 0 &#x3D; Browser, 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a file or multiple files for distribution
         * @summary Upload file(s) for distribution
         * @param {string} [projectName]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulkDistributionUploadPost(projectName, projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.BulkDistributionApiFp)(configuration).apiBulkDistributionUploadPost(projectName, projectId, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.BulkDistributionApiFactory = BulkDistributionApiFactory;
/**
 * BulkDistributionApi - object-oriented interface
 * @export
 * @class BulkDistributionApi
 * @extends {BaseAPI}
 */
class BulkDistributionApi extends base_1.BaseAPI {
    /**
     * Retrieves data from the cache if available, otherwise fetches it from the database and caches it.
     * @summary Get All Product Types by Platform
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProducttypesGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProducttypesGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets all the information for all available distribution project
     * @summary Get All Distribution Project Infos
     * @param {number} [refresh]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectAllGet(refresh, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectAllGet(refresh, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Returns data for the Analytics Campaign Report
     * @summary Generate Campaign Report
     * @param {string} projectId Guid for project
     * @param {Date} [startDate] Start date
     * @param {Date} [endDate] End date
     * @param {string} [fileId] (Optional) Filter by file Id (defaults to returning data for entire project)
     * @param {number} [version] (Optional) Filter by file version (defaults to all)
     * @param {number} [device] (Optional) Filter by device views/downloads (defaults to All; -1 &#x3D; All, 0 &#x3D; Browser, 1 &#x3D; WebApp)
     * @param {number} [userType] (Optional) Filter by user type (defaults to Both; 0 &#x3D; Both, 1 &#x3D; Clients, 2 &#x3D; Advisers)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdCampaignreportGet(projectId, startDate, endDate, fileId, version, device, userType, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the client list and relevant information for a particular project
     * @summary Get Client List by Project
     * @param {string} projectId Id of the Project
     * @param {string} [fileId] (Optional) Id of the file.
     * @param {number} [version] (Optional) Version of the distributed documents.
     * @param {number} [status] (Optional) Viewed status of the distributed documents.
     * @param {number} [start] (Optional) Start index of list.
     * @param {number} [count] (Optional) Number of clients to return.
     * @param {number} [sortBy] (Optional) How the list of clients is sorted. 0 &#x3D; By Name, 1 &#x3D; By Email, 2 &#x3D; By Opened Date.
     * @param {boolean} [desc] (Optional) Whether the list is sorted in descending order.
     * @param {string} [search] (Optional) Search string to filter the list of clients.
     * @param {number} [revokedStatus] (Optional) Filter on whether the client has been revoked or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdClientlistGet(projectId, fileId, version, status, start, count, sortBy, desc, search, revokedStatus, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Gets the list of clients for the distribution project
     * @param {string} projectId Id of the project
     * @param {number} [start] start index of results for pagination purposes
     * @param {number} [count] amount of results
     * @param {boolean} [sortDesc] sort by descending or ascending
     * @param {string} [search] search terms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdClientsGet(projectId, start, count, sortDesc, search, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Removes criteria from a distribution project that  currently hasn't been run yet. This will necessarily  bring the proiect back to the criteria upload stage
     * @summary Removes criteria from an undistributed project
     * @param {string} projectId ID of the distribution project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdCriteriaRemovePut(projectId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a CSV file containing a list of clients the  distribution file is to be shared between
     * @summary Uploads CSV criteria file for distribution project
     * @param {string} projectId ID of the distribution project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdCsvuploadPut(projectId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Deletes the distribution project and related files if it is in draft.  Cancels the project if it is scheduled but has not distributed yet.
     * @summary Cancel/Delete a distribution project
     * @param {string} projectId Id of the project
     * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdDelete(projectId, test, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdDelete(projectId, test, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Revoke a particular file from the distribution list when already pushed to the Vault
     * @summary Revoke a single file from the distribution
     * @param {string} projectId Id of the project
     * @param {string} fileId Id of the file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdFileFileIdRevokePut(projectId, fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Uploads a new version of an existing distributing file, replacing the old version
     * @summary Upload a new version of an existing distributing file
     * @param {string} projectId Id of the project
     * @param {string} fileId Id of the file
     * @param {boolean} [sendNotification] (Optional) Determines whether notification should be sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(projectId, fileId, sendNotification, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Update the version of a particular distributing file
     * @summary Update the version of a particular distributing file
     * @param {string} projectId Id of the project
     * @param {string} fileId Id of the file
     * @param {number} newVersion New version number to update to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(projectId, fileId, newVersion, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Removes file(s) from a distribution project that  currently hasn't been run yet
     * @summary Removes file(s) from an undistributed project
     * @param {string} projectId ID of the distribution project
     * @param {Array<string>} [body] List of files to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdFileRemovePut(projectId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a list of filter criteria info for clients the  distribution file is to be shared between
     * @summary Uploads filter criteria information for distribution project
     * @param {string} projectId ID of the distribution project
     * @param {DistributionFilterDto} [body] Dto with filter criteria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdFiltercriteriaPut(projectId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets all the information for a distribution project
     * @summary Get Distribution Project Info
     * @param {string} projectId ID of the distribution project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdGet(projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Updates the name of a project
     * @summary Updates the name of a project
     * @param {string} projectId Id of the project
     * @param {string} [newName] New name of the project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdNamePatch(projectId, newName, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload details required for a draft distribution project
     * @summary Uploads details for drafted distribution project
     * @param {string} projectId Id of project to save details against
     * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(projectId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload the remaining details required and schedules the  file distribution project
     * @summary Uploads details and schedules for distribution project
     * @param {string} projectId Id for project
     * @param {StoreDistributionDetailsDto} [body] DTO containing the remaining info
     * @param {boolean} [test] (Optional) Flag used for integration tests. Do not use
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdSchedulePut(projectId, body, test, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Revokes all distributed files from a project from a particular user's vault
     * @summary Revoke all distributed files from a project from a particular user's vault
     * @param {string} projectId Id of the project the file was distributed from
     * @param {string} userId Id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(projectId, userId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Remove a user from a distribution project through their email
     * @param {string} projectId Id of the project
     * @param {Array<string>} [body] Array of users emails to be removed from the project (Taken from the body)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdUsersPatch(projectId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Update the visibility of a particular distributing project
     * @summary Update the visibility of a particular distributing project
     * @param {string} projectId Id of the project
     * @param {string} newVisibility New visibility to update to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(projectId, newVisibility, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get information about number of projects sent and number of documents opened per date within the date range
     * @param {Date} [startDate] date for lower range limit(inclusive), if null it will default to the date of the first completed project
     * @param {Date} [endDate] date for upper range limit (inclusive)
     * @param {PlatformContext} [context] context in which files were opened. -1 &#x3D; All , 0 &#x3D; Browser , 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
     * @param {TimePeriod} [timePeriod] period range of returned data. 0 &#x3D; daily , 1 &#x3D; weekly , 2 &#x3D; Monthly , 3 &#x3D; Yearly  0 &#x3D; Daily  1 &#x3D; Weekly  2 &#x3D; Monthly  3 &#x3D; Yearly
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectsAnalyticsDatesGet(startDate, endDate, context, timePeriod, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get the list of projects sent within that date range, total amount of documents opened, percentage of opened documents and amount of documents downloaded
     * @param {Date} [startDate] Start date for filtering projects sent list (inclusive)
     * @param {Date} [endDate] End date for filtering projects sent list (inclusive)
     * @param {PlatformContext} [context] Context filter. Possible values are: -1 &#x3D; Any/All, 0 &#x3D; Browser, 1 &#x3D; MobileApp  0 &#x3D; Browser  1 &#x3D; MobileApp  -1 &#x3D; All
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionProjectsAnalyticsGet(startDate, endDate, context, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a file or multiple files for distribution
     * @summary Upload file(s) for distribution
     * @param {string} [projectName]
     * @param {string} [projectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkDistributionApi
     */
    apiBulkDistributionUploadPost(projectName, projectId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.BulkDistributionApiFp)(this.configuration).apiBulkDistributionUploadPost(projectName, projectId, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.BulkDistributionApi = BulkDistributionApi;
