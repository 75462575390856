import { Modal, ModalContent, ModalHeader } from "../../modules/Modal"

export const SessionUnavailableModal = () => {
  return (
    <Modal name={"Session unavailable"} hasCloseButton={false}>
      <ModalHeader>Session unavailable</ModalHeader>
      <ModalContent>
        Documents can not be accessed. Please close this tab and log back in to
        the platform
      </ModalContent>
    </Modal>
  )
}
