import { FC } from "react"
import "./ThumbnailBanner.css"
import { LabelType } from "../Thumbnail"

export const ThumbnailBanner: FC<{ label: LabelType }> = ({ label }) => {
  return (
    <div className="banner-label" aria-label={label}>
      <span>{label}</span>
    </div>
  )
}
