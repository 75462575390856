const modulesAdviser: string[] = [
  "AuditLog",
  "Upload",
  "DocumentsLibraryAdviserView",
  "Search",
  "Preview",
  "Thumbnail",
  "DocumentLibrary",
  "QuickAccess",
]
const modulesClient: string[] = [
  "DocumentLibrary",
  "AuditLog",
  "Upload",
  "Search",
  "Preview",
  "Thumbnail",
  "QuickAccess",
]

const modulesCorporateAdmin: string[] = [
  "AuditLog",
  "Upload",
  "Search",
  "Preview",
  "Thumbnail",
  "ClientSearchInDocHub",
  "DocumentHub",
  "DocumentsLibraryAdviserView",
  "QuickAccess",
]

export const demoUsers = {
  IanlimFnz: "ian.lim@fnz.co.nz",
  AdrianFungFnz: "adrian.fung1@fnz.co.nz",
  JensenOneX: "jensen@onex.com",
}

export const demoUsersMap: Map<string, { modules: string[] }> = new Map([
  [demoUsers.IanlimFnz, { modules: modulesAdviser }],
  [demoUsers.AdrianFungFnz, { modules: modulesClient }],
  [demoUsers.JensenOneX, { modules: modulesCorporateAdmin }],
])
