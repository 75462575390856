/* istanbul ignore file */
import { HelmetProvider } from "react-helmet-async"
import { ProvidersWrapper } from "./contexts/main/ProviderWrap"
import { GlobalStyle } from "./styled-components/GlobalStyle"
import { Routing } from "./routes/Routing"

import "./css/legado-no-theming.css"
import "./css/behavior.css"

const App = () => {
  return (
    <HelmetProvider>
      <ProvidersWrapper>
        <GlobalStyle>
          <Routing />
        </GlobalStyle>
      </ProvidersWrapper>
    </HelmetProvider>
  )
}

export default App
