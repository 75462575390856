import { FC, useEffect, useState } from "react"
import classNames from "classnames"

import { IContactInvite } from "../../api/api-client/api-types"
import { useApplicationContext } from "../../contexts/application/context"
import { useToastContext } from "../../contexts/toasts"
import { TRadioOptions } from "../../utils/shares/helpers"
import { SectionPermissions } from "../SharingPermissions/ReviewChangesContact"
import { Button } from "../atoms/Button/Button"
import CheckBox from "../atoms/CheckBox/CheckBox"
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modules/Modal"
import { CreateContactSteps } from "./CreateContact"
import { addClientApiAndApp, addContactApiAndApp } from "./helpers"

const groupSharingOptions = (sharingOptions: TRadioOptions[]) => {
  const groups = {} as { [key: string]: TRadioOptions[] }
  sharingOptions.forEach((sharingOption) => {
    groups[sharingOption.group] = groups[sharingOption.group] || []
    groups[sharingOption.group].push(sharingOption)
  })
  return groups
}

export interface SummaryProps {
  addingSharee?: boolean
  contact: IContactInvite
  sharingOptions: TRadioOptions[]
  setStep: (step: CreateContactSteps) => void
  onClose?: () => void
  isClient?: boolean
}

export const Summary: FC<SummaryProps> = ({
  addingSharee,
  contact,
  sharingOptions,
  setStep,
  onClose,
  isClient = false,
}) => {
  const {
    dispatch,
    applicationState: { selectedSharingWithMeUserId, clients, selectedTheme },
  } = useApplicationContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [addContactNow, setAddContactNow] = useState(false)
  const [sendEmailCopy, setSendEmailCopy] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const addClientOrContact = async () => {
      if (addContactNow) {
        setAddContactNow(false)
        contact.sendCopy = sendEmailCopy
        if (isClient) {
          await addClientApiAndApp({
            dispatch,
            toastDispatch,
            client: contact,
            onComplete: () => setStep(CreateContactSteps.INVITE_SENT),
            onClose,
            clients,
          })
        } else {
          await addContactApiAndApp({
            dispatch,
            toastDispatch,
            contact,
            sharingOptions,
            onComplete: () => setStep(CreateContactSteps.INVITE_SENT),
            onClose,
            selectedSharingWithMeUserId,
          })
        }
      }
    }
    addClientOrContact()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addContactNow])

  const sharingOptionsGroups = groupSharingOptions(sharingOptions)
  const classNameContactNote = classNames("contact-note", {
    "contact-note__border-bottom--dashed": selectedTheme !== "CO_OP",
  })

  return (
    <>
      <ModalHeader
        className={`has-subheader summary ${
          isClient ? "create-client-modal" : ""
        }`}
      >
        {" "}
        Add new contact
      </ModalHeader>
      <ModalSubHeader className="header__border-bottom--base">{`${
        addingSharee || sharingOptions.length === 0 ? "3/3" : "4/4"
      }:${isClient ? " Invite client." : " Review Summary"}`}</ModalSubHeader>
      <ModalContent className="create-contact-modal">
        <p className="create-contact-text">
          Please make sure all details are correct before proceeding.{" "}
          {!isClient &&
            "This can be changed at any point from a contact details page."}
        </p>

        <div className="flex contact-name modal-standard-p-top-bottom no-margin">
          <span className="no-margin">
            {contact.firstName} {contact.surname}
            <p className="contact-email">
              {contact.email}
              {contact.phoneNumber
                ? ` / ${contact.countryCode ?? ""} ${contact.phoneNumber}`
                : ""}
            </p>
          </span>
          <Button
            className="flex-column"
            variant="tertiary"
            onClick={() => setStep(CreateContactSteps.MAIN)}
          >
            Edit
          </Button>
        </div>
        {sharingOptions.length === 0 ? (
          ""
        ) : (
          <>
            <div className="create-contact-text modal-standard-p-top-bottom flex justify-content-between align-items-center">
              Edit individual folder permissions
              <Button
                className="ml-1"
                variant="tertiary"
                onClick={() => setStep(CreateContactSteps.PERMISSIONS)}
              >
                Edit
              </Button>
            </div>
            <div>
              {Object.keys(sharingOptionsGroups).map((groupName) => (
                <SectionPermissions
                  key={groupName}
                  sectionName={groupName}
                  permissions={sharingOptionsGroups[groupName]}
                />
              ))}
            </div>
          </>
        )}
        <div className="create-contact-text personal-note-button modal-standard-p-top-bottom flex justify-content-between align-items-center">
          Personal note:
          <Button
            variant="tertiary"
            onClick={() => setStep(CreateContactSteps.PERSONAL_NOTE)}
          >
            {contact.message === "" ? "Add" : "Edit note"}
          </Button>
        </div>
        <div className={classNameContactNote}>{contact.message}</div>
        {selectedTheme !== "CO_OP" && (
          <div className="flex align-items-center invite-checkbox modal-standard-m-top-bottom">
            <CheckBox
              id={"copyEmailCheckbox"}
              onChange={() =>
                setSendEmailCopy((sendEmailCopy) => !sendEmailCopy)
              }
              isChecked={sendEmailCopy}
              shouldEnableFocusOutline
            />
            <label
              htmlFor={"copyEmailCheckbox"}
              className="pl-2 typography-light"
            >
              Want a copy of the email invitation?
            </label>
          </div>
        )}
      </ModalContent>
      <ModalFooter className="create-contact-footer modal-footer flex">
        <Button
          className="mr-4"
          variant="tertiary"
          type="reset"
          onClick={() =>
            setStep(
              addingSharee
                ? CreateContactSteps.PERMISSIONS
                : CreateContactSteps.PERSONAL_NOTE
            )
          }
        >
          Back
        </Button>
        <Button
          variant="primary"
          type="submit"
          isDisabled={submitted}
          onClick={() => {
            if (!addContactNow) {
              setSubmitted(true)
              setAddContactNow(true)
            }
          }}
        >
          Send Invite
        </Button>
      </ModalFooter>
    </>
  )
}
