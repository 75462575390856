import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { IFile } from "../../api/api-client/api-types"
import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  CaseDocumentActionId,
  putWorkflowCaseDocumentAction,
} from "../../api/lib/workflow/workflow"
import {
  ActionType,
  UserModuleType,
} from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { useDownloadFile } from "../../hooks/useDownloadFile"
import { CoopCompanyName } from "../../utils/consts/consts"
import { WorkflowStatuses } from "../../utils/enums/WorkflowStatuses"
import { setPinFile } from "../../utils/pinnedNodes/setPin"
import CreateContact from "../CreateContact/CreateContact"
import { DeleteCaseFile } from "../DeleteCaseFile/DeleteCaseFile"
import { DeleteNode } from "../DeleteNode"
import { FileRename } from "../FileRename"
import { RejectDocumentModal } from "../RejectDocumentModal/RejectDocumentModal"
import { SharingPermissionsNode } from "../SharingPermissions/SharingPermissionsNode"
import { BUTTON_SIZE, BUTTON_VARIANT, Button } from "../atoms/Button"
import { LoadingSpinner } from "../atoms/LoadingSpinner/LoadingSpinner"

import "./FileDetails.css"

const FileSharingButtonAndModal: FC<{
  file: IFile
  caseDocument?: CaseDocument
}> = ({ file, caseDocument }) => {
  const [isOpen, setModalOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)

  return (
    <>
      <Button
        className="mr-3 mb-3 file-details"
        variant={BUTTON_VARIANT.SECONDARY}
        iconPosition="left"
        icon="ThreeUsers"
        onClick={() => setModalOpen(true)}
      >
        Sharing
      </Button>
      {!isCreateContactOpen && isOpen && (
        <SharingPermissionsNode
          file={file}
          onClose={() => setModalOpen(false)}
          setIsCreateContactOpen={() => {
            setModalOpen(false)
            setIsCreateContactOpen(true)
          }}
          // case documents should not have the option for edit in sharing permissions
          shouldHideEditOption={caseDocument !== undefined}
        />
      )}
      {!isOpen && isCreateContactOpen && (
        <CreateContact
          closeCreateContact={() => setIsCreateContactOpen(false)}
          shouldShowErrorsOnSubmitOnly={!!caseDocument}
          isAddingContactThroughWorkflowDocument={!!caseDocument}
          documentFilesToShare={caseDocument && [file]}
        />
      )}
    </>
  )
}

export type FileDetailsActionsProps = {
  file: IFile
  caseDocument?: CaseDocument
  setCaseDocument?: Dispatch<SetStateAction<CaseDocument | undefined>>
}
export const FileDetailsActions: FC<FileDetailsActionsProps> = ({
  file,
  caseDocument,
  setCaseDocument,
}) => {
  const {
    applicationState: { pinnedFoldersAndFiles, folders, sharedWithMeFolders },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [showRejectModal, setShowRejectModal] = useState(false)
  const [isAcknowledging, setIsAcknowledging] = useState(false)

  const { workflowId, workflowVersion, caseId } = useParams()
  const isSharedFile = file.ownerId !== currentUser?.userId
  const isDocumentAndShareable = caseDocument && !caseDocument.isComplete
  const canEdit = isSharedFile
    ? currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_EDIT)
    : currentUser?.modules?.includes(UserModuleType.PERSONALISE_VAULT)

  const isUsingWorkflows = currentUser?.modules?.includes(
    UserModuleType.CASE_TRACKER
  )
  const actionType = caseDocument?.actionType
  const shouldShowDeleteButton =
    file.isDeletable &&
    !file.hasCommenting &&
    (canEdit ||
      (isUsingWorkflows &&
        actionType === ActionType.Upload &&
        caseDocument?.displayStatus?.toLocaleLowerCase() ===
          ActionType.Uploaded &&
        caseDocument?.createdById === currentUser?.userId))

  const isDocusignFile = file?.isDocusignFile
  const hasVaultDocumentUploadForSigning = currentUser?.modules?.includes(
    UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING
  )

  const showRejectButton =
    isUsingWorkflows &&
    !hasVaultDocumentUploadForSigning &&
    !caseDocument?.isComplete &&
    caseDocument?.currentStatus === WorkflowStatuses.RequiredForAction &&
    (actionType === ActionType.CompleteAndUpload ||
      actionType === ActionType.ReviewAndSign ||
      actionType === ActionType.ReviewAndApprove ||
      actionType === ActionType.ReviewUploadAndSign ||
      actionType === ActionType.ReviewDownloadSignAndPost ||
      actionType === ActionType.CompleteAndSign)

  const showAcknowledgeButton =
    isUsingWorkflows &&
    !hasVaultDocumentUploadForSigning &&
    !caseDocument?.isComplete &&
    caseDocument?.currentStatus === WorkflowStatuses.RequiredForAction &&
    actionType === ActionType.ReviewAndApprove

  const [renameIsOpen, setRenameOpen] = useState(false)
  const [deleteNodeOpen, setDeleteNodeOpen] = useState(false)
  const [isDownloadFile, setIsDownloading] = useState(false)
  const [changePinned, setChangePinned] = useState(false)
  const isPinned = !!pinnedFoldersAndFiles?.contents?.find(
    (x) => x.id === file.id
  )

  useDownloadFile({ file, isDownloadFile, setIsDownloading })

  useEffect(() => {
    const onPin = async () => {
      if (changePinned) {
        const newIsPinned = !isPinned
        setChangePinned(false)
        setPinFile(
          dispatch,
          toastDispatch,
          newIsPinned,
          file,
          file.isOwner ? folders! : sharedWithMeFolders!,
          currentUser
        )
      }
    }
    onPin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePinned])

  const onAcknowledge = async () => {
    try {
      setIsAcknowledging(true)
      if (
        workflowId &&
        workflowVersion &&
        caseId &&
        caseDocument?.stageId &&
        caseDocument?.requestId &&
        caseDocument?.id &&
        setCaseDocument
      ) {
        const newCase = await putWorkflowCaseDocumentAction({
          workflowId,
          workflowVersion,
          caseId,
          stageId: caseDocument.stageId.toString(),
          requestId: caseDocument.requestId.toString(),
          documentId: caseDocument.id.toString(),
          actionId: CaseDocumentActionId.Acknowledge,
          userId: currentUser?.userId,
        })
        setCaseDocument(newCase)
        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            title: "Acknowledged",
            messageType: "SUCCESS",
            message: `${CoopCompanyName} will be notified.`,
          })
        )
      }
    } catch (error) {
      console.error("error:", error)
    } finally {
      setIsAcknowledging(false)
    }
  }

  return (
    <>
      <div>
        {renameIsOpen && (
          <FileRename
            onClose={() => {
              setRenameOpen(false)
            }}
            file={file}
            isShared={isSharedFile}
            caseDocument={caseDocument}
            setCaseDocument={setCaseDocument}
          />
        )}
        {deleteNodeOpen &&
          (isUsingWorkflows ? (
            <DeleteCaseFile
              onClose={() => setDeleteNodeOpen(false)}
              file={file}
            />
          ) : (
            <DeleteNode
              onClose={() => {
                setDeleteNodeOpen(false)
              }}
              file={file}
              isShared={isSharedFile}
            />
          ))}

        <div
          data-testid="file-details-action-buttons-id"
          className="file-details-buttons"
          id="file-details-action-buttons-id"
        >
          {file.isEditable && canEdit && (
            <Button
              className="file-details mb-3"
              iconPosition="left"
              icon="Pencil"
              onClick={() => setRenameOpen(true)}
              variant={BUTTON_VARIANT.SECONDARY}
              size={BUTTON_SIZE.SMALL}
            >
              Rename
            </Button>
          )}

          <Button
            className="mr-3 mb-3 file-details"
            variant={BUTTON_VARIANT.SECONDARY}
            iconPosition="left"
            icon="FnzDownload"
            onClick={() => setIsDownloading(true)}
          >
            Download
          </Button>
          {hasVaultDocumentUploadForSigning &&
            isDocusignFile &&
            currentUser?.roles?.includes("Adviser") && (
              <>
                <Button
                  className="mr-3 mb-3 file-details"
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={() => {}}
                >
                  Send reminder
                </Button>
                <Button
                  className="mb-3 mr-3 file-details"
                  variant={BUTTON_VARIANT.DANGER_REMOVE}
                  onClick={() => {}}
                >
                  Cancel
                </Button>
              </>
            )}
          {currentUser?.modules?.includes(UserModuleType.SHARING) &&
            (!isSharedFile || isDocumentAndShareable) && (
              <FileSharingButtonAndModal
                file={file}
                caseDocument={caseDocument}
              />
            )}
          {canEdit && currentUser?.modules?.includes(UserModuleType.VAULT) && (
            <Button
              className="mr-3 mb-3 file-details"
              variant={BUTTON_VARIANT.SECONDARY}
              iconPosition="left"
              icon={isPinned ? "PinRemove" : "Pin"}
              onClick={() => {
                setChangePinned(true)
              }}
            >
              {isPinned ? "Unpin" : "Pin"}
            </Button>
          )}
          {shouldShowDeleteButton && (
            <Button
              className="mb-3 mr-3 file-details"
              variant={BUTTON_VARIANT.DANGER_REMOVE}
              iconPosition="left"
              icon="FnzBin"
              onClick={() => setDeleteNodeOpen(true)}
            >
              Delete
            </Button>
          )}
          {showAcknowledgeButton && (
            /* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */
            <Button
              className="mb-3 mr-3 file-details"
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={onAcknowledge}
              title={isAcknowledging ? "Acknowledging..." : "Acknowledge"}
            >
              {!isAcknowledging && "Acknowledge"}
              {isAcknowledging && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    className="mr-1"
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "112px",
                    }}
                  >
                    Acknowledging
                  </span>
                  <LoadingSpinner
                    size="20px"
                    thickness="2px"
                    color="var(--color-universal-secondary-e)"
                  />
                </div>
              )}
            </Button>
          )}
          {showRejectButton && (
            <>
              {showRejectModal && (
                <RejectDocumentModal
                  setShowRejectModal={setShowRejectModal}
                  setCaseDocument={setCaseDocument}
                  caseDocument={caseDocument}
                  workflowId={workflowId}
                  workflowVersion={workflowVersion}
                  caseId={caseId}
                  currentUser={currentUser}
                  documentName={file.name}
                />
              )}
              <Button
                className="mb-3 mr-3 file-details"
                variant={BUTTON_VARIANT.DANGER_PRIMARY}
                onClick={() => setShowRejectModal(true)}
              >
                Reject
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
