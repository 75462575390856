import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { IContactInvite } from "../../api/api-client/api-types"
import {
  TBackendContactRelationship,
  UserModuleType,
} from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useUserContext } from "../../contexts/users"
import { isNullEmptyOrUndefined } from "../../utils/assertions/typeAssertions"
import { InputFieldType, validateInput } from "../../utils/forms/validateInputs"
import { Button } from "../atoms/Button"
import { Select } from "../atoms/Select"
import { TextInputWithValidation } from "../atoms/TextInput/TextInputWithValidation"
import AlertMessage, { MessageType } from "../modules/AlertMessage/AlertMessage"
import { AlertMessageItem } from "../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modules/Modal"
import { PhoneNumberInputWithCountryCodeDropDown } from "../modules/PhoneNumberInputWithCountryCodeDropDown/PhoneNumberInputWithCountryCodeDropDown"

type TContactRelationshipDropdownOtption = {
  label: string
  value: TBackendContactRelationship
}

const dropDownOptions: TContactRelationshipDropdownOtption[] = [
  {
    value: "Accountant",
    label: "Accountant",
  },
  {
    value: "Professional",
    label: "Professional",
  },
  {
    value: "FamilyMember",
    label: "Family member",
  },
  {
    value: "Solicitor",
    label: "Solicitor",
  },
  {
    value: "SpousePartner",
    label: "Spouse or partner",
  },
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Other",
    label: "Other",
  },
]
const contactFormFieldsIds = {
  firstName: {
    id: 1,
  },
  lastName: {
    id: 2,
  },
  phoneNumber: {
    id: 3,
  },
  email: {
    id: 4,
  },
  relationship: {
    id: 5,
  },
}
const isMainContactDataComplete = ({
  contact,
  currentUserEmail,
  alreadyAddedContactsEmails,
  isPhoneNumberRequired = false,
  shouldShowErrorsOnSubmitOnly = false,
}: {
  contact: IContactInvite
  currentUserEmail?: string
  alreadyAddedContactsEmails?: string[]
  isPhoneNumberRequired?: boolean
  shouldShowErrorsOnSubmitOnly?: boolean
}) => {
  const errors: { id: string; message: string }[] = []

  if (
    !isNullEmptyOrUndefined(
      validateInput({ value: contact.firstName!, type: InputFieldType.NAME })
    )
  ) {
    errors.push({
      id: contactFormFieldsIds.firstName.id.toString(),
      message: "Enter a valid first name",
    })
  }
  if (
    !isNullEmptyOrUndefined(
      validateInput({ value: contact.surname!, type: InputFieldType.NAME })
    )
  ) {
    errors.push({
      id: contactFormFieldsIds.lastName.id.toString(),
      message: "Enter a valid last name",
    })
  }
  if (
    !isNullEmptyOrUndefined(
      validateInput({
        value: contact.email!,
        type: InputFieldType.EMAIL,
        currentUserEmail: currentUserEmail,
        alreadyAddedContactsEmails: alreadyAddedContactsEmails,
      })
    )
  ) {
    errors.push({
      id: contactFormFieldsIds.email.id.toString(),
      message: "Enter a valid email",
    })
  }
  if (!contact.email.includes("@")) {
    errors.push({
      id: contactFormFieldsIds.email.id.toString(),
      message: "Enter an email that includes an '@'",
    })
  }
  if (
    isNullEmptyOrUndefined(contact.relationship) ||
    contact.relationship === "None"
  ) {
    errors.push({
      id: contactFormFieldsIds.relationship.id.toString(),
      message: "Choose a relationship",
    })
  }

  if (
    (isPhoneNumberRequired && isNullEmptyOrUndefined(contact.phoneNumber)) ||
    (shouldShowErrorsOnSubmitOnly &&
      validateInput({
        value: contact.countryCode! + contact.phoneNumber!,
        type: InputFieldType.PHONE_NUMBER_VALID_FORMAT,
      }))
  ) {
    errors.push({
      id: contactFormFieldsIds.phoneNumber.id.toString(),
      message: "Enter a valid phone number",
    })
  }

  return errors
}

const getErrorOnSubmitForInput = ({
  targetInput,
  errors,
}: {
  targetInput:
    | "firstName"
    | "surname"
    | "email"
    | "phoneNumber"
    | "relationship"
  errors: AlertMessageItem[]
}) => {
  let foundError = {
    hasError: false,
    message: "",
  }
  let error: AlertMessageItem | undefined
  // get the error by the error message
  switch (targetInput) {
    case "firstName": {
      error = errors.find(
        (error) => error.message === "Enter a valid first name"
      )
      break
    }
    case "surname": {
      error = errors.find(
        (error) => error.message === "Enter a valid last name"
      )
      break
    }
    case "email": {
      error =
        errors.find((error) => error.message === "Enter a valid email") ||
        errors.find(
          (error) => error.message === "Enter an email that includes an '@'"
        )
      break
    }
    case "phoneNumber": {
      error = errors.find(
        (error) => error.message === "Enter a valid phone number"
      )
      break
    }
    case "relationship": {
      error = errors.find((error) => error.message === "Choose a relationship")
      break
    }
  }
  if (error) {
    foundError = {
      hasError: true,
      message: error.message,
    }
  }
  return foundError
}

export interface CreateContactMainFormProps {
  addingSharee?: boolean
  onClose: () => void
  onContinueButtonClick: () => void
  contact: IContactInvite
  setContact?: Dispatch<SetStateAction<IContactInvite>>
  sharingOptions?: any
  testSharingOptions?: boolean
  errorPosition?: "below" | "above"
  shouldShowErrorsOnSubmitOnly?: boolean
}

export const CreateContactMainForm: FC<CreateContactMainFormProps> = ({
  addingSharee,
  onClose,
  onContinueButtonClick,
  contact,
  setContact,
  sharingOptions,
  testSharingOptions,
  errorPosition = "below",
  shouldShowErrorsOnSubmitOnly = false,
}) => {
  const {
    applicationState: { contacts, selectedTheme },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const [isValid, setIsValid] = useState(false)
  const [errors, setErrors] = useState<AlertMessageItem[]>([])
  const contactsEmails = contacts.map((contact) => {
    return contact.email ?? ""
  })
  const showHelper = selectedTheme !== "ONE_X"
  const isPhoneNumberRequired = currentUser?.modules?.includes(
    UserModuleType.PHONE_NUMBER_REQUIRED_FOR_CONTACT_INVITE
  )

  const onContinueButtonClickFn = () => {
    const validationErrors = isMainContactDataComplete({
      contact,
      currentUserEmail: currentUser?.email,
      alreadyAddedContactsEmails: contactsEmails,
      isPhoneNumberRequired,
      shouldShowErrorsOnSubmitOnly,
    })
    setIsValid(validationErrors.length === 0)

    if (shouldShowErrorsOnSubmitOnly) {
      setErrors(
        validationErrors.map((error, index) => ({
          id: index,
          href: error.id,
          message: error.message,
        }))
      )
    }

    if (validationErrors.length > 0) return

    onContinueButtonClick()
  }

  const closeModal = () => {
    if (setContact) setContact(contact)
    onClose()
  }

  const firstNameInputError = getErrorOnSubmitForInput({
    targetInput: "firstName",
    errors,
  })
  const lastNameInputError = getErrorOnSubmitForInput({
    targetInput: "surname",
    errors,
  })
  const emailInputError = getErrorOnSubmitForInput({
    targetInput: "email",
    errors,
  })
  const phoneInputError = getErrorOnSubmitForInput({
    targetInput: "phoneNumber",
    errors,
  })
  const relationshipDropdownError = getErrorOnSubmitForInput({
    targetInput: "relationship",
    errors,
  })

  useEffect(() => {
    if (!shouldShowErrorsOnSubmitOnly) {
      const errors = isMainContactDataComplete({
        contact,
        currentUserEmail: currentUser?.email,
        alreadyAddedContactsEmails: contactsEmails,
        isPhoneNumberRequired,
      })
      setIsValid(errors.length === 0)
    }
  }, [
    contact,
    currentUser,
    contactsEmails,
    isPhoneNumberRequired,
    shouldShowErrorsOnSubmitOnly,
  ])

  return (
    <>
      <ModalHeader className="has-subheader">Add new contact</ModalHeader>
      {showHelper && (
        <ModalSubHeader className="header__border-bottom--base">
          {`1/${
            addingSharee || sharingOptions?.length === 0 ? "3" : "4"
          }: Contact details`}
        </ModalSubHeader>
      )}
      <ModalContent
        className="create-contact-modal"
        data-testid="create-contact-main"
      >
        {shouldShowErrorsOnSubmitOnly && errors.length > 0 && (
          <AlertMessage
            messageType={MessageType.ERROR}
            title="There's a problem"
            message="Check the form. You must:"
            alertItems={errors.map((error) => ({
              ...error,
              message: error.message.toLowerCase(),
            }))}
          />
        )}
        <div className="flex flex-wrap create-contact-main">
          <div className="main-contact-name-wrapper flex-basis-100">
            <div>
              <TextInputWithValidation
                isDisabled={addingSharee && !!contact.firstName}
                value={contact.firstName}
                errorMessagePosition={errorPosition}
                validateOnSubmit={shouldShowErrorsOnSubmitOnly}
                shouldShowWarningIcon={!shouldShowErrorsOnSubmitOnly}
                errorOnSubmit={{
                  hasError: firstNameInputError.hasError,
                  message: firstNameInputError.message ?? "",
                }}
                showThickErrorBorder={firstNameInputError.hasError}
                labelClassName="text-s required"
                name="First Name"
                label="First name"
                type={InputFieldType.NAME}
                onChange={(newValue: string) =>
                  setContact?.((prevValue: IContactInvite) => {
                    return { ...prevValue, firstName: newValue }
                  })
                }
                id={contactFormFieldsIds.firstName.id.toString()}
              />
            </div>
            <div>
              <TextInputWithValidation
                isDisabled={addingSharee && !!contact.surname}
                value={contact.surname}
                errorMessagePosition={errorPosition}
                validateOnSubmit={shouldShowErrorsOnSubmitOnly}
                shouldShowWarningIcon={!shouldShowErrorsOnSubmitOnly}
                errorOnSubmit={lastNameInputError}
                showThickErrorBorder={lastNameInputError.hasError}
                labelClassName="text-s required"
                name="Last Name"
                label="Last name"
                type={InputFieldType.NAME}
                onChange={(newValue: string) =>
                  setContact?.((prevValue: IContactInvite) => {
                    return { ...prevValue, surname: newValue }
                  })
                }
                id={contactFormFieldsIds.lastName.id.toString()}
              />
            </div>
          </div>
          <div className="flex-basis-100">
            <div>
              <TextInputWithValidation
                isDisabled={addingSharee && !!contact.email}
                value={contact.email}
                errorMessagePosition={errorPosition}
                validateOnSubmit={shouldShowErrorsOnSubmitOnly}
                shouldShowWarningIcon={!shouldShowErrorsOnSubmitOnly}
                errorOnSubmit={emailInputError}
                showThickErrorBorder={emailInputError.hasError}
                labelClassName="text-s required"
                name="Email"
                label="Email"
                type={InputFieldType.EMAIL}
                email={currentUser?.email}
                alreadyAddedContactsEmails={contactsEmails}
                onChange={(newValue: string) =>
                  setContact?.((prevValue: IContactInvite) => {
                    return { ...prevValue, email: newValue }
                  })
                }
                id={contactFormFieldsIds.email.id.toString()}
              />
            </div>
          </div>
          <div className="flex-basis-100">
            <div>
              <PhoneNumberInputWithCountryCodeDropDown
                phoneNumber={contact.phoneNumber ?? ""}
                countryCodeSelected={contact.countryCode ?? "+44"}
                setPhoneNumber={(newPhoneNumber: string) => {
                  setContact?.((prevValue: IContactInvite) => {
                    const phoneNumberWithoutZeroAtTheStart =
                      newPhoneNumber.replace(/^0+/, "")
                    return {
                      ...prevValue,
                      phoneNumber: phoneNumberWithoutZeroAtTheStart,
                    }
                  })
                }}
                setCountryCodeSelected={(newCountryCode: string) => {
                  setContact?.((prevValue: IContactInvite) => {
                    return { ...prevValue, countryCode: newCountryCode }
                  })
                }}
                isRequired={isPhoneNumberRequired}
                id={contactFormFieldsIds.phoneNumber.id.toString()}
                shouldShowErrorIcon={false}
                error={phoneInputError}
              />
            </div>
          </div>
          <Select
            label={"Relationship"}
            labelClassName="required"
            description={showHelper ? "What is their relationship to you?" : ""}
            withRadios={true}
            options={dropDownOptions}
            id={contactFormFieldsIds.relationship.id.toString()}
            setSelectedValue={(newValue: string) =>
              setContact?.((prevValue: IContactInvite) => {
                return { ...prevValue, relationship: newValue }
              })
            }
            selectedValue={contact.relationship}
            errorMessageContent={relationshipDropdownError.message}
            shouldShowError={relationshipDropdownError.hasError}
            shouldShowErrorIcon={false}
          />
        </div>
      </ModalContent>
      <ModalFooter className="create-contact-footer modal-footer">
        <Button
          className="mr-4"
          variant="tertiary"
          type="reset"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={onContinueButtonClickFn}
          isDisabled={!shouldShowErrorsOnSubmitOnly ? !isValid : false}
        >
          {sharingOptions?.length === 0 && !testSharingOptions
            ? "Add personal note"
            : "Choose permissions"}
        </Button>
      </ModalFooter>
    </>
  )
}

export default CreateContactMainForm
