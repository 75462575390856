import { useEffect } from "react"
import { ApiController } from "../api/apiController"
import { setApiConnection } from "../contexts/application/action"
import { useApplicationContext } from "../contexts/application/context"
import { ApiConnection } from "../api/api-client/common/ApiConnection"

const api = ApiController.getInstance()

export const useRefreshTokenOnTabFocus = () => {
  const { dispatch } = useApplicationContext()
  useEffect(() => {
    const tabSwitchTokenCheck = async () => {
      if (api.connected) {
        if (document.visibilityState === "visible") {
          const result = await api.doTokenRefresh()
          if (!result) {
            dispatch(setApiConnection({ failed: true } as ApiConnection))
          }
        }
      }
    }

    document.addEventListener("visibilitychange", tabSwitchTokenCheck)
    return () => {
      document.removeEventListener("visibilitychange", tabSwitchTokenCheck)
    }
  }, [dispatch])
}
