import { baseUrl, httpClient } from "../httpClient"
import { GetRegistrationDetailsResponse } from "./models/GetRegistrationDetailsResponse"
import { LoginPasswordResponse } from "./models/LoginPasswordResponse"
import { SendResetLinkResponse } from "./models/SendResetLinkResponse"
import { SetPasswordResponse } from "./models/SetPasswordResponse"
import { ResetPasswordResponse } from "./models/ResetPasswordResponse"
import { GetTokenByMSAccessTokenResponse } from "./models/GetTokenByMSAccessTokenResponse"

export const getRegistrationDetails = async (
  activationCode: string
): Promise<GetRegistrationDetailsResponse> => {
  const url = new URL(`${baseUrl}/api/auth/registration`)
  url.searchParams.set("actCode", activationCode)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const setPasswordForUser = async ({
  newPassword,
  activationCode,
  twoFactorCode,
  additionalAssistanceText,
}: {
  newPassword: string
  activationCode: string
  twoFactorCode?: string
  additionalAssistanceText?: string
}): Promise<SetPasswordResponse> => {
  const url = new URL(`${baseUrl}/api/auth/setpassword`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      activationCode,
      newPassword,
      ...(twoFactorCode && { twoFactorCode }),
      ...(additionalAssistanceText && {
        additionalAssistanceUserStatus: additionalAssistanceText,
      }),
    },
  })

  return response.data
}

export const resetPassword = async ({
  newPassword,
  activationCode,
  twoFactorCode,
}: {
  newPassword: string
  activationCode: string
  twoFactorCode?: string
}): Promise<ResetPasswordResponse> => {
  const url = new URL(`${baseUrl}/api/auth/resetpassword`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      activationCode,
      newPassword,
      ...(twoFactorCode && { twoFactorCode }),
    },
  })

  return response.data
}

export const sendResetLink = async ({
  email,
}: {
  email: string
}): Promise<SendResetLinkResponse> => {
  const url = new URL(`${baseUrl}/api/auth/sendresetlink`)
  const response = await httpClient(url.toString(), {
    method: "POST",
    data: { email },
  })

  return response.data
}

export const login = async ({
  email,
  password,
  code,
}: {
  email: string
  password: string
  code?: string
}): Promise<LoginPasswordResponse> => {
  const url = new URL(`${baseUrl}/api/auth/login`)
  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      username: email,
      password,
      ...(code && { code }),
    },
  })

  return response.data
}

export const refresh = async (): Promise<string> => {
  const url = new URL(`${baseUrl}/api/auth/refresh`)
  const response = await httpClient(url.toString(), {
    method: "POST",
  })

  return response.data
}

export const getTokenByEmail = async (email: string): Promise<string> => {
  const url = new URL(`${baseUrl}/api/auth/tokenbyemail`)
  url.searchParams.set("email", email)

  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}

export const getTokenBySso = async (
  accessToken: string
): Promise<GetTokenByMSAccessTokenResponse> => {
  const url = new URL(`${baseUrl}/api/auth/tokenbysso`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: accessToken,
  })

  return response.data
}
