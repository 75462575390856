import { useNavigate } from "react-router-dom"
import { Dispatch, FC, SetStateAction, useState } from "react"

import { Button } from "../atoms/Button/Button"
import { TextArea } from "../atoms/TextArea/TextArea"
import AlertMessage, { MessageType } from "../modules/AlertMessage/AlertMessage"
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../modules/Modal/Modal"
import { replaceParamsInPath } from "../../utils/navigation/replaceParamsInPath"
import { PAGE_PATH } from "../../contexts/application/constants"
import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import { IUser } from "../../api/api-client/api-types"
import { BUTTON_VARIANT } from "../atoms/Button/constants"
import {
  CaseDocumentActionId,
  putWorkflowCaseDocumentAction,
} from "../../api/lib/workflow/workflow"
import { CoopCompanyName } from "../../utils/consts/consts"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"

import "./RejectDocumentModal.scss"
import { LoadingSpinner } from "../atoms/LoadingSpinner/LoadingSpinner"

export interface RejectDocumentModalProps {
  setShowRejectModal: (value: boolean) => void
  setCaseDocument?: Dispatch<SetStateAction<CaseDocument | undefined>>
  OnSuccess?: () => void
  caseDocument?: CaseDocument
  workflowId?: string
  workflowVersion?: string
  caseId?: string
  stageId?: string
  requestId?: string
  documentId?: string
  currentUser?: IUser
  documentName?: string
}

export const RejectDocumentModal: FC<RejectDocumentModalProps> = ({
  setShowRejectModal,
  setCaseDocument,
  OnSuccess,
  caseDocument,
  workflowId,
  workflowVersion,
  caseId,
  stageId,
  requestId,
  documentId,
  currentUser,
  documentName,
}) => {
  const { dispatch: toastDispatch } = useToastContext()
  const [rejectInput, setRejectInput] = useState("")
  const [hasRejectError, setHasRejectError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const rejectFunction = async () => {
    try {
      if (!isSubmitting) {
        setIsSubmitting(true)
        const apiStageId = stageId ?? caseDocument?.stageId.toString()
        const apiRequestId = requestId ?? caseDocument?.requestId.toString()
        const apiDocumentId = documentId ?? caseDocument?.id.toString()
        if (
          workflowId &&
          workflowVersion &&
          caseId &&
          apiStageId &&
          apiRequestId &&
          apiDocumentId
        ) {
          if (rejectInput.length < 3) {
            setHasRejectError(true)
          } else {
            setHasRejectError(false)
            try {
              const newDocument = await putWorkflowCaseDocumentAction({
                workflowId,
                workflowVersion,
                caseId,
                stageId: apiStageId,
                requestId: apiRequestId,
                documentId: apiDocumentId,
                actionId: CaseDocumentActionId.Reject,
                userId: currentUser?.userId,
                comment: rejectInput,
              })
              if (setCaseDocument && !!newDocument) {
                setCaseDocument(newDocument)
                navigate(
                  replaceParamsInPath({
                    path: PAGE_PATH.CaseDetailsPage,
                    pathParameters: {
                      workflowId,
                      workflowVersion,
                      caseId,
                    },
                  })
                )
                toastDispatch(
                  setDisplaySuccessOrErrorMessage({
                    title: "Document rejected",
                    message: `Document${
                      documentName && documentName.length > 0
                        ? ` '${documentName}'`
                        : ""
                    } has been rejected.`,
                    messageType: "INFO",
                  })
                )
              }
              if (OnSuccess) {
                OnSuccess()
              }
            } catch (error) {
              console.error("error:", error)
            } finally {
              setIsSubmitting(false)
              setShowRejectModal(false)
            }
          }
        }
      }
    } catch (error) {
      console.error("error:", error)
    }
  }

  return (
    <Modal name="Reject Document" onClose={() => setShowRejectModal(false)}>
      <ModalHeader>Reject document</ModalHeader>
      <ModalContent>
        {hasRejectError && (
          <AlertMessage
            className="reject-document-modal-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message="Check the form. You must:"
            alertItems={[
              {
                id: 1,
                href: "document-rejection",
                message:
                  "make sure a description is added for rejecting this document",
              },
            ]}
          />
        )}
        <div className="reject-document-modal-content">
          {`Want to reject this document? This will remove it from document
          exchange and alert ${CoopCompanyName}.`}
        </div>
        <TextArea
          id="document-rejection"
          name="Document Rejection"
          label="Why are you rejecting this document?"
          value={rejectInput}
          onChange={(value) => setRejectInput(value)}
          isRequired={true}
          hasError={hasRejectError}
          errorLabel="Required"
        />
      </ModalContent>
      <ModalFooter>
        <Button
          variant={BUTTON_VARIANT.DANGER_PRIMARY}
          onClick={rejectFunction}
        >
          Reject
          {isSubmitting && (
            <LoadingSpinner
              thickness="2px"
              color="var(--color-universal-secondary-e)"
            />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
