import dayjs from "dayjs"
import {
  DistributionCampaignReportDto,
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectInfoDto,
} from "legado-generated-api-client"
import { FilterType, TFilterOption } from "../../../modules/Filter/Filter"
import { CampaignReportFilterValues } from "./Analytics/Analytics"

export const dummyDistributionCampaignReportAll = {
  status: "Sent",
  deliveredDate: "2024-05-31T23:30:00Z",
  totalOpened: 8,
  totalDelivered: 10,
  firstOpened: "2024-06-01T08:30:00Z",
  lastOpened: "2024-06-05T11:00:00Z",
  numberOfDownloads: 5,
  campaignReports: [
    {
      day: "2024-06-03T00:30:00Z",
      numberOfDocumentsRead: 2,
      numberOfDocumentsDownloaded: 1,
    },
    {
      day: "2024-06-02T00:30:00Z",
      numberOfDocumentsRead: 3,
      numberOfDocumentsDownloaded: 0,
    },
    {
      day: "2024-06-01T00:30:00Z",
      numberOfDocumentsRead: 5,
      numberOfDocumentsDownloaded: 4,
    },
  ],
} as DistributionCampaignReportDto

export const dummyDistributionCampaignReportClient = {
  status: "Sent",
  deliveredDate: "2023-08-31T00:30:00",
  totalOpened: 5,
  totalDelivered: 5,
  firstOpened: "2023-09-21T09:30:00",
  lastOpened: "2023-10-01T11:00:00Z",
  numberOfDownloads: 1,
  campaignReports: [
    {
      day: "2023-09-02T00:30:00Z",
      numberOfDocumentsRead: 2,
      numberOfDocumentsDownloaded: 1,
    },
    {
      day: "2023-09-05T00:30:00Z",
      numberOfDocumentsRead: 3,
      numberOfDocumentsDownloaded: 0,
    },
  ],
} as DistributionCampaignReportDto

export const dummyDistributionCampaignReportAdvisor = {
  status: "Sent",
  deliveredDate: "2023-08-31T00:30:00",
  totalOpened: 3,
  totalDelivered: 5,
  firstOpened: "2023-09-21T09:30:00",
  lastOpened: "2023-10-01T11:00:00Z",
  numberOfDownloads: 4,
  campaignReports: [
    {
      day: "2023-09-10T00:30:00Z",
      numberOfDocumentsRead: 5,
      numberOfDocumentsDownloaded: 4,
    },
  ],
} as DistributionCampaignReportDto

export const dummyAnalyicsData = {
  status: "Sent",
  sendDateAdviser: "2021-06-01T00:00:00Z",
  sendDateClient: "2021-06-01T00:00:00Z",
  sizeOfDistribution: 10,
  readCount: 10,
  version: "2",
  fileVisibility: "ClientAndAdviser",
  isSubAccount: false,
  createdDate: "2021-06-01T00:00:00Z",
  downloads: 2,
} as DistributionProjectAnalyticsInfoDto

export const getDocumentFilters = (
  projectInfo: DistributionProjectInfoDto
): TFilterOption[] => {
  if (!projectInfo.versionedFiles || projectInfo.versionedFiles.length <= 0)
    return []
  const filters = projectInfo.versionedFiles?.map((file, index) => {
    const name = file!.files![file!.currentVersion! - 1].name
    return {
      name: name,
      checked: index === 0,
      value: name,
      type: "name",
      id: file!.files![file!.currentVersion! - 1].id,
    } as TFilterOption
  })
  return filters ?? []
}

export const getVersionFilters = (
  projectInfo: DistributionProjectInfoDto,
  currentSelectedFilterFileId: string
): TFilterOption[] => {
  if (!projectInfo.versionedFiles || !currentSelectedFilterFileId) return []
  const selectedFile = projectInfo.versionedFiles.find((x) => {
    return x.files?.find((y) => y.id === currentSelectedFilterFileId)
  })!
  const filters = selectedFile.files?.map((version) => {
    const isCurrent = version.version === selectedFile.currentVersion
    const versionDate = dayjs(version.created).format("DD/MM/YY - hh:mmA")
    return {
      name:
        (isCurrent ? "Current version - " : "") +
        "V" +
        version.version +
        " - " +
        versionDate,
      checked: isCurrent,
      value: version.version?.toString(),
      type: "name",
    } as TFilterOption
  })
  return filters?.reverse() ?? []
}

export const deviceFilterTemplate: TFilterOption[] = [
  {
    name: "Mobile (App)",
    checked: false,
    value: "1",
    type: FilterType.FORMAT,
  },
  {
    name: "Desktop (Website)",
    checked: false,
    value: "0",
    type: FilterType.FORMAT,
  },
]

export const userTypeFilterTemplate: TFilterOption[] = [
  {
    name: "Client",
    checked: false,
    value: "1",
    type: FilterType.NAME,
  },
  {
    name: "Advisers",
    checked: false,
    value: "2",
    type: FilterType.NAME,
  },
]

export const defaultFilterTemplate: CampaignReportFilterValues = {
  startDate: dayjs().format("YYYY-MM-DD HH:MM").toString(),
  endDate: dayjs().format("YYYY-MM-DD HH:MM").toString(),
  device: -1,
  userType: 0,
  version: 0,
  fileId: undefined,
}

export const filterValueToEnum = (
  filters: TFilterOption[],
  allValue: number
) => {
  const checked = filters.filter((x) => x.checked)
  return checked.length === 0 || checked.length === filters.length
    ? allValue
    : (checked[0].value as unknown as number)
}

export const getFilterFileId = (filterOption: TFilterOption[]) => {
  const checked = filterOption.filter((x) => x.checked)
  return checked.length === 0 ? undefined : checked[0].id
}
