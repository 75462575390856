import React, { FC } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { MainLayout } from "./Main"

export const MainLayoutWrapper: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation()

  const pageTitle =
    pathname === "/"
      ? "Document Vault"
      : pathname === "/my-files"
      ? "Files"
      : pathname === "/sharing"
      ? "Contacts"
      : pathname === "/sharedWithMe"
      ? "Shared"
      : pathname === "/search-result"
      ? "Search result"
      : pathname === "/All-Activity"
      ? "All activity"
      : pathname === "/notifications"
      ? "Notifications"
      : ""

  return (
    <MainLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </MainLayout>
  )
}
