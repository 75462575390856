import { FunctionComponent } from "react"
import { NavLink } from "react-router-dom"
import { TLink } from "../../contexts/application/constants"
import "./MenuBarSimpleItem.css"
import { sendGaData } from "../../utils/gaEvents"

type IMenuBarSimpleItem = {
  link: TLink
}
export const MenuBarSimpleItem: FunctionComponent<IMenuBarSimpleItem> = ({
  link,
}) => {
  const onAnalyticsClick = () => {
    if (link.href === "/analytics") {
      sendGaData({ name: "page_view", page_name: link.href })
    }
  }
  return (
    <NavLink
      role="menuitem"
      className={({ isActive }) =>
        `menubarsimpleitem ${isActive ? "menubarsimpleitem--active" : ""}`
      }
      to={link.href}
      onClick={onAnalyticsClick}
    >
      <span className="menubarsimpleitem__text">{link.name}</span>
    </NavLink>
  )
}
