import React from "react"
import Link from "../../../atoms/Link/Link"
import { MessageType } from "../AlertMessage"
import { getAlertErrorMessage } from "../utils/alertMessageErrorTranslationsService"
import "./AlertMessageItemList.css"
import { Button } from "../../../atoms/Button"
import classNames from "classnames"
import { isNullEmptyOrUndefined } from "../../../../utils/assertions/typeAssertions"

export interface AlertMessageItem {
  id: number
  href: string
  message: string
  // This is used to determine if the error is actionable, for example if the error is a warning and the user can click on it to navigate to a different page
  isAction?: boolean
  onClick?: (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void | Promise<void>
}

interface AlertMessageItemListProps {
  messageType: MessageType
  alertItems: AlertMessageItem[]
  shouldDisplayButton?: boolean
}

const AlertMessageItemList: React.FC<AlertMessageItemListProps> = ({
  messageType,
  alertItems,
  shouldDisplayButton,
}) => {
  if (messageType === MessageType.ERROR) {
    return (
      <ul>
        {alertItems.map((alertItem) => (
          <li key={alertItem.id}>
            <Link
              href={`#${alertItem.href}`}
              onClick={(e) => {
                e.preventDefault()
                const element = document.getElementById(alertItem.href)
                if (element) {
                  element.focus()
                }
              }}
            >
              {getAlertErrorMessage(alertItem.message)}
            </Link>
          </li>
        ))}
      </ul>
    )
  } else if (messageType === MessageType.WARNING) {
    return shouldDisplayButton ? (
      <Button
        className="stage-details-upload-file-button-text"
        size="regular"
        shouldShowIcon={false}
        onClick={async () => {
          if (alertItems[0].isAction) {
            alertItems[0].onClick?.()
          }
        }}
      >
        <span className="stage-details-upload-file-button-text">
          {alertItems[0].message}
        </span>
      </Button>
    ) : (
      <ul>
        {alertItems.map((alertItem) => (
          <li
            key={alertItem.id}
            className={classNames({
              message:
                !alertItem.onClick && isNullEmptyOrUndefined(alertItem.href),
            })}
          >
            {(alertItem.onClick || !isNullEmptyOrUndefined(alertItem.href)) && (
              <Link
                href={alertItem.isAction ? "" : `#${alertItem.href}`}
                onClick={() => {
                  if (alertItem.isAction) {
                    alertItem.onClick?.()
                  }
                }}
              >
                {alertItem.message}
              </Link>
            )}
            {!alertItem.onClick &&
              isNullEmptyOrUndefined(alertItem.href) &&
              alertItem.message}
          </li>
        ))}
      </ul>
    )
  }
}

export default AlertMessageItemList
