"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorporateAdminApi = exports.CorporateAdminApiFactory = exports.CorporateAdminApiFp = exports.CorporateAdminApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * CorporateAdminApi - axios parameter creator
 * @export
 */
const CorporateAdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {FileVisibility} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser  0 &#x3D; AdminOnly  1 &#x3D; AdviserOnly  2 &#x3D; ClientOnly  3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminFileFileIdVisibilityVisibilityPatch: (fileId, visibility, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiCorporateAdminFileFileIdVisibilityVisibilityPatch.');
            }
            // verify required parameter 'visibility' is not null or undefined
            if (visibility === null || visibility === undefined) {
                throw new base_1.RequiredError('visibility', 'Required parameter visibility was null or undefined when calling apiCorporateAdminFileFileIdVisibilityVisibilityPatch.');
            }
            const localVarPath = `/api/CorporateAdmin/file/{fileId}/visibility/{visibility}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"visibility"}}`, encodeURIComponent(String(visibility)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a single file to client's vault based on the data provided in the
         * @summary Upload file to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminHeadaccountHeadAccountFileuploadPost: (headAccount, subAccount, visibility, sendNotification, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'headAccount' is not null or undefined
            if (headAccount === null || headAccount === undefined) {
                throw new base_1.RequiredError('headAccount', 'Required parameter headAccount was null or undefined when calling apiCorporateAdminHeadaccountHeadAccountFileuploadPost.');
            }
            const localVarPath = `/api/CorporateAdmin/headaccount/{headAccount}/fileupload`
                .replace(`{${"headAccount"}}`, encodeURIComponent(String(headAccount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (subAccount !== undefined) {
                localVarQueryParameter['subAccount'] = subAccount;
            }
            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }
            if (sendNotification !== undefined) {
                localVarQueryParameter['sendNotification'] = sendNotification;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CorporateAdminApiAxiosParamCreator = CorporateAdminApiAxiosParamCreator;
/**
 * CorporateAdminApi - functional programming interface
 * @export
 */
const CorporateAdminApiFp = function (configuration) {
    return {
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {FileVisibility} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser  0 &#x3D; AdminOnly  1 &#x3D; AdviserOnly  2 &#x3D; ClientOnly  3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.CorporateAdminApiAxiosParamCreator)(configuration).apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a single file to client's vault based on the data provided in the
         * @summary Upload file to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.CorporateAdminApiAxiosParamCreator)(configuration).apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CorporateAdminApiFp = CorporateAdminApiFp;
/**
 * CorporateAdminApi - factory interface
 * @export
 */
const CorporateAdminApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {FileVisibility} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser  0 &#x3D; AdminOnly  1 &#x3D; AdviserOnly  2 &#x3D; ClientOnly  3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.CorporateAdminApiFp)(configuration).apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a single file to client's vault based on the data provided in the
         * @summary Upload file to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.CorporateAdminApiFp)(configuration).apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.CorporateAdminApiFactory = CorporateAdminApiFactory;
/**
 * CorporateAdminApi - object-oriented interface
 * @export
 * @class CorporateAdminApi
 * @extends {BaseAPI}
 */
class CorporateAdminApi extends base_1.BaseAPI {
    /**
     * Changes the file visibility attribute
     * @summary Change file visibility
     * @param {string} fileId Id of the users file to change visibility
     * @param {FileVisibility} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser  0 &#x3D; AdminOnly  1 &#x3D; AdviserOnly  2 &#x3D; ClientOnly  3 &#x3D; ClientAndAdviser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateAdminApi
     */
    apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.CorporateAdminApiFp)(this.configuration).apiCorporateAdminFileFileIdVisibilityVisibilityPatch(fileId, visibility, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a single file to client's vault based on the data provided in the
     * @summary Upload file to client vault
     * @param {string} headAccount The head-account reference
     * @param {string} [subAccount] (Optional) The sub-account reference (if required)
     * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
     * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorporateAdminApi
     */
    apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.CorporateAdminApiFp)(this.configuration).apiCorporateAdminHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, sendNotification, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.CorporateAdminApi = CorporateAdminApi;
