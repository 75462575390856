import { ChangeEvent, FC, KeyboardEvent } from "react"
import { useNavigate } from "react-router-dom"
import { PAGE_PATH } from "../../../../contexts/application/constants"
import {
  setSearchExpression,
  useSearchContext,
} from "../../../../contexts/search"
import Search from "../../../../design-tokens/icons/theme/oneX/Search"
import { useGetIcon } from "../../../../styled-components/GetIconLibraryInTheme"
import { replaceParamsInPath } from "../../../../utils/navigation/replaceParamsInPath"

export interface PageContentSearchBarProps {
  workflowId: string
  workflowVersion: string
  caseId: string
}

const PageContentSearchBar: FC<PageContentSearchBarProps> = ({
  workflowId,
  workflowVersion,
  caseId,
}) => {
  const {
    searchState: { searchExpression },
    dispatch,
  } = useSearchContext()

  const navigate = useNavigate()
  const crossIcon = useGetIcon("Cross")

  const handleOnSearchBarClick = () => {
    if (
      !searchExpression ||
      searchExpression.trim() === "" ||
      !workflowId ||
      !workflowVersion ||
      !caseId
    )
      return
    navigate(
      replaceParamsInPath({
        path: PAGE_PATH.CaseSearchPage,
        pathParameters: {
          workflowId: workflowId,
          workflowVersion: workflowVersion,
          caseId: caseId,
        },
      })
    )
  }
  const navigateToCaseDetailsPage = () => {
    navigate(
      replaceParamsInPath({
        path: PAGE_PATH.CaseDetailsPage,
        pathParameters: {
          workflowId: workflowId,
          workflowVersion: workflowVersion,
          caseId: caseId,
        },
      })
    )
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnSearchBarClick()
    }
  }

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search document"
        className="search-input"
        aria-label="Search document"
        role="searchbox"
        value={searchExpression}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch(setSearchExpression(e.target.value))
        }}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      {searchExpression && (
        <button
          className="crossIcon"
          title="Clear search"
          type="button"
          tabIndex={0}
          onClick={() => {
            dispatch(setSearchExpression(""))
            if (workflowId && workflowVersion && caseId) {
              navigateToCaseDetailsPage()
            }
          }}
        >
          {crossIcon}
        </button>
      )}
      <button
        className="search-bar-button"
        aria-label="Search document button"
        tabIndex={-1}
        onClick={() => handleOnSearchBarClick()}
      >
        <Search />
      </button>
    </div>
  )
}

export default PageContentSearchBar
