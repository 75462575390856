"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowApi = exports.WorkflowApiFactory = exports.WorkflowApiFp = exports.WorkflowApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * WorkflowApi - axios parameter creator
 * @export
 */
const WorkflowApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * returns all the documents for a case by external case id
         * @summary Gets all the documents for a case by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdDocumentsGet: (externalId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'externalId' is not null or undefined
            if (externalId === null || externalId === undefined) {
                throw new base_1.RequiredError("externalId", "Required parameter externalId was null or undefined when calling apiWorkflowCaseExternalIdDocumentsGet.");
            }
            const localVarPath = `/api/Workflow/case/{externalId}/documents`.replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * returns case Id, case name, workflow Id, workflow version
         * @summary Get case info by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdGet: (externalId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'externalId' is not null or undefined
            if (externalId === null || externalId === undefined) {
                throw new base_1.RequiredError("externalId", "Required parameter externalId was null or undefined when calling apiWorkflowCaseExternalIdGet.");
            }
            const localVarPath = `/api/Workflow/case/{externalId}`.replace(`{${"externalId"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * The list of cases for the currently logged in user
         * @summary Get list of cases for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseListGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Workflow/case/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single specific document for a case via the internal file id
         * @summary Get a single document for a case via file id
         * @param {string} fileId File Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowFileFileIdGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError("fileId", "Required parameter fileId was null or undefined when calling apiWorkflowFileFileIdGet.");
            }
            const localVarPath = `/api/Workflow/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * The complete list of documents with status
         * @summary Get a list of all documents for a case with status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet: (workflowId, version, caseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/documents`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a document to a stage request in a case
         * @summary Upload Document to Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} [stageId] Stage Id
         * @param {number} [requestId] Request Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost: (workflowId, version, caseId, stageId, requestId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/documents/upload`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (stageId !== undefined) {
                localVarQueryParameter["stageId"] = stageId;
            }
            if (requestId !== undefined) {
                localVarQueryParameter["requestId"] = requestId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Confirms that the docusign was sent via the FE
         * @summary Confirm Docusign Was Sent
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost: (workflowId, version, caseId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/docusign-sent`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Confirms that the docusign file was signed by current user
         * @summary Confirm Docusign Was Signed
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost: (workflowId, version, caseId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/docusign-signed`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Takes an unattached case file and attaches it to particular request on case
         * @summary Attach File To Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {string} fileId
         * @param {AttachCaseDocumentDto} [body] Attach File Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut: (workflowId, version, caseId, fileId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut.");
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError("fileId", "Required parameter fileId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/file/{fileId}/attach`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Case with status, stages, documents and documents requests
         * @summary Get Case
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdGet: (workflowId, version, caseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdGet.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdGet.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdGet.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Run an action against a case's stage request document
         * @summary Update Document Status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {CaseActionDto} [body] Case Action Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut: (workflowId, version, caseId, stageId, requestId, documentId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new base_1.RequiredError("requestId", "Required parameter requestId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new base_1.RequiredError("documentId", "Required parameter documentId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/stage/{stageId}/request/{requestId}/document/{documentId}/actions`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname +
                    localVarUrlObj.search +
                    localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve a single specific document for a case
         * @summary Get a single document for a case
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet: (workflowId, version, caseId, stageId, requestId, documentId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new base_1.RequiredError("requestId", "Required parameter requestId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new base_1.RequiredError("documentId", "Required parameter documentId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/stage/{stageId}/request/{requestId}/document/{documentId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname +
                    localVarUrlObj.search +
                    localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update entered details for a file
         * @summary Update File Details
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId Document Id
         * @param {UpdateCaseDocumentRequestDto} [body] FileNodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch: (workflowId, version, caseId, stageId, requestId, documentId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new base_1.RequiredError("requestId", "Required parameter requestId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new base_1.RequiredError("documentId", "Required parameter documentId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/stage/{stageId}/request/{requestId}/document/{documentId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname +
                    localVarUrlObj.search +
                    localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {number} requestId
         * @param {boolean} [remindersEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch: (workflowId, version, caseId, stageId, requestId, remindersEnabled, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch.");
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new base_1.RequiredError("requestId", "Required parameter requestId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/stage/{stageId}/request/{requestId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PATCH" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (remindersEnabled !== undefined) {
                localVarQueryParameter["remindersEnabled"] = remindersEnabled;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname +
                    localVarUrlObj.search +
                    localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * The list of requests for a case stage
         * @summary Get a list of requests for a case stage
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet: (workflowId, version, caseId, stageId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet.");
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError("version", "Required parameter version was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{version}/case/{caseId}/stage/{stageId}/requests`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * The list of case actions for the currently logged user
         * @summary Get list of case actions for the user
         * @param {string} workflowId The Workflow Id
         * @param {number} versionId Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet: (workflowId, versionId, caseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet.");
            }
            // verify required parameter 'versionId' is not null or undefined
            if (versionId === null || versionId === undefined) {
                throw new base_1.RequiredError("versionId", "Required parameter versionId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{versionId}/case/{caseId}/actions`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Hard Delete a document from a stage request in a case
         * @summary Delete Document
         * @param {string} workflowId Workflow Id
         * @param {number} versionId Id of Workflow Version
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete: (workflowId, versionId, caseId, stageId, requestId, documentId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'workflowId' is not null or undefined
            if (workflowId === null || workflowId === undefined) {
                throw new base_1.RequiredError("workflowId", "Required parameter workflowId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            // verify required parameter 'versionId' is not null or undefined
            if (versionId === null || versionId === undefined) {
                throw new base_1.RequiredError("versionId", "Required parameter versionId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new base_1.RequiredError("caseId", "Required parameter caseId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            // verify required parameter 'stageId' is not null or undefined
            if (stageId === null || stageId === undefined) {
                throw new base_1.RequiredError("stageId", "Required parameter stageId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            // verify required parameter 'requestId' is not null or undefined
            if (requestId === null || requestId === undefined) {
                throw new base_1.RequiredError("requestId", "Required parameter requestId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new base_1.RequiredError("documentId", "Required parameter documentId was null or undefined when calling apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete.");
            }
            const localVarPath = `/api/Workflow/{workflowId}/{versionId}/case/{caseId}/stage/{stageId}/request/{requestId}/document/{documentId}`
                .replace(`{${"workflowId"}}`, encodeURIComponent(String(workflowId)))
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"stageId"}}`, encodeURIComponent(String(stageId)))
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname +
                    localVarUrlObj.search +
                    localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.WorkflowApiAxiosParamCreator = WorkflowApiAxiosParamCreator;
/**
 * WorkflowApi - functional programming interface
 * @export
 */
const WorkflowApiFp = function (configuration) {
    return {
        /**
         * returns all the documents for a case by external case id
         * @summary Gets all the documents for a case by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdDocumentsGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowCaseExternalIdDocumentsGet(externalId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * returns case Id, case name, workflow Id, workflow version
         * @summary Get case info by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowCaseExternalIdGet(externalId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * The list of cases for the currently logged in user
         * @summary Get list of cases for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowCaseListGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieve a single specific document for a case via the internal file id
         * @summary Get a single document for a case via file id
         * @param {string} fileId File Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowFileFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowFileFileIdGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * The complete list of documents with status
         * @summary Get a list of all documents for a case with status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a document to a stage request in a case
         * @summary Upload Document to Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} [stageId] Stage Id
         * @param {number} [requestId] Request Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Confirms that the docusign was sent via the FE
         * @summary Confirm Docusign Was Sent
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Confirms that the docusign file was signed by current user
         * @summary Confirm Docusign Was Signed
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Takes an unattached case file and attaches it to particular request on case
         * @summary Attach File To Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {string} fileId
         * @param {AttachCaseDocumentDto} [body] Attach File Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Case with status, stages, documents and documents requests
         * @summary Get Case
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Run an action against a case's stage request document
         * @summary Update Document Status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {CaseActionDto} [body] Case Action Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieve a single specific document for a case
         * @summary Get a single document for a case
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Update entered details for a file
         * @summary Update File Details
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId Document Id
         * @param {UpdateCaseDocumentRequestDto} [body] FileNodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {number} requestId
         * @param {boolean} [remindersEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * The list of requests for a case stage
         * @summary Get a list of requests for a case stage
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * The list of case actions for the currently logged user
         * @summary Get list of case actions for the user
         * @param {string} workflowId The Workflow Id
         * @param {number} versionId Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Hard Delete a document from a stage request in a case
         * @summary Delete Document
         * @param {string} workflowId Workflow Id
         * @param {number} versionId Id of Workflow Version
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.WorkflowApiAxiosParamCreator)(configuration).apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.WorkflowApiFp = WorkflowApiFp;
/**
 * WorkflowApi - factory interface
 * @export
 */
const WorkflowApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * returns all the documents for a case by external case id
         * @summary Gets all the documents for a case by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdDocumentsGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowCaseExternalIdDocumentsGet(externalId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * returns case Id, case name, workflow Id, workflow version
         * @summary Get case info by external case id
         * @param {string} externalId External case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseExternalIdGet(externalId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowCaseExternalIdGet(externalId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * The list of cases for the currently logged in user
         * @summary Get list of cases for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowCaseListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowCaseListGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieve a single specific document for a case via the internal file id
         * @summary Get a single document for a case via file id
         * @param {string} fileId File Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowFileFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowFileFileIdGet(fileId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * The complete list of documents with status
         * @summary Get a list of all documents for a case with status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a document to a stage request in a case
         * @summary Upload Document to Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} [stageId] Stage Id
         * @param {number} [requestId] Request Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Confirms that the docusign was sent via the FE
         * @summary Confirm Docusign Was Sent
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Confirms that the docusign file was signed by current user
         * @summary Confirm Docusign Was Signed
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Takes an unattached case file and attaches it to particular request on case
         * @summary Attach File To Case
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {string} fileId
         * @param {AttachCaseDocumentDto} [body] Attach File Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Case with status, stages, documents and documents requests
         * @summary Get Case
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Run an action against a case's stage request document
         * @summary Update Document Status
         * @param {string} workflowId The Workflow Id
         * @param {number} version Workflow Version
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {CaseActionDto} [body] Case Action Dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieve a single specific document for a case
         * @summary Get a single document for a case
         * @param {string} workflowId The Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId The Case Id
         * @param {number} stageId The Stage Id
         * @param {number} requestId The Request Id
         * @param {number} documentId The Document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Update entered details for a file
         * @summary Update File Details
         * @param {string} workflowId Workflow Id
         * @param {number} version Version of Workflow
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId Document Id
         * @param {UpdateCaseDocumentRequestDto} [body] FileNodeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {number} requestId
         * @param {boolean} [remindersEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * The list of requests for a case stage
         * @summary Get a list of requests for a case stage
         * @param {string} workflowId
         * @param {number} version
         * @param {number} caseId
         * @param {number} stageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * The list of case actions for the currently logged user
         * @summary Get list of case actions for the user
         * @param {string} workflowId The Workflow Id
         * @param {number} versionId Version of Workflow
         * @param {number} caseId The Case Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Hard Delete a document from a stage request in a case
         * @summary Delete Document
         * @param {string} workflowId Workflow Id
         * @param {number} versionId Id of Workflow Version
         * @param {number} caseId Case Id
         * @param {number} stageId Stage Id
         * @param {number} requestId Request Id
         * @param {number} documentId document Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.WorkflowApiFp)(configuration)
                    .apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.WorkflowApiFactory = WorkflowApiFactory;
/**
 * WorkflowApi - object-oriented interface
 * @export
 * @class WorkflowApi
 * @extends {BaseAPI}
 */
class WorkflowApi extends base_1.BaseAPI {
    /**
     * returns all the documents for a case by external case id
     * @summary Gets all the documents for a case by external case id
     * @param {string} externalId External case Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowCaseExternalIdDocumentsGet(externalId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowCaseExternalIdDocumentsGet(externalId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * returns case Id, case name, workflow Id, workflow version
     * @summary Get case info by external case id
     * @param {string} externalId External case Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowCaseExternalIdGet(externalId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowCaseExternalIdGet(externalId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * The list of cases for the currently logged in user
     * @summary Get list of cases for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowCaseListGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowCaseListGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieve a single specific document for a case via the internal file id
     * @summary Get a single document for a case via file id
     * @param {string} fileId File Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowFileFileIdGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowFileFileIdGet(fileId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * The complete list of documents with status
     * @summary Get a list of all documents for a case with status
     * @param {string} workflowId The Workflow Id
     * @param {number} version Version of Workflow
     * @param {number} caseId The Case Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsGet(workflowId, version, caseId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a document to a stage request in a case
     * @summary Upload Document to Case
     * @param {string} workflowId Workflow Id
     * @param {number} version Version of Workflow
     * @param {number} caseId Case Id
     * @param {number} [stageId] Stage Id
     * @param {number} [requestId] Request Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdDocumentsUploadPost(workflowId, version, caseId, stageId, requestId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Confirms that the docusign was sent via the FE
     * @summary Confirm Docusign Was Sent
     * @param {string} workflowId The Workflow Id
     * @param {number} version Workflow Version
     * @param {number} caseId The Case Id
     * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSentPost(workflowId, version, caseId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Confirms that the docusign file was signed by current user
     * @summary Confirm Docusign Was Signed
     * @param {string} workflowId The Workflow Id
     * @param {number} version Workflow Version
     * @param {number} caseId The Case Id
     * @param {DocusignFileInfoDto} [body] Info to identify the Docusign file that has been signed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdDocusignSignedPost(workflowId, version, caseId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Takes an unattached case file and attaches it to particular request on case
     * @summary Attach File To Case
     * @param {string} workflowId Workflow Id
     * @param {number} version Version of Workflow
     * @param {number} caseId Case Id
     * @param {string} fileId
     * @param {AttachCaseDocumentDto} [body] Attach File Dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdFileFileIdAttachPut(workflowId, version, caseId, fileId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Case with status, stages, documents and documents requests
     * @summary Get Case
     * @param {string} workflowId
     * @param {number} version
     * @param {number} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdGet(workflowId, version, caseId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Run an action against a case's stage request document
     * @summary Update Document Status
     * @param {string} workflowId The Workflow Id
     * @param {number} version Workflow Version
     * @param {number} caseId The Case Id
     * @param {number} stageId The Stage Id
     * @param {number} requestId The Request Id
     * @param {number} documentId The Document Id
     * @param {CaseActionDto} [body] Case Action Dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdActionsPut(workflowId, version, caseId, stageId, requestId, documentId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieve a single specific document for a case
     * @summary Get a single document for a case
     * @param {string} workflowId The Workflow Id
     * @param {number} version Version of Workflow
     * @param {number} caseId The Case Id
     * @param {number} stageId The Stage Id
     * @param {number} requestId The Request Id
     * @param {number} documentId The Document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdGet(workflowId, version, caseId, stageId, requestId, documentId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Update entered details for a file
     * @summary Update File Details
     * @param {string} workflowId Workflow Id
     * @param {number} version Version of Workflow
     * @param {number} caseId Case Id
     * @param {number} stageId Stage Id
     * @param {number} requestId Request Id
     * @param {number} documentId Document Id
     * @param {UpdateCaseDocumentRequestDto} [body] FileNodeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdPatch(workflowId, version, caseId, stageId, requestId, documentId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @param {string} workflowId
     * @param {number} version
     * @param {number} caseId
     * @param {number} stageId
     * @param {number} requestId
     * @param {boolean} [remindersEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestRequestIdPatch(workflowId, version, caseId, stageId, requestId, remindersEnabled, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * The list of requests for a case stage
     * @summary Get a list of requests for a case stage
     * @param {string} workflowId
     * @param {number} version
     * @param {number} caseId
     * @param {number} stageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionCaseCaseIdStageStageIdRequestsGet(workflowId, version, caseId, stageId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * The list of case actions for the currently logged user
     * @summary Get list of case actions for the user
     * @param {string} workflowId The Workflow Id
     * @param {number} versionId Version of Workflow
     * @param {number} caseId The Case Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionIdCaseCaseIdActionsGet(workflowId, versionId, caseId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Hard Delete a document from a stage request in a case
     * @summary Delete Document
     * @param {string} workflowId Workflow Id
     * @param {number} versionId Id of Workflow Version
     * @param {number} caseId Case Id
     * @param {number} stageId Stage Id
     * @param {number} requestId Request Id
     * @param {number} documentId document Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApi
     */
    apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.WorkflowApiFp)(this.configuration)
                .apiWorkflowWorkflowIdVersionIdCaseCaseIdStageStageIdRequestRequestIdDocumentDocumentIdDelete(workflowId, versionId, caseId, stageId, requestId, documentId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.WorkflowApi = WorkflowApi;
