import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { DateTimeFormat } from "./constants"

dayjs.extend(utc)

export const isDateLessThanSetNumberOfMinutesFromNow = ({
  disDate,
  minutes,
}: {
  disDate: TDate
  minutes: number
}) => {
  const now = dayjs()
  const isBefore = dayjs(disDate).isBefore(now.add(minutes, "minutes"))
  return isBefore
}

export const isDateNewer = (firstDate: string, secondDate: string) => {
  const a = dayjs(firstDate)
  const b = dayjs(secondDate)

  return a.isAfter(b)
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm"
export const getDbFormat = (date?: string | null) => {
  if (date) {
    return dayjs(date).utc().format(DATE_FORMAT)
  }
  return dayjs().add(15, "minutes").format(DATE_FORMAT)
}

export const addMinutes = (date: Date, min: number) => {
  const dateDayjs = dayjs(date).add(min, "minutes")
  return dateDayjs.format()
}

export const addDays = (date: Date, days: number) => {
  const dateDayjs = dayjs(date).add(days, "days")
  return dateDayjs.format()
}

export const getDayJsStr = (date: TDate) => {
  if (date) {
    const dateDayjs = dayjs(date).local()
    return dateDayjs.format()
  }
}

const areDatesNotDefined = (date1: TDate, date2: TDate) => {
  if (
    (!date1 || (typeof date1 === "string" && date1.includes("0001-01-01"))) &&
    (!date2 || (typeof date2 === "string" && date2.includes("0001-01-01")))
  ) {
    return true
  }
}

type TDate = Date | string | undefined | null
export const compareIfEqualDates = ({
  projectDate,
  newDate,
}: {
  projectDate: TDate
  newDate: TDate
}) => {
  const datesAreNotDefined = areDatesNotDefined(projectDate, newDate)
  if (datesAreNotDefined) {
    return true
  }
  const projectDateDay = getDayJsStr(projectDate)
  const newDateDay = getDayJsStr(newDate)

  return projectDateDay === newDateDay
}

type TAddUnit = "hours" | "days" | "minutes"
type TAddXArray = [number, TAddUnit]
export const oneXFromNow = (addX: TAddXArray) => {
  const now = dayjs()
  return now.add(addX[0], addX[1]).format(DATE_FORMAT)
}

export const getMonthsAgoDate = (date: Date, monthsToSubtract: number) => {
  return dayjs(date).subtract(monthsToSubtract, "month").toDate()
}

export const plainDateFormat = (date?: Date) => {
  if (date === undefined) return undefined
  return dayjs(date).format("DD/MM/YYYY")
}

export const getDay = (date: Date) => {
  return dayjs(date).format("DD")
}

/**
 * Given a datetime string, returns a string in the format "DD/MM/YYYY - hh<am/pm>"
 * @param dateTime
 *
 */
export const getDateTimeString = (dateTime: string): string => {
  const date = new Date(dateTime)

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear()

  const datePart = `${day}/${month}/${year}`

  const shortTime = new Intl.DateTimeFormat("en", {
    timeStyle: "short",
  })
  const timePart = shortTime.format(date).replace(/\s/g, "").toLowerCase()
  return `${datePart} - ${timePart}`
}

/**
 * Converts date string to a date object
 * uses the enum DateTimeFormat to determine the format of the date string
 * @param date : string
 * @param format : DateTimeFormat
 * @returns formatted date
 */
export function formatDate({
  date,
  format,
}: {
  date?: string | null
  format: DateTimeFormat
}) {
  if (date === null || !date) {
    return ""
  }

  if (!date.endsWith("Z")) {
    date = date + "Z"
  }

  const formattedDate = dayjs(date).format(format)

  return formattedDate
}
