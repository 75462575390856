import { useLocation } from "react-router-dom"
import { useUserContext } from "../../contexts/users"
import { IUser } from "../../api/api-client/api-types"
import { PAGE_PATH, UserModuleType } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import {
  AmadeusFooter,
  Footer,
  FooterBarclays,
  FooterMoneyhub,
  FortCirrusFooter,
  LegadoFooter,
  DocumentHubOrLibraryFooter,
  TFEFooterSmall,
  LifeStageFooter,
} from "../Footer"
import {
  TopBarBarclays,
  TopBarMoneyhub,
  TopBarOneX,
  TopBarQuilter,
} from "../TopBar"
import { TopBarAbrdn } from "../TopBar/Abrdn/TopBarAbrdn"

export const GetFooter = () => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  switch (selectedTheme) {
    case "BARCLAYS":
      return <FooterBarclays />
    case "MONEYHUB":
      return <FooterMoneyhub />
    case "LEGADO":
      return <LegadoFooter />
    case "FORT_CIRRUS":
      return <FortCirrusFooter />
    case "THE_FAMILY_ELEPHANT":
      return <TFEFooterSmall />
    case "QUILTER":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Quilter platform" />
      )
    case "ONE_LEGACY":
      return <></>
    case "AMADEUS":
      return <AmadeusFooter />
    case "ABRDN":
    case "ABRDN_VANILLA":
      return <DocumentHubOrLibraryFooter text="As part of the abrdn platform" />

    case "ABRDN_BALMORAL":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Balmoral Asset Management platform" />
      )
    case "ABRDN_CAPITAL":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Capital Asset Management platform" />
      )
    case "ABRDN_LUDLOW":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Ludlow platform" />
      )
    case "ABRDN_GIBBS":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Gibbs Denley platform" />
      )
    case "ABRDN_SUCCESSION":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Succession Wealth platform" />
      )
    case "ABRDN_SHERIDAN":
      return (
        <DocumentHubOrLibraryFooter text="As part of the Sheridan platform" />
      )
    case "LIFESTAGE":
      return <LifeStageFooter />
    default:
      return <Footer />
  }
}

export const GetTopbar = () => {
  const {
    applicationState: { selectedTheme, hideHeader },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const { search } = useLocation()

  const searchParams = Object.fromEntries(new URLSearchParams(search).entries())

  const showDocumentHub =
    currentUser?.modules?.includes(UserModuleType.DOCUMENT_HUB) &&
    !searchParams.context

  switch (selectedTheme) {
    case "BARCLAYS":
      return <TopBarBarclays />
    case "MONEYHUB":
      return <TopBarMoneyhub />
    case "ONE_X":
      return <TopBarOneX hideHeader={hideHeader} />
    case "QUILTER":
      if (showDocumentHub) {
        return (
          <TopBarQuilter
            title="Document Hub"
            subtitle="As part of the Quilter platform"
          />
        )
      } else {
        return (
          <TopBarQuilter
            title="Document Library"
            subtitle="As part of the Quilter platform"
          />
        )
      }
    case "ABRDN":
    case "ABRDN_VANILLA":
    case "ABRDN_BALMORAL":
    case "ABRDN_CAPITAL":
    case "ABRDN_LUDLOW":
    case "ABRDN_GIBBS":
    case "ABRDN_SUCCESSION":
    case "ABRDN_SHERIDAN":
      if (showDocumentHub) {
        return (
          <TopBarAbrdn
            title="Document Hub"
            subtitle="As part of the abrdn platform"
          />
        )
      } else {
        return (
          <TopBarAbrdn
            title="Document Library"
            subtitle="As part of the abrdn platform"
            shouldGetLogoFromApi
          />
        )
      }

    default:
      return undefined
  }
}

const pagesWithoutSearch = [PAGE_PATH.DocumentLibraryPage]

export const ShowSearchBar = (user?: IUser) => {
  const pagePath = new URL(window.location.href).pathname
  return (
    user?.modules?.includes(UserModuleType.SEARCH) &&
    !pagesWithoutSearch.includes(pagePath)
  )
}
