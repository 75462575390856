import { FC } from "react"

import { IFile } from "../../../../../api/api-client/api-types"
import { MaxFileNameLength } from "../../../../../utils/consts/consts"
import {
  InputFieldType,
  validateInput,
} from "../../../../../utils/forms/validateInputs"
import {
  BrowserDefaultSelect,
  BrowserDefaultSelectItem,
} from "../../../../atoms/BrowserDefaultSelect/BrowserDefaultSelect"
import { TextInputWithValidation } from "../../../../atoms/TextInput/TextInputWithValidation"
import { DocumentDetailsErrorMessages } from "../../../../modules/AddDocumentDetailsForUploadedFile/AddDocumentDetailsForUploadedFile"
import { AlertMessageItem } from "../../../../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import {
  Option,
  UploadedDocument,
} from "../../../../modules/UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"

import "./DocumentDetailsForm.css"

interface DocumentDetailsFormProps {
  file: IFile
  requestDropdownOptions: Option[]
  formData: UploadedDocument[]
  formErrors: AlertMessageItem[] | undefined
  base64ThumbnailString: string
  setFormData: (data: UploadedDocument[]) => void
  uploadedViaCategory: boolean
  setUnsavedChanges?: (unsavedChanges: boolean) => void
}

export const DocumentDetailsForm: FC<DocumentDetailsFormProps> = ({
  file,
  requestDropdownOptions,
  formData,
  formErrors,
  base64ThumbnailString,
  setFormData,
  uploadedViaCategory,
  setUnsavedChanges,
}) => {
  const fileInfo = formData.find((x) => x.fileId === file.id)

  const data: UploadedDocument = {
    fileId: file.id,
    name: fileInfo?.name ?? "",
    category: fileInfo?.category ?? {
      stageId: "",
      requestId: "",
      name: "",
    },
    base64ThumbnailString: base64ThumbnailString,
    fileExtension: fileInfo?.fileExtension ?? "",
  }

  const setUpdatedFormData = (newDocumentDetails: UploadedDocument) => {
    const newFormData: UploadedDocument[] = [...formData]

    let dataToUpdate = newFormData.find((x) => x.fileId === file.id)

    dataToUpdate = newDocumentDetails

    newFormData.splice(
      newFormData.findIndex((x) => x.fileId === file.id),
      1,
      dataToUpdate
    )

    setFormData(newFormData)
    setUnsavedChanges?.(true)
  }

  const onNameChange = (newValue: string) => {
    const newDocumentDetails: UploadedDocument = {
      ...data,
      name: newValue,
    }

    setUpdatedFormData(newDocumentDetails)
    setUnsavedChanges?.(true)
  }

  const onRequestChange = (newValue: string) => {
    const option = requestDropdownOptions.find((x) => x.value === newValue)

    const newDocumentDetails: UploadedDocument = {
      ...data,
      category: {
        stageId: option?.stageId ?? "",
        requestId: option?.requestId ?? "",
        name: newValue.split(":")[0].trim(),
      },
    }

    setUpdatedFormData(newDocumentDetails)
  }

  const getIsInvalidName = (newValue: string) => {
    //Validate document name
    const documentNameInputErrors = validateInput({
      type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
      value: newValue,
    })
    if (documentNameInputErrors.length > 0 || newValue.length === 0) {
      return true
    }
    return false
  }

  const getIsInvalidCategory = (newValue: string) => {
    if (newValue === "") {
      return true
    }
    return false
  }

  const getOptions = () => {
    const newOptions: BrowserDefaultSelectItem[] = []
    newOptions.push({
      name: "Select option",
      value: "",
      uniqueId: "select-category",
      selected:
        formData.find((x) => x.fileId === file.id)?.category.name === "",
    })
    requestDropdownOptions.forEach((option, index) => {
      newOptions.push({
        name: option.name,
        value: option.value,
        uniqueId:
          fileInfo?.fileId ?? fileInfo?.name
            ? fileInfo.name + index
            : index.toString(),
        selected:
          formData.find((x) => x.fileId === file.id)?.category.name ===
          option.name,
      })
    })

    return newOptions
  }

  return (
    <div className="document-details-form">
      <TextInputWithValidation
        value={data.name}
        label="Document name"
        labelClassName="required"
        type={InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT}
        name={`${data.name.replace(/\s+/g, "-")}-name`}
        maxLength={MaxFileNameLength}
        displayError={false}
        validateOnSubmit
        errorOnSubmit={{
          hasError: formErrors !== undefined && getIsInvalidName(data.name),
          message: DocumentDetailsErrorMessages.EMPTY_NAME,
        }}
        onChange={onNameChange}
      />
      {!uploadedViaCategory && (
        <BrowserDefaultSelect
          label="Document category"
          name={`${data.name.replace(/\s+/g, "-")}-category`}
          isRequired
          options={getOptions()}
          onChange={onRequestChange}
          error={{
            hasError:
              formErrors !== undefined &&
              getIsInvalidCategory(data.category.name),
            message: "",
          }}
        />
      )}
    </div>
  )
}
