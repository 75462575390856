import { useMsal } from "@azure/msal-react"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"

// COOP case handlers would never log out from the app, but this is route is here for completeness and to test the logout functionality.
export const LogoutCaseManagerPage = () => {
  localStorage.removeItem(LocalStorageKeys.Token)
  localStorage.removeItem(LocalStorageKeys.ApiKey)
  localStorage.removeItem(LocalStorageKeys.UserEmail)
  localStorage.removeItem(LocalStorageKeys.ExternalId)

  const { instance } = useMsal()
  instance.logoutRedirect({
    postLogoutRedirectUri: "/",
  })

  return null
}
