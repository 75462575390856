import { FC } from "react"
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modules/Modal"
import { Button } from "../atoms/Button"
import { IContactInvite } from "../../api/api-client/api-types"
import "../SharingPermissions/SharingPermissions.css"
import { SharingPermissionsContact } from "../SharingPermissions/SharingPermissionsContact"
import { TRadioOptions } from "../../utils/shares/helpers"

export interface FolderPermissionsProps {
  addingSharee?: boolean
  contact: IContactInvite
  sharingOptions: TRadioOptions[]
  setSharingOptions: (sharingOptions: TRadioOptions[]) => void
  onBackButtonClick: () => void
  onContinueButtonClick: () => void
  showImportedFolderWarning?: () => void
  whatIsBeingShared?: string
  nextStepButtonLabel?: string
  isAddingContactThroughWorkflowDocument?: boolean
}

export const SharingPermissionsNewContact: FC<FolderPermissionsProps> = ({
  addingSharee,
  contact,
  sharingOptions,
  setSharingOptions,
  onBackButtonClick,
  onContinueButtonClick,
  showImportedFolderWarning,
  whatIsBeingShared = "sections and folders",
  nextStepButtonLabel,
  isAddingContactThroughWorkflowDocument = false,
}) => {
  const getNextStepButtonText = () => {
    if (!!nextStepButtonLabel) {
      return nextStepButtonLabel
    }
    if (addingSharee) {
      return "Review summary"
    }
    return "Add personal note"
  }
  const headerText = `2/${addingSharee ? "3" : "4"}: ${
    isAddingContactThroughWorkflowDocument
      ? "Document to be shared"
      : "Choose what to share"
  }`
  const fullContactName = `${contact.firstName} ${contact.surname}`
  return (
    <>
      <ModalHeader className="permissions has-subheader">
        Add new contact
      </ModalHeader>
      <ModalSubHeader className="header__border-bottom--base">
        {headerText}
      </ModalSubHeader>
      <ModalContent className="sharing-permissions">
        <p className="mb-2 create-contact-text">
          {isAddingContactThroughWorkflowDocument ? (
            <>
              <span className="fw-700">{fullContactName}</span> will be able to
              access this document.
            </>
          ) : (
            <>
              Choose the {whatIsBeingShared} that{" "}
              <span className="fw-700">{fullContactName}</span> should be able
              to access.
            </>
          )}
        </p>

        <SharingPermissionsContact
          sharingOptions={sharingOptions}
          setSharingOptions={setSharingOptions}
          showImportedFolderWarning={showImportedFolderWarning}
          isAddingContactThroughWorkflowDocument={
            isAddingContactThroughWorkflowDocument
          }
        />
      </ModalContent>
      <ModalFooter className="create-contact-footer modal-footer flex">
        <Button
          className="mr-4"
          variant="tertiary"
          type="reset"
          onClick={onBackButtonClick}
        >
          Back
        </Button>
        <Button variant="primary" type="submit" onClick={onContinueButtonClick}>
          {getNextStepButtonText()}
        </Button>
      </ModalFooter>
    </>
  )
}
