"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganisationApi = exports.OrganisationApiFactory = exports.OrganisationApiFp = exports.OrganisationApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * OrganisationApi - axios parameter creator
 * @export
 */
const OrganisationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get Logo for Organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationLogoGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Organisation/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get All child organisations of parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdChildorgsGet: (organisationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new base_1.RequiredError('organisationId', 'Required parameter organisationId was null or undefined when calling apiOrganisationOrganisationIdChildorgsGet.');
            }
            const localVarPath = `/api/Organisation/{organisationId}/childorgs`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve an organisations detail by ID
         * @summary Get Organisation
         * @param {string} organisationId The Organisation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdGet: (organisationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new base_1.RequiredError('organisationId', 'Required parameter organisationId was null or undefined when calling apiOrganisationOrganisationIdGet.');
            }
            const localVarPath = `/api/Organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get All Single Adviser Companies for a particular parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {string} [partialOrganisationName] Optional parameter for a partial part of the beginning of the organisation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdSingleadviserGet: (organisationId, partialOrganisationName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new base_1.RequiredError('organisationId', 'Required parameter organisationId was null or undefined when calling apiOrganisationOrganisationIdSingleadviserGet.');
            }
            const localVarPath = `/api/Organisation/{organisationId}/singleadviser`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (partialOrganisationName !== undefined) {
                localVarQueryParameter['partialOrganisationName'] = partialOrganisationName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get User Count and Users for Organisation
         * @param {string} organisationId Guid of the organisation
         * @param {number} [start] Start index of results for pagination purposes
         * @param {number} [count] The number of results to return.
         * @param {number} [filter] Filter for users activation status, 0 &#x3D; all, 1 &#x3D; active, 2 &#x3D; pending
         * @param {string} [search] Text field string to search for a specific for specific user(s)
         * @param {boolean} [sortDesc] Sort by FirstName Ascending or Descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdUsersGet: (organisationId, start, count, filter, search, sortDesc, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'organisationId' is not null or undefined
            if (organisationId === null || organisationId === undefined) {
                throw new base_1.RequiredError('organisationId', 'Required parameter organisationId was null or undefined when calling apiOrganisationOrganisationIdUsersGet.');
            }
            const localVarPath = `/api/Organisation/{organisationId}/users`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }
            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all Organisations/Firms for a particular platform
         * @param {string} [partialOrganisationName] Optional parameter of organisation name. This can be the whole name or  a few letters of the beginning of the organisation name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPlatformGet: (partialOrganisationName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Organisation/platform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (partialOrganisationName !== undefined) {
                localVarQueryParameter['partialOrganisationName'] = partialOrganisationName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.OrganisationApiAxiosParamCreator = OrganisationApiAxiosParamCreator;
/**
 * OrganisationApi - functional programming interface
 * @export
 */
const OrganisationApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Logo for Organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationLogoGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationLogoGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get All child organisations of parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdChildorgsGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationOrganisationIdChildorgsGet(organisationId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieve an organisations detail by ID
         * @summary Get Organisation
         * @param {string} organisationId The Organisation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationOrganisationIdGet(organisationId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get All Single Adviser Companies for a particular parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {string} [partialOrganisationName] Optional parameter for a partial part of the beginning of the organisation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get User Count and Users for Organisation
         * @param {string} organisationId Guid of the organisation
         * @param {number} [start] Start index of results for pagination purposes
         * @param {number} [count] The number of results to return.
         * @param {number} [filter] Filter for users activation status, 0 &#x3D; all, 1 &#x3D; active, 2 &#x3D; pending
         * @param {string} [search] Text field string to search for a specific for specific user(s)
         * @param {boolean} [sortDesc] Sort by FirstName Ascending or Descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get all Organisations/Firms for a particular platform
         * @param {string} [partialOrganisationName] Optional parameter of organisation name. This can be the whole name or  a few letters of the beginning of the organisation name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPlatformGet(partialOrganisationName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.OrganisationApiAxiosParamCreator)(configuration).apiOrganisationPlatformGet(partialOrganisationName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.OrganisationApiFp = OrganisationApiFp;
/**
 * OrganisationApi - factory interface
 * @export
 */
const OrganisationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get Logo for Organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationLogoGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationLogoGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get All child organisations of parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdChildorgsGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationOrganisationIdChildorgsGet(organisationId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieve an organisations detail by ID
         * @summary Get Organisation
         * @param {string} organisationId The Organisation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdGet(organisationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get All Single Adviser Companies for a particular parent organisation
         * @param {string} organisationId Guid of the parent organisation
         * @param {string} [partialOrganisationName] Optional parameter for a partial part of the beginning of the organisation name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get User Count and Users for Organisation
         * @param {string} organisationId Guid of the organisation
         * @param {number} [start] Start index of results for pagination purposes
         * @param {number} [count] The number of results to return.
         * @param {number} [filter] Filter for users activation status, 0 &#x3D; all, 1 &#x3D; active, 2 &#x3D; pending
         * @param {string} [search] Text field string to search for a specific for specific user(s)
         * @param {boolean} [sortDesc] Sort by FirstName Ascending or Descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get all Organisations/Firms for a particular platform
         * @param {string} [partialOrganisationName] Optional parameter of organisation name. This can be the whole name or  a few letters of the beginning of the organisation name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPlatformGet(partialOrganisationName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.OrganisationApiFp)(configuration).apiOrganisationPlatformGet(partialOrganisationName, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.OrganisationApiFactory = OrganisationApiFactory;
/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
class OrganisationApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get Logo for Organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationLogoGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationLogoGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get All child organisations of parent organisation
     * @param {string} organisationId Guid of the parent organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationOrganisationIdChildorgsGet(organisationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationOrganisationIdChildorgsGet(organisationId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieve an organisations detail by ID
     * @summary Get Organisation
     * @param {string} organisationId The Organisation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationOrganisationIdGet(organisationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get All Single Adviser Companies for a particular parent organisation
     * @param {string} organisationId Guid of the parent organisation
     * @param {string} [partialOrganisationName] Optional parameter for a partial part of the beginning of the organisation name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationOrganisationIdSingleadviserGet(organisationId, partialOrganisationName, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get User Count and Users for Organisation
     * @param {string} organisationId Guid of the organisation
     * @param {number} [start] Start index of results for pagination purposes
     * @param {number} [count] The number of results to return.
     * @param {number} [filter] Filter for users activation status, 0 &#x3D; all, 1 &#x3D; active, 2 &#x3D; pending
     * @param {string} [search] Text field string to search for a specific for specific user(s)
     * @param {boolean} [sortDesc] Sort by FirstName Ascending or Descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationOrganisationIdUsersGet(organisationId, start, count, filter, search, sortDesc, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get all Organisations/Firms for a particular platform
     * @param {string} [partialOrganisationName] Optional parameter of organisation name. This can be the whole name or  a few letters of the beginning of the organisation name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    apiOrganisationPlatformGet(partialOrganisationName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.OrganisationApiFp)(this.configuration).apiOrganisationPlatformGet(partialOrganisationName, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.OrganisationApi = OrganisationApi;
