import { useEffect, useState } from "react"
import { AxiosError } from "axios"

import {
  setLoadingState,
  setShowFooter,
  setShowMenu,
} from "../../contexts/application/action"
import { getRegistrationDetails } from "../../api/lib/auth/auth"

export const useRegistrationDetails = ({
  activationCode,
  dispatch,
}: {
  activationCode: string | null
  dispatch: (action: any) => void
}) => {
  const [email, setEmail] = useState("")
  const [lastFourPhoneDigits, setLastFourPhoneDigits] = useState("")
  const [shouldShowContactUsError, setShouldShowContactUsError] =
    useState(false)
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false)

  useEffect(() => {
    dispatch(setLoadingState(true))

    const fetchUserData = async () => {
      try {
        const response = await getRegistrationDetails(activationCode ?? "")
        if (response) {
          setEmail(response.email)
          setLastFourPhoneDigits(response.endOfPhoneNumber)
        }
      } catch (error) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status === 400) {
          if (axiosError.response?.data === "Expired") {
            setShouldShowContactUsError(true)
          }
          // User not found means that the code has been used already and the user is clicking again the link in the registration email they received.
          else if (axiosError.response?.data === "User not found") {
            setShouldRedirectToLogin(true)
          }
        }
      } finally {
        dispatch(setLoadingState(false))
      }
    }

    if (activationCode) {
      fetchUserData()
      dispatch(setShowMenu(false))
      dispatch(setShowFooter(false))
    }
  }, [activationCode, dispatch])
  return {
    email,
    lastFourPhoneDigits,
    shouldShowContactUsError,
    shouldRedirectToLogin,
  }
}
