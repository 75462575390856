"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectivityApi = exports.ConnectivityApiFactory = exports.ConnectivityApiFp = exports.ConnectivityApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * ConnectivityApi - axios parameter creator
 * @export
 */
const ConnectivityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get list of Folder Connections by current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityGetFolderConnectionStatusGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/GetFolderConnectionStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Industry list
         * @param {boolean} [onlyEnabled] Will only return industries that are enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityIndustriesGet: (onlyEnabled, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/industries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (onlyEnabled !== undefined) {
                localVarQueryParameter['onlyEnabled'] = onlyEnabled;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the Account Info for a particular linked account (via linked folder Id)
         * @summary Get Provider Account Info
         * @param {string} [folderId] The id of the Folder related provider account being checked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountAccountInfoGet: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providerAccount/accountInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Deletes a provider account for the user
         * @summary Delete Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDelete: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providerAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Disconnect a provider account for the user
         * @summary Disconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDisconnectPut: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providerAccount/disconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Adds a provider account for the user, by setting the credentials
         * @summary Add Provider Account
         * @param {ProviderAccountDto} [body] The account details to add
         * @param {string} [folderId] [Optional] Id of the existing folder for this provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountPost: (body, folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providerAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Reconnect a provider account for the user
         * @summary Reconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being reconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountReconnectPut: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providerAccount/reconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Provider List
         * @param {boolean} [onlyEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProvidersGet: (onlyEnabled, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (onlyEnabled !== undefined) {
                localVarQueryParameter['onlyEnabled'] = onlyEnabled;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set List of folder statuses as read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityReadStatusPut: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/readStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Adds a suggested provider from the \"Provider Not There?\" modal. If the name already exists in DB then the count is incremented instead.
         * @summary Add Suggested Provider
         * @param {SuggestedProviderDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivitySuggestedProviderPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Connectivity/suggestedProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ConnectivityApiAxiosParamCreator = ConnectivityApiAxiosParamCreator;
/**
 * ConnectivityApi - functional programming interface
 * @export
 */
const ConnectivityApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get list of Folder Connections by current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityGetFolderConnectionStatusGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityGetFolderConnectionStatusGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get Industry list
         * @param {boolean} [onlyEnabled] Will only return industries that are enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityIndustriesGet(onlyEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityIndustriesGet(onlyEnabled, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the Account Info for a particular linked account (via linked folder Id)
         * @summary Get Provider Account Info
         * @param {string} [folderId] The id of the Folder related provider account being checked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountAccountInfoGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProviderAccountAccountInfoGet(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Deletes a provider account for the user
         * @summary Delete Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDelete(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProviderAccountDelete(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Disconnect a provider account for the user
         * @summary Disconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDisconnectPut(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProviderAccountDisconnectPut(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Adds a provider account for the user, by setting the credentials
         * @summary Add Provider Account
         * @param {ProviderAccountDto} [body] The account details to add
         * @param {string} [folderId] [Optional] Id of the existing folder for this provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountPost(body, folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProviderAccountPost(body, folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Reconnect a provider account for the user
         * @summary Reconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being reconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountReconnectPut(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProviderAccountReconnectPut(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get Provider List
         * @param {boolean} [onlyEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProvidersGet(onlyEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityProvidersGet(onlyEnabled, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Set List of folder statuses as read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityReadStatusPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivityReadStatusPut(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Adds a suggested provider from the \"Provider Not There?\" modal. If the name already exists in DB then the count is incremented instead.
         * @summary Add Suggested Provider
         * @param {SuggestedProviderDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivitySuggestedProviderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ConnectivityApiAxiosParamCreator)(configuration).apiConnectivitySuggestedProviderPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.ConnectivityApiFp = ConnectivityApiFp;
/**
 * ConnectivityApi - factory interface
 * @export
 */
const ConnectivityApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get list of Folder Connections by current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityGetFolderConnectionStatusGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityGetFolderConnectionStatusGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get Industry list
         * @param {boolean} [onlyEnabled] Will only return industries that are enabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityIndustriesGet(onlyEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityIndustriesGet(onlyEnabled, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the Account Info for a particular linked account (via linked folder Id)
         * @summary Get Provider Account Info
         * @param {string} [folderId] The id of the Folder related provider account being checked
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountAccountInfoGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProviderAccountAccountInfoGet(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Deletes a provider account for the user
         * @summary Delete Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDelete(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProviderAccountDelete(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Disconnect a provider account for the user
         * @summary Disconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being disconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountDisconnectPut(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProviderAccountDisconnectPut(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Adds a provider account for the user, by setting the credentials
         * @summary Add Provider Account
         * @param {ProviderAccountDto} [body] The account details to add
         * @param {string} [folderId] [Optional] Id of the existing folder for this provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountPost(body, folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProviderAccountPost(body, folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Reconnect a provider account for the user
         * @summary Reconnect Provider Account
         * @param {string} [folderId] The id of the Folder related to the provider being reconnected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProviderAccountReconnectPut(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProviderAccountReconnectPut(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get Provider List
         * @param {boolean} [onlyEnabled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityProvidersGet(onlyEnabled, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityProvidersGet(onlyEnabled, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Set List of folder statuses as read
         * @param {Array<string>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivityReadStatusPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivityReadStatusPut(body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Adds a suggested provider from the \"Provider Not There?\" modal. If the name already exists in DB then the count is incremented instead.
         * @summary Add Suggested Provider
         * @param {SuggestedProviderDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConnectivitySuggestedProviderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ConnectivityApiFp)(configuration).apiConnectivitySuggestedProviderPost(body, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.ConnectivityApiFactory = ConnectivityApiFactory;
/**
 * ConnectivityApi - object-oriented interface
 * @export
 * @class ConnectivityApi
 * @extends {BaseAPI}
 */
class ConnectivityApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get list of Folder Connections by current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityGetFolderConnectionStatusGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityGetFolderConnectionStatusGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get Industry list
     * @param {boolean} [onlyEnabled] Will only return industries that are enabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityIndustriesGet(onlyEnabled, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityIndustriesGet(onlyEnabled, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the Account Info for a particular linked account (via linked folder Id)
     * @summary Get Provider Account Info
     * @param {string} [folderId] The id of the Folder related provider account being checked
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProviderAccountAccountInfoGet(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProviderAccountAccountInfoGet(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Deletes a provider account for the user
     * @summary Delete Provider Account
     * @param {string} [folderId] The id of the Folder related to the provider being deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProviderAccountDelete(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProviderAccountDelete(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Disconnect a provider account for the user
     * @summary Disconnect Provider Account
     * @param {string} [folderId] The id of the Folder related to the provider being disconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProviderAccountDisconnectPut(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProviderAccountDisconnectPut(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Adds a provider account for the user, by setting the credentials
     * @summary Add Provider Account
     * @param {ProviderAccountDto} [body] The account details to add
     * @param {string} [folderId] [Optional] Id of the existing folder for this provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProviderAccountPost(body, folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProviderAccountPost(body, folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Reconnect a provider account for the user
     * @summary Reconnect Provider Account
     * @param {string} [folderId] The id of the Folder related to the provider being reconnected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProviderAccountReconnectPut(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProviderAccountReconnectPut(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get Provider List
     * @param {boolean} [onlyEnabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityProvidersGet(onlyEnabled, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityProvidersGet(onlyEnabled, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Set List of folder statuses as read
     * @param {Array<string>} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivityReadStatusPut(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivityReadStatusPut(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Adds a suggested provider from the \"Provider Not There?\" modal. If the name already exists in DB then the count is incremented instead.
     * @summary Add Suggested Provider
     * @param {SuggestedProviderDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectivityApi
     */
    apiConnectivitySuggestedProviderPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ConnectivityApiFp)(this.configuration).apiConnectivitySuggestedProviderPost(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.ConnectivityApi = ConnectivityApi;
