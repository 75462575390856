"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeApi = exports.NodeApiFactory = exports.NodeApiFp = exports.NodeApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * NodeApi - axios parameter creator
 * @export
 */
const NodeApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Set the approval of a document to true
         * @summary Approves a File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeApprovefileFileIdPost: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeApprovefileFileIdPost.');
            }
            const localVarPath = `/api/Node/approvefile/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Add user tag to file's meta data. The tag can then be used for displaying/ sorting and searching
         * @summary Add Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileAddTagFileIdPut: (fileId, tagName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileAddTagFileIdPut.');
            }
            const localVarPath = `/api/Node/file/AddTag/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (tagName !== undefined) {
                localVarQueryParameter['tagName'] = tagName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Reviewing a file is the process of scanning the document for relevant dates and information. This is handled by the AI process of the system.   This method returns a flag indicating if the file is allowed to be send to the review process.
         * @summary Get File Can Be Reviewed
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileCanBeReviewedFileIdGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileCanBeReviewedFileIdGet.');
            }
            const localVarPath = `/api/Node/file/CanBeReviewed/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * When a document is scanned for dates and added to the metadata for the file, this method allows for the system or user to delete the date(s) if they are not needed.
         * @summary Delete Date
         * @param {string} fileId The id of the File
         * @param {string} [body] The date to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteDateFileIdPut: (fileId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileDeleteDateFileIdPut.');
            }
            const localVarPath = `/api/Node/file/DeleteDate/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete Organization from file  Both the ID and full organisation name must be supplied.This is to help ensure the correct organisation is being deleted.
         * @summary Delete Organization
         * @param {string} fileId The id of the File
         * @param {string} [body] The name of the Organization to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteOrganizationFileIdPut: (fileId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileDeleteOrganizationFileIdPut.');
            }
            const localVarPath = `/api/Node/file/DeleteOrganization/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete user tag from file
         * @summary Delete Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteTagFileIdPut: (fileId, tagName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileDeleteTagFileIdPut.');
            }
            const localVarPath = `/api/Node/file/DeleteTag/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (tagName !== undefined) {
                localVarQueryParameter['tagName'] = tagName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get a flat file by its ID
         * @summary Get Flat File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdFlatnodeGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileFileIdFlatnodeGet.');
            }
            const localVarPath = `/api/Node/file/{fileId}/flatnode`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Rename the specified file
         * @summary Rename a File
         * @param {string} fileId The id of the file to rename
         * @param {string} [body] The new name of the file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdRenamePut: (fileId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileFileIdRenamePut.');
            }
            const localVarPath = `/api/Node/file/{fileId}/rename`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get a file by its ID
         * @summary Get File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdTreenodeGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileFileIdTreenodeGet.');
            }
            const localVarPath = `/api/Node/file/{fileId}/treenode`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * The file coordinator manages the process of creating thumbnail and preview. Use this method to check if the thumbnail and preview have been generated.
         * @summary Get file coordinator status
         * @param {string} fileId The id of the file to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetFileCoordinatorStatusFileIdGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileGetFileCoordinatorStatusFileIdGet.');
            }
            const localVarPath = `/api/Node/file/GetFileCoordinatorStatus/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the preview PDF copy of the file which can be used to display within the browser. If the file is an MS Office document, it will have been converted to PDF when uploaded.
         * @summary Get File Preview
         * @param {string} fileId The id of the File
         * @param {boolean} [logPreview] Optional parameter, determines whether to log Preview Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetPreviewFileIdGet: (fileId, logPreview, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileGetPreviewFileIdGet.');
            }
            const localVarPath = `/api/Node/file/GetPreview/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (logPreview !== undefined) {
                localVarQueryParameter['logPreview'] = logPreview;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the tags that have been added to the file meta data from the system or by the user.
         * @summary Get Tags
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetTagsFileIdGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileGetTagsFileIdGet.');
            }
            const localVarPath = `/api/Node/file/GetTags/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves the thumbnail image of the first page of the document if one was generated
         * @summary Get Thumbnail
         * @param {string} fileId The id of the File
         * @param {boolean} [fullSize] Optional. If true return full size thumbnail, else the small version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetThumbnailFileIdGet: (fileId, fullSize, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileGetThumbnailFileIdGet.');
            }
            const localVarPath = `/api/Node/file/GetThumbnail/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (fullSize !== undefined) {
                localVarQueryParameter['fullSize'] = fullSize;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the flag showing whether file has been be reviewed or not
         * @summary Get File Has Been Reviewed flag
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileHasBeenReviewedFileIdGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileHasBeenReviewedFileIdGet.');
            }
            const localVarPath = `/api/Node/file/HasBeenReviewed/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Download the file with the specified file Id
         * @summary Download file
         * @param {string} fileId The unique ID of the file to download
         * @param {boolean} [logDownload] Flag to indicate that the download action should be logged. The default is True
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdDownloadGet: (fileId, logDownload, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileIdDownloadGet.');
            }
            const localVarPath = `/api/Node/{fileId}/download`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (logDownload !== undefined) {
                localVarQueryParameter['logDownload'] = logDownload;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets a list of all versions for a single file
         * @summary Get the version history list for a file
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdGetVersionsListGet: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileIdGetVersionsListGet.');
            }
            const localVarPath = `/api/Node/{fileId}/GetVersionsList`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Deletes the specified file. The file is marked as deleted but not deleted from the system.
         * @summary Delete File
         * @param {string} nodeId The id of the file to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileNodeIdDelete: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeFileNodeIdDelete.');
            }
            const localVarPath = `/api/Node/file/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get the most recent files marked as important for a user
         * @param {string} userId Id of the user
         * @param {number} [amount] (optional) Max amount of recent files to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRecentimportantUserIdGet: (userId, amount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling apiNodeFileRecentimportantUserIdGet.');
            }
            const localVarPath = `/api/Node/file/recentimportant/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Restore a file that has been marked for deletion. When a file is deleted, it is only tags are deleted. This function removes that tag.
         * @summary Restore File
         * @param {string} nodeId The id of the file to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRestoreNodeIdPost: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeFileRestoreNodeIdPost.');
            }
            const localVarPath = `/api/Node/file/restore/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Triggers she file review process that extracts key data from the file and stores it as the meta data for the file.
         * @summary Trigger File Review
         * @param {string} fileId The id of the File
         * @param {boolean} [forceReview] Flag to force a review, regardless of the file&#x27;s flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileTriggerFileReviewFileIdPost: (fileId, forceReview, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeFileTriggerFileReviewFileIdPost.');
            }
            const localVarPath = `/api/Node/file/TriggerFileReview/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (forceReview !== undefined) {
                localVarQueryParameter['forceReview'] = forceReview;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieves a list of files currently in the folder
         * @param {string} folderId Id of folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdContentsGet: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError('folderId', 'Required parameter folderId was null or undefined when calling apiNodeFolderFolderIdContentsGet.');
            }
            const localVarPath = `/api/Node/folder/{folderId}/contents`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get a folder by its ID
         * @summary Get Folder by ID
         * @param {string} folderId The folder id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdGet: (folderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError('folderId', 'Required parameter folderId was null or undefined when calling apiNodeFolderFolderIdGet.');
            }
            const localVarPath = `/api/Node/folder/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Rename the specified folder
         * @summary Rename Folder
         * @param {string} folderId The id of the folder to rename
         * @param {string} [body] The new name for the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdRenamePut: (folderId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError('folderId', 'Required parameter folderId was null or undefined when calling apiNodeFolderFolderIdRenamePut.');
            }
            const localVarPath = `/api/Node/folder/{folderId}/rename`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a file or multiple files to the specified folder
         * @summary Upload file(s)
         * @param {string} folderId The id of the folder to upload the file to.
         * @param {boolean} [versions] Flag to indicate whether the file can have versioning
         * @param {boolean} [commenting] Flag to indicate whether the file can have commenting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderIdUploadPost: (folderId, versions, commenting, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError('folderId', 'Required parameter folderId was null or undefined when calling apiNodeFolderIdUploadPost.');
            }
            const localVarPath = `/api/Node/{folderId}/upload`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (versions !== undefined) {
                localVarQueryParameter['versions'] = versions;
            }
            if (commenting !== undefined) {
                localVarQueryParameter['commenting'] = commenting;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete the folder.  **NOTE** This function will delete the folder and all child folders and files along with any reminders that have been set up for these folders and files.The function will only delete the folder and files if there is permission to do so.  As with file deletes, the folders and files are marked for delete rather than physically deleted off the system
         * @summary Delete Folder
         * @param {string} nodeId The id of the Folder to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderNodeIdDelete: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeFolderNodeIdDelete.');
            }
            const localVarPath = `/api/Node/Folder/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Creates a folder according to the information passed in the \"CreateFolderDTO\".  If creating Custom folders, it will need to have a valid ParentId to place it within the hierarchy, however if no parentID passed in then the folder will be created at the root level.  DefaultStructure Folders need to be placed at the correct path.If DefaultStructureFolder is passed without a name, then it will be set to default name.
         * @summary Create Folder
         * @param {CreateFolderDto} [body] Contains ParentId, name and type. ParentId could be null to create at root level. The name could be null if DefaultFolderStructure and type defaults to Client Custom Folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Restore a folder and its contents that were deleted.
         * @summary Restore Folder
         * @param {string} nodeId The id of the folder to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderRestoreNodeIdPost: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeFolderRestoreNodeIdPost.');
            }
            const localVarPath = `/api/Node/folder/restore/{nodeId}`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets all the tags for every file related to a user
         * @summary Gets Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetAllTagsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/GetAllTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the flattened node list for a current user.
         * @summary Get Flat File List
         * @param {boolean} [legadoOnly] Flag to indicate whether to include only legado files or not
         * @param {number} [refresh] Set this to true to override the cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetFlatFileListGet: (legadoOnly, refresh, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/GetFlatFileList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (legadoOnly !== undefined) {
                localVarQueryParameter['legadoOnly'] = legadoOnly;
            }
            if (refresh !== undefined) {
                localVarQueryParameter['refresh'] = refresh;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the node list of a vault for the current user.
         * @summary Get Node List
         * @param {boolean} [contents] Flag to indicate whether to include folder contents or just the folder list
         * @param {number} [levels] For the number of levels to return, use &#x27;0&#x27; for all levels
         * @param {boolean} [legadoOnly] Flag to indicate that only Legado files are included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetNodeListGet: (contents, levels, legadoOnly, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/GetNodeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (contents !== undefined) {
                localVarQueryParameter['contents'] = contents;
            }
            if (levels !== undefined) {
                localVarQueryParameter['levels'] = levels;
            }
            if (legadoOnly !== undefined) {
                localVarQueryParameter['legadoOnly'] = legadoOnly;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns the Node's owner object.
         * @summary Get Node Owner
         * @param {string} nodeId The node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdOwnerGet: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeNodeIdOwnerGet.');
            }
            const localVarPath = `/api/Node/{nodeId}/owner`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *  Sets the \"pinned flag\" for a file or folder..  This can be used to show a list of pinned files or folders in the users app.
         * @summary Set Pin
         * @param {string} nodeId The id for the node
         * @param {boolean} pin The flag to set pinned as True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdPinPut: (nodeId, pin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiNodeNodeIdPinPut.');
            }
            // verify required parameter 'pin' is not null or undefined
            if (pin === null || pin === undefined) {
                throw new base_1.RequiredError('pin', 'Required parameter pin was null or undefined when calling apiNodeNodeIdPinPut.');
            }
            const localVarPath = `/api/Node/{nodeId}/pin`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (pin !== undefined) {
                localVarQueryParameter['pin'] = pin;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Stitches together existing images into a single PDF
         * @summary Pdf stitching for a series of files
         * @param {PdfStitchingInfoDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePdfstitchingPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/pdfstitching`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves a list of pinned nodes for the logged in user.
         * @summary Get Pinned Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePinnedGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/pinned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves a list of suggested folders that the user can select from.
         * @summary Get suggested folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeSuggestedFoldersGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Node/SuggestedFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Replace an Existing file with an attached file
         * @summary Replace Exsiting File
         * @param {string} fileId The id of the file to be replaced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeUploadFileIdPatch: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiNodeUploadFileIdPatch.');
            }
            const localVarPath = `/api/Node/upload/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.NodeApiAxiosParamCreator = NodeApiAxiosParamCreator;
/**
 * NodeApi - functional programming interface
 * @export
 */
const NodeApiFp = function (configuration) {
    return {
        /**
         * Set the approval of a document to true
         * @summary Approves a File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeApprovefileFileIdPost(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeApprovefileFileIdPost(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Add user tag to file's meta data. The tag can then be used for displaying/ sorting and searching
         * @summary Add Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileAddTagFileIdPut(fileId, tagName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileAddTagFileIdPut(fileId, tagName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Reviewing a file is the process of scanning the document for relevant dates and information. This is handled by the AI process of the system.   This method returns a flag indicating if the file is allowed to be send to the review process.
         * @summary Get File Can Be Reviewed
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileCanBeReviewedFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileCanBeReviewedFileIdGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * When a document is scanned for dates and added to the metadata for the file, this method allows for the system or user to delete the date(s) if they are not needed.
         * @summary Delete Date
         * @param {string} fileId The id of the File
         * @param {string} [body] The date to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteDateFileIdPut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileDeleteDateFileIdPut(fileId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Delete Organization from file  Both the ID and full organisation name must be supplied.This is to help ensure the correct organisation is being deleted.
         * @summary Delete Organization
         * @param {string} fileId The id of the File
         * @param {string} [body] The name of the Organization to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Delete user tag from file
         * @summary Delete Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteTagFileIdPut(fileId, tagName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileDeleteTagFileIdPut(fileId, tagName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get a flat file by its ID
         * @summary Get Flat File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdFlatnodeGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileFileIdFlatnodeGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Rename the specified file
         * @summary Rename a File
         * @param {string} fileId The id of the file to rename
         * @param {string} [body] The new name of the file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdRenamePut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileFileIdRenamePut(fileId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get a file by its ID
         * @summary Get File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdTreenodeGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileFileIdTreenodeGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * The file coordinator manages the process of creating thumbnail and preview. Use this method to check if the thumbnail and preview have been generated.
         * @summary Get file coordinator status
         * @param {string} fileId The id of the file to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the preview PDF copy of the file which can be used to display within the browser. If the file is an MS Office document, it will have been converted to PDF when uploaded.
         * @summary Get File Preview
         * @param {string} fileId The id of the File
         * @param {boolean} [logPreview] Optional parameter, determines whether to log Preview Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the tags that have been added to the file meta data from the system or by the user.
         * @summary Get Tags
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetTagsFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileGetTagsFileIdGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieves the thumbnail image of the first page of the document if one was generated
         * @summary Get Thumbnail
         * @param {string} fileId The id of the File
         * @param {boolean} [fullSize] Optional. If true return full size thumbnail, else the small version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the flag showing whether file has been be reviewed or not
         * @summary Get File Has Been Reviewed flag
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileHasBeenReviewedFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileHasBeenReviewedFileIdGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Download the file with the specified file Id
         * @summary Download file
         * @param {string} fileId The unique ID of the file to download
         * @param {boolean} [logDownload] Flag to indicate that the download action should be logged. The default is True
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdDownloadGet(fileId, logDownload, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileIdDownloadGet(fileId, logDownload, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets a list of all versions for a single file
         * @summary Get the version history list for a file
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdGetVersionsListGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileIdGetVersionsListGet(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Deletes the specified file. The file is marked as deleted but not deleted from the system.
         * @summary Delete File
         * @param {string} nodeId The id of the file to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileNodeIdDelete(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileNodeIdDelete(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get the most recent files marked as important for a user
         * @param {string} userId Id of the user
         * @param {number} [amount] (optional) Max amount of recent files to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRecentimportantUserIdGet(userId, amount, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileRecentimportantUserIdGet(userId, amount, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Restore a file that has been marked for deletion. When a file is deleted, it is only tags are deleted. This function removes that tag.
         * @summary Restore File
         * @param {string} nodeId The id of the file to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRestoreNodeIdPost(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileRestoreNodeIdPost(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Triggers she file review process that extracts key data from the file and stores it as the meta data for the file.
         * @summary Trigger File Review
         * @param {string} fileId The id of the File
         * @param {boolean} [forceReview] Flag to force a review, regardless of the file&#x27;s flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Retrieves a list of files currently in the folder
         * @param {string} folderId Id of folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdContentsGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderFolderIdContentsGet(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get a folder by its ID
         * @summary Get Folder by ID
         * @param {string} folderId The folder id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderFolderIdGet(folderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Rename the specified folder
         * @summary Rename Folder
         * @param {string} folderId The id of the folder to rename
         * @param {string} [body] The new name for the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdRenamePut(folderId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderFolderIdRenamePut(folderId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a file or multiple files to the specified folder
         * @summary Upload file(s)
         * @param {string} folderId The id of the folder to upload the file to.
         * @param {boolean} [versions] Flag to indicate whether the file can have versioning
         * @param {boolean} [commenting] Flag to indicate whether the file can have commenting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderIdUploadPost(folderId, versions, commenting, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderIdUploadPost(folderId, versions, commenting, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Delete the folder.  **NOTE** This function will delete the folder and all child folders and files along with any reminders that have been set up for these folders and files.The function will only delete the folder and files if there is permission to do so.  As with file deletes, the folders and files are marked for delete rather than physically deleted off the system
         * @summary Delete Folder
         * @param {string} nodeId The id of the Folder to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderNodeIdDelete(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderNodeIdDelete(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Creates a folder according to the information passed in the \"CreateFolderDTO\".  If creating Custom folders, it will need to have a valid ParentId to place it within the hierarchy, however if no parentID passed in then the folder will be created at the root level.  DefaultStructure Folders need to be placed at the correct path.If DefaultStructureFolder is passed without a name, then it will be set to default name.
         * @summary Create Folder
         * @param {CreateFolderDto} [body] Contains ParentId, name and type. ParentId could be null to create at root level. The name could be null if DefaultFolderStructure and type defaults to Client Custom Folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Restore a folder and its contents that were deleted.
         * @summary Restore Folder
         * @param {string} nodeId The id of the folder to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderRestoreNodeIdPost(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeFolderRestoreNodeIdPost(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets all the tags for every file related to a user
         * @summary Gets Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetAllTagsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeGetAllTagsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the flattened node list for a current user.
         * @summary Get Flat File List
         * @param {boolean} [legadoOnly] Flag to indicate whether to include only legado files or not
         * @param {number} [refresh] Set this to true to override the cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetFlatFileListGet(legadoOnly, refresh, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeGetFlatFileListGet(legadoOnly, refresh, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the node list of a vault for the current user.
         * @summary Get Node List
         * @param {boolean} [contents] Flag to indicate whether to include folder contents or just the folder list
         * @param {number} [levels] For the number of levels to return, use &#x27;0&#x27; for all levels
         * @param {boolean} [legadoOnly] Flag to indicate that only Legado files are included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetNodeListGet(contents, levels, legadoOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeGetNodeListGet(contents, levels, legadoOnly, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Returns the Node's owner object.
         * @summary Get Node Owner
         * @param {string} nodeId The node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdOwnerGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeNodeIdOwnerGet(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *  Sets the \"pinned flag\" for a file or folder..  This can be used to show a list of pinned files or folders in the users app.
         * @summary Set Pin
         * @param {string} nodeId The id for the node
         * @param {boolean} pin The flag to set pinned as True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdPinPut(nodeId, pin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeNodeIdPinPut(nodeId, pin, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Stitches together existing images into a single PDF
         * @summary Pdf stitching for a series of files
         * @param {PdfStitchingInfoDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePdfstitchingPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodePdfstitchingPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieves a list of pinned nodes for the logged in user.
         * @summary Get Pinned Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePinnedGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodePinnedGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Retrieves a list of suggested folders that the user can select from.
         * @summary Get suggested folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeSuggestedFoldersGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeSuggestedFoldersGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Replace an Existing file with an attached file
         * @summary Replace Exsiting File
         * @param {string} fileId The id of the file to be replaced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeUploadFileIdPatch(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.NodeApiAxiosParamCreator)(configuration).apiNodeUploadFileIdPatch(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.NodeApiFp = NodeApiFp;
/**
 * NodeApi - factory interface
 * @export
 */
const NodeApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Set the approval of a document to true
         * @summary Approves a File
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeApprovefileFileIdPost(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeApprovefileFileIdPost(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Add user tag to file's meta data. The tag can then be used for displaying/ sorting and searching
         * @summary Add Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileAddTagFileIdPut(fileId, tagName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileAddTagFileIdPut(fileId, tagName, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Reviewing a file is the process of scanning the document for relevant dates and information. This is handled by the AI process of the system.   This method returns a flag indicating if the file is allowed to be send to the review process.
         * @summary Get File Can Be Reviewed
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileCanBeReviewedFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileCanBeReviewedFileIdGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * When a document is scanned for dates and added to the metadata for the file, this method allows for the system or user to delete the date(s) if they are not needed.
         * @summary Delete Date
         * @param {string} fileId The id of the File
         * @param {string} [body] The date to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteDateFileIdPut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileDeleteDateFileIdPut(fileId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Delete Organization from file  Both the ID and full organisation name must be supplied.This is to help ensure the correct organisation is being deleted.
         * @summary Delete Organization
         * @param {string} fileId The id of the File
         * @param {string} [body] The name of the Organization to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Delete user tag from file
         * @summary Delete Tag
         * @param {string} fileId The id of the File
         * @param {string} [tagName] The name of the tag to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileDeleteTagFileIdPut(fileId, tagName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileDeleteTagFileIdPut(fileId, tagName, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get a flat file by its ID
         * @summary Get Flat File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdFlatnodeGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileFileIdFlatnodeGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Rename the specified file
         * @summary Rename a File
         * @param {string} fileId The id of the file to rename
         * @param {string} [body] The new name of the file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdRenamePut(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileFileIdRenamePut(fileId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get a file by its ID
         * @summary Get File by ID
         * @param {string} fileId The file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileFileIdTreenodeGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileFileIdTreenodeGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * The file coordinator manages the process of creating thumbnail and preview. Use this method to check if the thumbnail and preview have been generated.
         * @summary Get file coordinator status
         * @param {string} fileId The id of the file to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the preview PDF copy of the file which can be used to display within the browser. If the file is an MS Office document, it will have been converted to PDF when uploaded.
         * @summary Get File Preview
         * @param {string} fileId The id of the File
         * @param {boolean} [logPreview] Optional parameter, determines whether to log Preview Activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the tags that have been added to the file meta data from the system or by the user.
         * @summary Get Tags
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetTagsFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileGetTagsFileIdGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieves the thumbnail image of the first page of the document if one was generated
         * @summary Get Thumbnail
         * @param {string} fileId The id of the File
         * @param {boolean} [fullSize] Optional. If true return full size thumbnail, else the small version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the flag showing whether file has been be reviewed or not
         * @summary Get File Has Been Reviewed flag
         * @param {string} fileId The id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileHasBeenReviewedFileIdGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileHasBeenReviewedFileIdGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Download the file with the specified file Id
         * @summary Download file
         * @param {string} fileId The unique ID of the file to download
         * @param {boolean} [logDownload] Flag to indicate that the download action should be logged. The default is True
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdDownloadGet(fileId, logDownload, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileIdDownloadGet(fileId, logDownload, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets a list of all versions for a single file
         * @summary Get the version history list for a file
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileIdGetVersionsListGet(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileIdGetVersionsListGet(fileId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Deletes the specified file. The file is marked as deleted but not deleted from the system.
         * @summary Delete File
         * @param {string} nodeId The id of the file to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileNodeIdDelete(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileNodeIdDelete(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get the most recent files marked as important for a user
         * @param {string} userId Id of the user
         * @param {number} [amount] (optional) Max amount of recent files to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRecentimportantUserIdGet(userId, amount, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileRecentimportantUserIdGet(userId, amount, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Restore a file that has been marked for deletion. When a file is deleted, it is only tags are deleted. This function removes that tag.
         * @summary Restore File
         * @param {string} nodeId The id of the file to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileRestoreNodeIdPost(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileRestoreNodeIdPost(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Triggers she file review process that extracts key data from the file and stores it as the meta data for the file.
         * @summary Trigger File Review
         * @param {string} fileId The id of the File
         * @param {boolean} [forceReview] Flag to force a review, regardless of the file&#x27;s flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Retrieves a list of files currently in the folder
         * @param {string} folderId Id of folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdContentsGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderFolderIdContentsGet(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get a folder by its ID
         * @summary Get Folder by ID
         * @param {string} folderId The folder id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdGet(folderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderFolderIdGet(folderId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Rename the specified folder
         * @summary Rename Folder
         * @param {string} folderId The id of the folder to rename
         * @param {string} [body] The new name for the folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderFolderIdRenamePut(folderId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderFolderIdRenamePut(folderId, body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a file or multiple files to the specified folder
         * @summary Upload file(s)
         * @param {string} folderId The id of the folder to upload the file to.
         * @param {boolean} [versions] Flag to indicate whether the file can have versioning
         * @param {boolean} [commenting] Flag to indicate whether the file can have commenting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderIdUploadPost(folderId, versions, commenting, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderIdUploadPost(folderId, versions, commenting, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Delete the folder.  **NOTE** This function will delete the folder and all child folders and files along with any reminders that have been set up for these folders and files.The function will only delete the folder and files if there is permission to do so.  As with file deletes, the folders and files are marked for delete rather than physically deleted off the system
         * @summary Delete Folder
         * @param {string} nodeId The id of the Folder to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderNodeIdDelete(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderNodeIdDelete(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Creates a folder according to the information passed in the \"CreateFolderDTO\".  If creating Custom folders, it will need to have a valid ParentId to place it within the hierarchy, however if no parentID passed in then the folder will be created at the root level.  DefaultStructure Folders need to be placed at the correct path.If DefaultStructureFolder is passed without a name, then it will be set to default name.
         * @summary Create Folder
         * @param {CreateFolderDto} [body] Contains ParentId, name and type. ParentId could be null to create at root level. The name could be null if DefaultFolderStructure and type defaults to Client Custom Folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderPost(body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Restore a folder and its contents that were deleted.
         * @summary Restore Folder
         * @param {string} nodeId The id of the folder to restore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeFolderRestoreNodeIdPost(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeFolderRestoreNodeIdPost(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets all the tags for every file related to a user
         * @summary Gets Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetAllTagsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeGetAllTagsGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the flattened node list for a current user.
         * @summary Get Flat File List
         * @param {boolean} [legadoOnly] Flag to indicate whether to include only legado files or not
         * @param {number} [refresh] Set this to true to override the cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetFlatFileListGet(legadoOnly, refresh, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeGetFlatFileListGet(legadoOnly, refresh, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the node list of a vault for the current user.
         * @summary Get Node List
         * @param {boolean} [contents] Flag to indicate whether to include folder contents or just the folder list
         * @param {number} [levels] For the number of levels to return, use &#x27;0&#x27; for all levels
         * @param {boolean} [legadoOnly] Flag to indicate that only Legado files are included.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeGetNodeListGet(contents, levels, legadoOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeGetNodeListGet(contents, levels, legadoOnly, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Returns the Node's owner object.
         * @summary Get Node Owner
         * @param {string} nodeId The node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdOwnerGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeNodeIdOwnerGet(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         *  Sets the \"pinned flag\" for a file or folder..  This can be used to show a list of pinned files or folders in the users app.
         * @summary Set Pin
         * @param {string} nodeId The id for the node
         * @param {boolean} pin The flag to set pinned as True/False
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeNodeIdPinPut(nodeId, pin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeNodeIdPinPut(nodeId, pin, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Stitches together existing images into a single PDF
         * @summary Pdf stitching for a series of files
         * @param {PdfStitchingInfoDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePdfstitchingPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodePdfstitchingPost(body, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieves a list of pinned nodes for the logged in user.
         * @summary Get Pinned Nodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodePinnedGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodePinnedGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Retrieves a list of suggested folders that the user can select from.
         * @summary Get suggested folders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeSuggestedFoldersGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeSuggestedFoldersGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Replace an Existing file with an attached file
         * @summary Replace Exsiting File
         * @param {string} fileId The id of the file to be replaced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNodeUploadFileIdPatch(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.NodeApiFp)(configuration).apiNodeUploadFileIdPatch(fileId, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.NodeApiFactory = NodeApiFactory;
/**
 * NodeApi - object-oriented interface
 * @export
 * @class NodeApi
 * @extends {BaseAPI}
 */
class NodeApi extends base_1.BaseAPI {
    /**
     * Set the approval of a document to true
     * @summary Approves a File
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeApprovefileFileIdPost(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeApprovefileFileIdPost(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Add user tag to file's meta data. The tag can then be used for displaying/ sorting and searching
     * @summary Add Tag
     * @param {string} fileId The id of the File
     * @param {string} [tagName] The name of the tag to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileAddTagFileIdPut(fileId, tagName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileAddTagFileIdPut(fileId, tagName, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Reviewing a file is the process of scanning the document for relevant dates and information. This is handled by the AI process of the system.   This method returns a flag indicating if the file is allowed to be send to the review process.
     * @summary Get File Can Be Reviewed
     * @param {string} fileId The id of the File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileCanBeReviewedFileIdGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileCanBeReviewedFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * When a document is scanned for dates and added to the metadata for the file, this method allows for the system or user to delete the date(s) if they are not needed.
     * @summary Delete Date
     * @param {string} fileId The id of the File
     * @param {string} [body] The date to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileDeleteDateFileIdPut(fileId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileDeleteDateFileIdPut(fileId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Delete Organization from file  Both the ID and full organisation name must be supplied.This is to help ensure the correct organisation is being deleted.
     * @summary Delete Organization
     * @param {string} fileId The id of the File
     * @param {string} [body] The name of the Organization to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileDeleteOrganizationFileIdPut(fileId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Delete user tag from file
     * @summary Delete Tag
     * @param {string} fileId The id of the File
     * @param {string} [tagName] The name of the tag to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileDeleteTagFileIdPut(fileId, tagName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileDeleteTagFileIdPut(fileId, tagName, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get a flat file by its ID
     * @summary Get Flat File by ID
     * @param {string} fileId The file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileFileIdFlatnodeGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileFileIdFlatnodeGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Rename the specified file
     * @summary Rename a File
     * @param {string} fileId The id of the file to rename
     * @param {string} [body] The new name of the file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileFileIdRenamePut(fileId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileFileIdRenamePut(fileId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get a file by its ID
     * @summary Get File by ID
     * @param {string} fileId The file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileFileIdTreenodeGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileFileIdTreenodeGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * The file coordinator manages the process of creating thumbnail and preview. Use this method to check if the thumbnail and preview have been generated.
     * @summary Get file coordinator status
     * @param {string} fileId The id of the file to query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileGetFileCoordinatorStatusFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the preview PDF copy of the file which can be used to display within the browser. If the file is an MS Office document, it will have been converted to PDF when uploaded.
     * @summary Get File Preview
     * @param {string} fileId The id of the File
     * @param {boolean} [logPreview] Optional parameter, determines whether to log Preview Activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileGetPreviewFileIdGet(fileId, logPreview, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the tags that have been added to the file meta data from the system or by the user.
     * @summary Get Tags
     * @param {string} fileId The id of the File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileGetTagsFileIdGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileGetTagsFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieves the thumbnail image of the first page of the document if one was generated
     * @summary Get Thumbnail
     * @param {string} fileId The id of the File
     * @param {boolean} [fullSize] Optional. If true return full size thumbnail, else the small version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileGetThumbnailFileIdGet(fileId, fullSize, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the flag showing whether file has been be reviewed or not
     * @summary Get File Has Been Reviewed flag
     * @param {string} fileId The id of the File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileHasBeenReviewedFileIdGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileHasBeenReviewedFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Download the file with the specified file Id
     * @summary Download file
     * @param {string} fileId The unique ID of the file to download
     * @param {boolean} [logDownload] Flag to indicate that the download action should be logged. The default is True
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileIdDownloadGet(fileId, logDownload, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileIdDownloadGet(fileId, logDownload, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets a list of all versions for a single file
     * @summary Get the version history list for a file
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileIdGetVersionsListGet(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileIdGetVersionsListGet(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Deletes the specified file. The file is marked as deleted but not deleted from the system.
     * @summary Delete File
     * @param {string} nodeId The id of the file to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileNodeIdDelete(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileNodeIdDelete(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get the most recent files marked as important for a user
     * @param {string} userId Id of the user
     * @param {number} [amount] (optional) Max amount of recent files to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileRecentimportantUserIdGet(userId, amount, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileRecentimportantUserIdGet(userId, amount, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Restore a file that has been marked for deletion. When a file is deleted, it is only tags are deleted. This function removes that tag.
     * @summary Restore File
     * @param {string} nodeId The id of the file to restore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileRestoreNodeIdPost(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileRestoreNodeIdPost(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Triggers she file review process that extracts key data from the file and stores it as the meta data for the file.
     * @summary Trigger File Review
     * @param {string} fileId The id of the File
     * @param {boolean} [forceReview] Flag to force a review, regardless of the file&#x27;s flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFileTriggerFileReviewFileIdPost(fileId, forceReview, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Retrieves a list of files currently in the folder
     * @param {string} folderId Id of folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderFolderIdContentsGet(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderFolderIdContentsGet(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get a folder by its ID
     * @summary Get Folder by ID
     * @param {string} folderId The folder id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderFolderIdGet(folderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderFolderIdGet(folderId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Rename the specified folder
     * @summary Rename Folder
     * @param {string} folderId The id of the folder to rename
     * @param {string} [body] The new name for the folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderFolderIdRenamePut(folderId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderFolderIdRenamePut(folderId, body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a file or multiple files to the specified folder
     * @summary Upload file(s)
     * @param {string} folderId The id of the folder to upload the file to.
     * @param {boolean} [versions] Flag to indicate whether the file can have versioning
     * @param {boolean} [commenting] Flag to indicate whether the file can have commenting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderIdUploadPost(folderId, versions, commenting, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderIdUploadPost(folderId, versions, commenting, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Delete the folder.  **NOTE** This function will delete the folder and all child folders and files along with any reminders that have been set up for these folders and files.The function will only delete the folder and files if there is permission to do so.  As with file deletes, the folders and files are marked for delete rather than physically deleted off the system
     * @summary Delete Folder
     * @param {string} nodeId The id of the Folder to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderNodeIdDelete(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderNodeIdDelete(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Creates a folder according to the information passed in the \"CreateFolderDTO\".  If creating Custom folders, it will need to have a valid ParentId to place it within the hierarchy, however if no parentID passed in then the folder will be created at the root level.  DefaultStructure Folders need to be placed at the correct path.If DefaultStructureFolder is passed without a name, then it will be set to default name.
     * @summary Create Folder
     * @param {CreateFolderDto} [body] Contains ParentId, name and type. ParentId could be null to create at root level. The name could be null if DefaultFolderStructure and type defaults to Client Custom Folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderPost(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Restore a folder and its contents that were deleted.
     * @summary Restore Folder
     * @param {string} nodeId The id of the folder to restore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeFolderRestoreNodeIdPost(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeFolderRestoreNodeIdPost(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets all the tags for every file related to a user
     * @summary Gets Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeGetAllTagsGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeGetAllTagsGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the flattened node list for a current user.
     * @summary Get Flat File List
     * @param {boolean} [legadoOnly] Flag to indicate whether to include only legado files or not
     * @param {number} [refresh] Set this to true to override the cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeGetFlatFileListGet(legadoOnly, refresh, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeGetFlatFileListGet(legadoOnly, refresh, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the node list of a vault for the current user.
     * @summary Get Node List
     * @param {boolean} [contents] Flag to indicate whether to include folder contents or just the folder list
     * @param {number} [levels] For the number of levels to return, use &#x27;0&#x27; for all levels
     * @param {boolean} [legadoOnly] Flag to indicate that only Legado files are included.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeGetNodeListGet(contents, levels, legadoOnly, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeGetNodeListGet(contents, levels, legadoOnly, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Returns the Node's owner object.
     * @summary Get Node Owner
     * @param {string} nodeId The node id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeNodeIdOwnerGet(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeNodeIdOwnerGet(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *  Sets the \"pinned flag\" for a file or folder..  This can be used to show a list of pinned files or folders in the users app.
     * @summary Set Pin
     * @param {string} nodeId The id for the node
     * @param {boolean} pin The flag to set pinned as True/False
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeNodeIdPinPut(nodeId, pin, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeNodeIdPinPut(nodeId, pin, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Stitches together existing images into a single PDF
     * @summary Pdf stitching for a series of files
     * @param {PdfStitchingInfoDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodePdfstitchingPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodePdfstitchingPost(body, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieves a list of pinned nodes for the logged in user.
     * @summary Get Pinned Nodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodePinnedGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodePinnedGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Retrieves a list of suggested folders that the user can select from.
     * @summary Get suggested folders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeSuggestedFoldersGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeSuggestedFoldersGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Replace an Existing file with an attached file
     * @summary Replace Exsiting File
     * @param {string} fileId The id of the file to be replaced
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeApi
     */
    apiNodeUploadFileIdPatch(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.NodeApiFp)(this.configuration).apiNodeUploadFileIdPatch(fileId, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.NodeApi = NodeApi;
