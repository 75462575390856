"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityApi = exports.ActivityApiFactory = exports.ActivityApiFp = exports.ActivityApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * ActivityApi - axios parameter creator
 * @export
 */
const ActivityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get the list of all activities for the current user. This function is paginated using the start and count parameters.
         * @summary All Activities
         * @param {number} [start] The position to start the data from for pagination
         * @param {number} [count] The number of records to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityAllGet: (start, count, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Activity/All`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }
            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the activity log for a node
         * @summary Activity Log
         * @param {string} nodeId Node Id of the requested activity log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityNodeIdLogGet: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError('nodeId', 'Required parameter nodeId was null or undefined when calling apiActivityNodeIdLogGet.');
            }
            const localVarPath = `/api/Activity/{nodeId}/log`
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the list of recent changes to the vault *since the last time they logged in*.  These activities will include :-                1. Changes to documents (added, deleted etc)  2. Shares (new, altered or deleted)  3. Any notification that the user would have gotten.
         * @summary Recent Changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityRecentGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Activity/Recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ActivityApiAxiosParamCreator = ActivityApiAxiosParamCreator;
/**
 * ActivityApi - functional programming interface
 * @export
 */
const ActivityApiFp = function (configuration) {
    return {
        /**
         * Get the list of all activities for the current user. This function is paginated using the start and count parameters.
         * @summary All Activities
         * @param {number} [start] The position to start the data from for pagination
         * @param {number} [count] The number of records to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityAllGet(start, count, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ActivityApiAxiosParamCreator)(configuration).apiActivityAllGet(start, count, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the activity log for a node
         * @summary Activity Log
         * @param {string} nodeId Node Id of the requested activity log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityNodeIdLogGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ActivityApiAxiosParamCreator)(configuration).apiActivityNodeIdLogGet(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the list of recent changes to the vault *since the last time they logged in*.  These activities will include :-                1. Changes to documents (added, deleted etc)  2. Shares (new, altered or deleted)  3. Any notification that the user would have gotten.
         * @summary Recent Changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityRecentGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ActivityApiAxiosParamCreator)(configuration).apiActivityRecentGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.ActivityApiFp = ActivityApiFp;
/**
 * ActivityApi - factory interface
 * @export
 */
const ActivityApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get the list of all activities for the current user. This function is paginated using the start and count parameters.
         * @summary All Activities
         * @param {number} [start] The position to start the data from for pagination
         * @param {number} [count] The number of records to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityAllGet(start, count, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ActivityApiFp)(configuration).apiActivityAllGet(start, count, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the activity log for a node
         * @summary Activity Log
         * @param {string} nodeId Node Id of the requested activity log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityNodeIdLogGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ActivityApiFp)(configuration).apiActivityNodeIdLogGet(nodeId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the list of recent changes to the vault *since the last time they logged in*.  These activities will include :-                1. Changes to documents (added, deleted etc)  2. Shares (new, altered or deleted)  3. Any notification that the user would have gotten.
         * @summary Recent Changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivityRecentGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ActivityApiFp)(configuration).apiActivityRecentGet(options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.ActivityApiFactory = ActivityApiFactory;
/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
class ActivityApi extends base_1.BaseAPI {
    /**
     * Get the list of all activities for the current user. This function is paginated using the start and count parameters.
     * @summary All Activities
     * @param {number} [start] The position to start the data from for pagination
     * @param {number} [count] The number of records to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    apiActivityAllGet(start, count, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ActivityApiFp)(this.configuration).apiActivityAllGet(start, count, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the activity log for a node
     * @summary Activity Log
     * @param {string} nodeId Node Id of the requested activity log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    apiActivityNodeIdLogGet(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ActivityApiFp)(this.configuration).apiActivityNodeIdLogGet(nodeId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the list of recent changes to the vault *since the last time they logged in*.  These activities will include :-                1. Changes to documents (added, deleted etc)  2. Shares (new, altered or deleted)  3. Any notification that the user would have gotten.
     * @summary Recent Changes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    apiActivityRecentGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ActivityApiFp)(this.configuration).apiActivityRecentGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.ActivityApi = ActivityApi;
